<template>
	<footer class="footer">
		<div class="footer__logo">
			<img src="../../assets/images/logo_black.svg" alt="logo">
		</div>

		<div class="footer__content ">
			<div class="footer__belt" :style="[this.$store.state.isEn ? {'justify-content': 'flex-start', gap: '3rem 8rem'} : '']">
				<div
					v-for="(item, i) in footer[0]"
					:key="i"
					class="footer__box"
				>
					<div class="footer__box-title">
						<h4 class="small-title" v-html="item.title"/>
					</div>

					<div class="footer__box-content">
						<p v-if="item.position" class="footer__position"> {{ item.position }}</p>
						<p v-if="item.text" class="footer__fullname"> {{ item.text }} </p>
						<p v-if="item.email" class="footer__email"> {{ item.email }} </p>
						<p v-if="item.phone" class="footer__number"> {{ item.phone }} </p>

						<p v-if="item.address"> {{ item.address }} </p>
						<p v-if="item.post"> {{ item.post }} </p>
						<p v-if="item.nip"> {{ item.nip }} </p>
						<p v-if="item.regon"> {{ item.regon }} </p>
					</div>
				</div>
			</div>

			<div class="footer__belt footer__links">
				<h4 class="small-title">{{ footer[1].title }}</h4>

				<div class="footer__links-content">
					<div class="footer__links-col">
						<div
							v-for="(item, i) in footer[1].list[0]"
							:key="i"
							class="footer__box footer__links-col-box"
						>
							<Button
								buttonStyle="normal"
								:buttonLink="item.link"
								:buttonText="item.title"
							/>
						</div>
					</div>

					<div class="footer__links-col">
						<div
							v-for="(item, i) in footer[1].list[1]"
							:key="i"
							class="footer__box footer__links-col-box"
						>
							<Button
								buttonStyle="normal"
								:buttonLink="item.link"
								:buttonText="item.title"
							/>
						</div>
					</div>

					<div class="footer__links-col">
						<div
							v-for="(item, i) in footer[1].list[2]"
							:key="i"
							class="footer__box footer__links-col-box"
						>
							<Button
								buttonStyle="normal"
								:buttonLink="item.link"
								:buttonText="item.title"
							/>
						</div>
					</div>
				</div>
			</div>

			<div class="footer__belt footer__industries">
				<h4 class="small-title">{{ footer[2].title }}</h4>

				<div class="footer__industries-content">
					<div
						v-for="(item, i) in footer[2].list"
						:key="i"
						class="footer__box footer__industries-box"
					>
						<p> {{ item.name1 }} </p>
						<p> {{ item.name2 }} </p>
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
import {footerData} from '@/data/footer'
import {footerEN} from '@/data/en/footer'

export default {
	name: "footer index",
	computed: {
		footer () {
			return this.$store.state.isEn ? this.footerEN : this.footerData
		}
	},
	data() {
		return {
			footerData,
			footerEN
		}
	}
}
</script>

<style scoped lang="sass">
.small-title
	font-size: 12px
	font-weight: $weight--semi-bold
	color: $color--dark
	line-height: 13px
	text-transform: uppercase

.footer
	position: relative
	width: 100vw
	background: $color--gray-4
	padding-top: 67px
	padding-bottom: 135px

	@include view('md')
		padding: 60px 100px

	@include view('sm')
		padding: 60px 33px

	&::after
		position: absolute
		content: ''
		bottom: 0
		left: 0
		width: 100%
		height: 22px
		background: $color--dark

	&__logo
		width: 1250px
		margin: 0 auto

		@include view('sm')
		@include view('md')
			width: 100%

	&__content
		padding-top: 65px
		padding-right: 89px
		width: 1020px
		margin: 0 auto
		display: flex
		flex-direction: column

		@include view('md')
			width: 100%
			padding: 60px 20px

		@include view('sm')
			padding: 60px 0 0 0
			width: 100%

	&__position
		display: inline-block
		font-size: 15px
		font-weight: $weight--semi-bold
		margin-bottom: 6px

	&__belt
		position: relative
		display: flex
		justify-content: space-between

		@include view('sm')
			width: 100%
			gap: 2rem 0
			flex-direction: column

		&:nth-child(1)
			&::after
				position: absolute
				content: ''
				bottom: -28px
				left: 0
				width: 100%
				height: 1px
				background: $color--gray-primary

		&:nth-child(2)
			margin-top: 61px

	&__links
		display: flex
		flex-direction: column
		width: 747px
		margin-bottom: 65px

		@include view('sm')
		@include view('md')
			width: 100%

		&-content
			padding-top: 18px
			width: 100%
			display: flex
			flex-direction: row
			justify-content: space-between

			@include view('sm')
				padding: 0
				gap: 2rem 0
				flex-direction: column

		&-col
			display: flex
			flex-direction: column
			justify-content: space-between

			&-box
				margin: 7.5px 0

				@include view('sm')
					margin: 0

	&__industries
		display: flex
		flex-direction: column
		width: 962px

		@include view('sm')
		@include view('md')
			width: 100%

		&-content
			padding-top: 30px
			width: 100%
			display: flex
			flex-direction: row
			justify-content: space-between

			@include view('md')
				justify-content: space-between
				flex-wrap: wrap

			@include view('sm')
				flex-wrap: wrap

		&-box
			@include view('sm')
				width: 50%

			p
				font-size: 12px
				font-weight: $weight--light
				color: $color--dark
				line-height: 22px

	&__box

		&-title
			margin-bottom: 25px
			height: 26px

			@include view('sm')
				margin-bottom: 10px
				height: unset

		&-content
			p
				font-size: 12px
				font-weight: $weight--light
				line-height: 22px
				color: $color--dark
</style>
