const productFaq = [
	{
		title: 'Czym jest produkt cyfrowy?',
		text: 'Produktem cyfrowym w rozumieniu projektowo-biznesowym jest m.in. każda strona internetowa (niezależnie od wielkości), aplikacja mobilna, system czy platforma. Do mniejszych produktów cyfrowych, których zadaniem jest wspieranie powyższych, należą narzędzia digital marketingu, takie jak na przykład animacja.'
	},
	{
		title: 'Skąd pewność, że nowe rozwiązania się sprawdzą i będą dopasowane do naszej firmy?',
		textArray: [
			{
				text: 'Nasze rozwiązania budujemy w oparciu o realne dane i potrzeby, dlatego tak ważne są badania i analityka, które pozwalają zweryfikować zasadność rozwiązań przed ich wdrożeniem. Posiadając wiedzę na temat tego, kim jest Wasz użytkownik, jakie są jego motywacje i w jaki sposób podejmuje wybór, jesteśmy w stanie ułatwić mu podjęcie decyzji poprzez dostarczenie odpowiedniego rozwiązania.\n'
			},
			{
				text: 'Im lepsze doświadczenie użytkownik ma z Waszym produktem, tym większa szansa nie tylko na konwersję, ale również powrót do produktu w przyszłości, a być może nawet zarekomendowanie go znajomym. Pozytywne doświadczenia działają nie tylko tu i teraz, ale również procentują na przyszłość.'
			}
		]
	},
	{
		title: 'Czy nowy albo udoskonalony produkt cyfrowy zwiększy nasze zyski?',
		textArray: [
			{
				text: 'W Hylite skupiamy się na dostarczaniu rozwiązań, które z założenia mają służyć zwiększeniu konwersji, a tym samym zwiększyć przychód.'
			},
			{
				text: 'Zysków na pewno nie przyniesie projekt oparty jedynie o wyobrażenia, bez podparcia się realnymi danymi. Jeśli pojawiają się wątpliwości, czy dany projekt może okazać się opłacalny, warto rozważyć walidację pomysłu. Istnieje szereg metodyk i badań, które pozwalają na weryfikację, czy dana idea ma sens i czy warto ją wdrażać. Czasami lepiej jest poświęcić mniej czasu i pieniędzy np. na badanie fokusowe z użytkownikami bądź przeprowadzenie Design Sprintu, niż rozpoczynać pełny projekt bez sprawdzenia, czy Wasi odbiorcy są w ogóle zainteresowani Waszym nowym pomysłem.'
			}
		],
	},
	{
		title: 'Ile kosztuje i jak długo trwa stworzenie produktu cyfrowego?',
		textArray: [
			{
				text: 'Proces tworzenia produktu cyfrowego znacząco różni się od tego, jak podchodziliśmy do projektowania takich rzeczy jeszcze kilkanaście lat temu. Wraz z globalną cyfryzacją i pojawiającymi się narzędziami analitycznymi monitorującymi ruch użytkowników, przestaliśmy tworzyć strony, aplikacje, systemy, które po prostu są. Zamiast nich zaczęliśmy tworzyć realne narzędzia sprzedażowe i marketingowe. Przestaliśmy podchodzić do tematu tworzenia produktów cyfrowych jak do czegoś, co ma tylko wyglądać, a zaczęliśmy przykładać wagę do tego, żeby również działało. Weszliśmy w erę świadomego projektowania.'
			},
			{
				text: 'Świadome projektowanie wiąże się w pierwszej kolejności z koniecznością uzyskania wszelkich niezbędnych informacji i badań, które są kluczowym elementem procesu projektowego. To faza, która w całym projekcie zajmuje najwięcej czasu i która jest pod tym kątem najbardziej trudna do przewidzenia. To moment, w którym odkrywamy potrzeby zarówno Wasze, jak i Waszych odbiorców, projektując strategie finalnych rozwiązań.'
			},
			{
				text: 'W przypadku niewielkich, konkretnych produktów, a także firm, które dysponują już wynikami badań i audytami, proces jest krótszy i trwa przeciętnie kilka tygodni. W przypadku dużych projektów, których decydenci nie dysponują żadnymi danymi, a tym samym projekty te wymagają licznych badań, warsztatów i testowania, proces będzie dłuższy i może wynieść nawet kilkanaście miesięcy.'
			},
			{
				text: 'Oznacza to, że bez badań i rozplanowanej strategii nie jesteśmy w stanie precyzyjnie określić, jak długo będziemy pracować nad stworzeniem produktu cyfrowego dla Waszej firmy, ponieważ nikt z nas nie zna pełnego zakresu prac, a co za tym idzie: trudno też ocenić jego finalną cenę.'
			},
			{
				text: 'W tym celu sugerujemy w pierwszej kolejności konsultacje, które pozwolą nam wstępnie ocenić zakres prac.'
			},
			{
				text: 'Dla Waszego komfortu oferujemy szereg rozwiązań, takich jak szacunki czasowe podzielone według etapów oraz ich oddzielne wycenianie. Każdy z etapów kończony jest również raportem i dalszymi rekomendacjami, tak abyście nie tylko samodzielnie wiedzieli, na jakim etapie się znajdujemy, ale również w razie potrzeby byli w stanie przedstawić to wyższym szczeblom. W przypadku projektów z konkretnymi deadline\'ami sugerujemy rozpoczęcie od realizacji produktu MVP.'
			}
		]
	},
	{
		title: 'Co to znaczy, że produkt cyfrowy nigdy nie jest skończony? Czy to oznacza, że czeka nas nieskończona ilość wydatków na coś, co nigdy nie będzie zrealizowane?',
		textArray: [
			{
				text: 'Przez nieskończoność produktu cyfrowego rozumiemy to, że obecne produkty cyfrowe, takie jak np. strona internetowa, landing page czy aplikacja, nieustannie podlegają naturalnym zmianom rynkowym. Rynek cyfrowy jest znacznie bardziej dynamiczny niż tradycyjny, często również przez konieczność rywalizacji nie tylko na rynku lokalnym, ale także krajowym, a w wielu przypadkach nawet globalnym. Oznacza to, że nawet jeżeli dzisiaj Wasz produkt cyfrowy przynosi duże zyski, za trzy lata, o ile nie będzie kontrolowany i ulepszany, może zacząć przynosić straty. Aby uniknąć takiej sytuacji, sugerujemy - w zależności od potrzeb firmy - na bieżąco bądź sporadyczne, np. raz na kwartał, dokonywać ewaluacji na podstawie aktualnych danych analitycznych podpiętych do produktu. Pozwala to trzymać rękę na pulsie, a tym samym na bieżąco optymalizować produkt, tak aby wciąż spełniał najwyższe standardy.'
			},
			{
				text: 'Należy zaznaczyć, że ewaluacja i analityka wdrożonego produktu są rekomendowane, lecz nie obowiązkowe: tym samym, jeżeli nie zależy Wam na dalszym rozwijaniu produktu i dostosowywaniu go do potrzeb konsumentów, możemy zakończyć projekt na tym, co zostało już wykonane.'
			}
		]
	},
	{
		title: 'Nie przeprowadzaliśmy do tej pory profesjonalnych badań, ale znamy swoich użytkowników: czy w takim razie potrzebujemy badań?',
		textArray: [
			{
				text: 'Tak długo, jak nie dysponujemy badaniami ilościowymi i jakościowymi, tak naprawdę wcale nie znamy swoich użytkowników.\n'
			},
			{
				text: 'Z natury jesteśmy obarczeni błędami poznawczymi, które wpędzają nas w myślenie, że nasi użytkownicy kochają i rozumieją nasz produkt w taki sam sposób, jak my i wszystko co nam mówią to czysta prawda. Bez badań nie jesteśmy w stanie ocenić motywacji zakupowych, łącznie z tą, dlaczego Wasz klient wybrał akurat Waszą firmę i produkt, podczas kiedy na rynku jest wiele innych o podobnym profilu. Spotkaliśmy się z wieloma opiniami firm o tym, że klienci wybrali ich produkt dlatego, że jest najlepszy na rynku, ale na prośbę o rozwinięcie powodów, dla których miałby to być faktycznie najlepszy produkt, nie byli w stanie ich podać bądź podawali te same powody, jakie wymieniała ich konkurencja w odniesieniu do siebie.\n'
			},
			{
				text: 'Błędem jest zakładanie z góry, że klient wybiera Wasz produkt przez te punkty przewagi, które najintensywniej reklamujecie i które osobiście uważacie za decydujące.'
			},
			{
				text: 'Brak przeprowadzonych badań uniemożliwia również odpowiedź na uniwersalne pytanie, co klienci chcieliby, aby zostało poprawione w Waszym produkcie. Wychodzenie z założenia, że produkt jest idealny i nie wymaga poprawy, jest niestety myśleniem życzeniowym, nie znajdującym odzwierciedlenia w przeprowadzanych badaniach, a wysnuwanie wniosków opartych na wyobrażeniach i błędach poznawczych skutkuje niedopasowanymi rozwiązaniami.'
			}
		]
	},
	{
		title: 'Mamy deadline za dwa miesiące: czy mamy szansę zdążyć? Dlaczego, od czego to zależy?',
		textArray: [
			{
				text: 'To, czy mamy szansę, zależy od tego, jak duży jest projekt, jakie ma oferować funkcjonalności, jaki jest cel biznesowy, a także jakie informacje (łącznie z badaniami) zostały do tej pory zebrane.'
			},
			{
				text: 'W przypadku projektów z konkretnym nieprzekraczalnym terminem realizacji rekomendujemy w pierwszej kolejności konsultacje, które pozwolą nam zdefiniować istotę projektu. Być może dobrą opcją przy konkretnym deadlinie będzie stworzenie w pierwszej kolejności podstawowej działającej wersji produktu (MVP) pozbawionej wad, będącej rozbudowywaną o dodatkowe funkcjonalności w dalszej perspektywie, niż ryzykowanie opublikowaniem rozbudowanego produktu zawierającego masę błędów wpływających na złe doświadczenia użytkownika, takich jak na przykład źle rozplanowane w pośpiechu ścieżki użytkownika.'
			}
		]
	},
	{
		title: 'Czym jest MVP?',
		textArray: [
			{
				text: 'MVP (Minimum Viable Product) to produkt okrojony ze wszelkich dodatkowych funkcjonalności, które nie są niezbędne do jego prawidłowego funkcjonowania, gotowy do bycia rozbudowywanym w przyszłości.'
			},
			{
				text: 'Przykładowo: w przypadku strony internetowej dla przewoźnika autobusowego produktem MVP będzie strona, która udostępnia rozkład jazdy i możliwość zakupu biletów online. Chociaż nie jest to niezbędne dla jej funkcjonowania, to w przyszłości może ona zostać poszerzona o takie funkcjonalności jak możliwość założenia konta pasażera czy funkcji oceniania jakości przejazdów.'
			}
		]
	},
	{
		title: 'Czy audyty i badania zarezerwowane są wyłącznie dla największych firm i dużych, skomplikowanych projektów?',
		text: 'Nie - badania są dla wszystkich. Co więcej, w przypadku mniejszych firm badania mają często większe znaczenie niż w przypadku tych większych, co związane jest z kompensacją ryzyka niepowodzenia. Mała firma decydując się na badania może w łatwy sposób uchronić się przed niechcianymi kosztami, duże firmy zazwyczaj mają wkalkulowane ryzyko nietrafionej inwestycji.'
	},
	{
		title: 'Nie mamy doświadczenia w realizacji produktów cyfrowych: czy odnajdziemy się w tym procesie?',
		textArray: [
			{
				text: 'Tak - podchodzimy do procesu realizacji w taki sposób, abyście byli jego integralną częścią. Doradzimy i wyjaśnimy wszystko, co jest konieczne do realizacji, bez zostawiania Was samych z koniecznością realizacji procesu, z którym nie macie do czynienia na co dzień. Wiemy, jakie kroki podjąć po kolei i jak zagwarantować Wam bezpieczeństwo realizacji, tak aby zapewnić efektywną realizację i optymalizację kosztów.'
			},
			{
				text: 'Wielu z naszych klientów potrzebuje również wyjaśnienia procesów w celu dalszego przedstawienia ich przełożonym: w tym celu tworzymy raporty, które umożliwiają weryfikację tego, na jakim etapie projektu jesteśmy.'
			}
		]
	},
	{
		title: 'Chcemy mieć produkt podobny do tego, jaki ma konkurencja - czy realizujecie takie projekty?',
		textArray: [
			{
				text: 'Tak, ale nie rekomendujemy takiego podejścia.'
			},
			{
				text: 'Dwie podobne firmy o pokrewnej lub takiej samej działalności nigdy nie będą realizowały dokładnie tak samo swoich zadań. Istnieje duże niebezpieczeństwo, że rozwiązania zaadaptowane z innej firmy nie będą się sprawdzały w Waszej. Tworząc produkt cyfrowy podobny do produktu cyfrowego konkurencji pozbawiacie się swojej naturalnej przewagi, czyli realizowania usług według Waszych własnych standardów. Stwarza to tym samym szeroko zakrojone ryzyko stworzenia produktu, który pozostanie wizualną wydmuszką nie spełniającą założeń biznesowych ani nie odpowiadającą na potrzeby Wasze, ani Waszych klientów.'
			},
			{
				text: 'Nie oznacza to jednak, że nie bierzemy Waszych potrzeb, obaw i życzeń pod uwagę. Podczas realizacji produktów przeprowadzamy warsztaty, które pozwalają nam poznać, co jest dla Was ważne i z jakich powodów.'
			}
		]
	},
	{
		title: 'Obawiamy się ukrytych kosztów, które mogą pojawić się w trakcie realizacji.',
		textArray: [
			{
				text: 'Podczas realizacji procesu projektowego ustalamy, jakie elementy muszą zostać zrealizowane, aby wykonać projekt, a każdy z etapów wyceniany jest oddzielnie.'
			},
			{
				text: 'Chociaż w naszym procesie nie ma ryzyka ukrytych kosztów, należy mieć na uwadze to, że tworzenie produktu cyfrowego jest procesem odkrywania zarówno potrzeb użytkownika, jak i ukrytych problemów i barier. Oznacza to, że nie da się przewidzieć na początku projektu, czy zaistnieje konieczność wykonania dodatkowych badań bądź dodania nowych funkcjonalności, o których nie było wcześniej mowy, a tym samym potrzeba poszerzenia zakresu projektu. Może się okazać, że jakiś z obszarów powinien być uwypuklony bardziej, a tym samym powinien zostać przebadany bardziej dogłębnie. Dotyczy to głównie sytuacji, gdy firma nie dysponuje profesjonalnymi badaniami, na których można by się oprzeć, albo jej struktury są nie do końca uporządkowane.'
			},
			{
				text: 'Wiąże się to z rekomendacją dodatkowych badań albo rozwiązań, które choć nie są konieczne, mogą znacząco wpłynąć na jakość produktu albo oznaczać dodatkowe funkcjonalności. O każdej takiej sytuacji informujemy, a finalna decyzja, czy mamy podjąć dodatkowe działania, należy do Was.'
			},
			{
				text: 'W większości przypadków dążymy jednak do uzyskania niezbędnego MVP (podstawowej wersji projektu bez dodatkowych funkcjonalności), a wszelkie dodatkowe funkcjonalności sugerujemy jako opcjonalne dodatkowe rozwiązania w przyszłości.'
			}
		]
	},
	{
		title: 'Skąd pewność, że forma badań, jaką dobierzecie, będzie dobra?',
		textArray: [
			{
				text: 'Każda metoda prowadzi do konkretnych danych, które są niezbędne, aby uzyskać dane rozwiązanie. Metody należy potraktować w kategorii narzędzi, które mają pewną funkcję: mając do dyspozycji szeroki wachlarz takich narzędzi i znając ich przeznaczenie, można łatwo dopasować je do powierzonego zadania.'
			},
			{
				text: 'W naszych badaniach poza metodami tradycyjnymi stosujemy również metody autorskie, które opracowaliśmy na przestrzeni wielu lat pracy. Pozwala nam to na głębszą eksplorację niektórych zagadnień, a tym samym na dostarczenie jeszcze lepszych rozwiązań niż w przypadku stosowania wyłącznie tradycyjnych metod.'
			}
		]
	},
	{
		title: 'Czy podczas realizacji będę zmuszony do ciągłego uczestnictwa w warsztatach i regularnej aktywności?',
		text: 'Nie. Są jednak pewne obszary, gdzie bez Waszego udziału nie damy rady dostarczyć odpowiedniego rozwiązania. Warsztaty mają za zadanie pomóc w procesie tworzenia rozwiązań, a nie być przykrą koniecznością: to właśnie dzięki nim można odkryć nowe rozwiązania problemów, na które bezskutecznie szukało się wcześniej odpowiedzi. Doświadczenie osoby prowadzącej warsztaty pozwala również na usystematyzowanie obecnej wiedzy i poukładanie jej w logiczną całość.'
	},
	{
		title: 'Czy rozwiązania na pewno będą optymalne czy może okażą się jednak kosztowne we wdrożeniu?',
		textArray: [
			{
				text: 'W miarę możliwości optymalizujemy finansowo każdy projekt, który realizujemy, bo zależy nam na usatysfakcjonowanych klientach, którzy w przyszłości do nas powrócą.'
			},
			{
				text: 'Należy mieć jednak na uwadze, że zaburzanie rekomendowanego procesu w ramach pozornego cięcia kosztów albo świadome pomijanie istotnych informacji podczas kick-offu bądź warsztatów celem uzyskania niskiej wyceny projektu powoduje powstawanie błędnych, pozornie tylko optymalnych rozwiązań. Oznacza to również chaos w samej strukturze projektu, ponieważ każdy kolejny krok wynika z poprzedniego, a jeśli któregoś z nich zabraknie albo dostarczy nieprawidłowych wniosków, nie otrzymamy informacji, która będzie niezbędna do właściwego zbudowania rozwiązania.\n'
			},
			{
				text: 'Przywrócenie projektu na właściwe tory może okazać się trudnym wyzwaniem, pochłaniającym niepotrzebnie czas i zasoby.'
			},
			{
				text: 'Z tego powodu w przypadku posiadania z góry określonego budżetu rekomendujemy porozmawianie o tym na etapie kick-offu: da nam to możliwość rozmowy o potencjalnych możliwościach i obawach, przy bardzo roboczo zdefiniowanym MVP.'
			}
		]
	},
]

const animationFaq = [
	{
		title: 'Ile potrzeba czasu na stworzenie animacji biznesowej?',
		text: 'Przeciętny proces realizacji animacji reklamowej trwa 8-14 tygodni. W praktyce zależy to od czynników takich jak długość animacji i poziom jej skomplikowania, ale także łańcuch decyzyjny po stronie klienta. Realizację animacji można przyspieszyć poprzez użycie pewnych uproszczeń, warto się natomiast zastanowić, czy takie działanie nie spowoduje, że animacja stanie się pozbawiona cech, które pozwolą jej na poprawne pełnienie swojej funkcji sprzedażowej.'
	},
	{
		title: 'Jeśli chcemy animację, w jakim czasie powinniśmy się z Wami skontaktować, aby udało się to zrobić?',
		text: 'Przeciętna realizacja trwa 8-14 tygodni, natomiast jest to zależne od długości animacji czy poziomu skomplikowania projektu. Im wcześniej nastąpi kontakt się z nami, tym lepiej będziemy mogli się odnieść do terminów.'
	},
	{
		title: 'Co musimy dostarczyć, aby taka animacja powstała?',
		textArray: [
			{
				text: 'W pierwszej kolejności przed dostarczeniem materiałów do rozpoczęcia realizacji chcielibyśmy wysłuchać, jaki macie problem i czego to Wy potrzebujecie. Da nam to także możliwość zadania Wam konkretnych pytań.'
			},
			{
				text: 'Jeśli chodzi natomiast o informacje i materiały, jakich będziemy potrzebować do rozpoczęcia prac:'
			},
			{
				text: `
					● brief zawierający informacje na temat tego, czego potrzebujecie;<br>
					● tekst lektorski (o ile został stworzony po Waszej stronie);<br>
					● informację o tym, jak długa ma być animacja i czy ograniczenie
					czasowe jest warunkiem koniecznym (np. w przypadku wykupienia
					reklamy na Youtube);<br>
					● wszelkie niezbędne informacje techniczne (standardowo dostarczamy 
					animacje w *.avi, full HD 1920x1080, chyba że zostaje to określone
					inaczej);<br>
					● informację o tym, gdzie i do czego będzie używana animacja.
				`
			},
			{
				text: 'Poza tym bardzo nam pomogą, ale nie są obligatoryjne:'
			},
			{
				text: `
					● wszelkie materiały reklamowe firmy, które będą dla nas wskazówką
					odnośnie brandingu (np. dominujących kolorów)<br>
					● linki do animacji, które Wam się podobają (o ile takimi dysponujecie);<br>
					● wcześniej wykonane dla Was animacje z informacją, czy nowa
					animacja będzie miała do nich nawiązywać czy nie.
				`
			}
		]
	},
	{
		title: 'Czy możemy pominąć etap storyboardu? Wszystkie sceny są jasno i dokładnie rozpisane w dostarczonym przez nas briefie.',
		text: 'Niestety nie - sytuacje, kiedy rezygnujemy ze stworzenia storyboardów, są bardzo sporadyczne i dotyczą wyłącznie krótkich form animacyjnych do 15 sekund. Wynika to z tego, że musimy sprawdzić, na ile Wasze pomysły są możliwe do realizacji i jakich narzędzi będziemy potrzebować. Jest to również koniecznie dla sprawdzenia timingu i wprowadzenia ewentualnych zmian i korekt. Rozrysowanie storyboardu jest znacznie prostsze i tańsze niż późniejsze zmiany na etapie ilustracji bądź animacji.'
	},
	{
		title: 'Dlaczego jakość animacji ma znaczenie, skoro kluczowe jest przede wszystkim to, aby zawierała istotne informacje?',
		text: 'Jakość animacji ma o tyle duże znaczenie, że animacja reklamowa stała się powszechna wśród odbiorców. Oznacza to, że nie wystarczy jej już po prostu tylko mieć, żeby przyciągała uwagę jako ciekawostka - musi być na tyle atrakcyjna, aby w pierwszej kolejności skłoniła odbiorcę do zainteresowania się nią, a w drugiej utrzymała jego uwagę. Jest to o tyle trudne, że w świecie cyfrowym jesteśmy bombardowani treściami reklamowymi z różnych stron, więc jeśli zależy nam na efekcie, koniecznością jest tworzenie treści o wysokiej jakości.'
	},
	{
		title: 'Czy możemy wykonać scenariusz samodzielnie?',
		textArray: [
			{
				text: 'Tak, natomiast rekomendujemy to podejście wyłącznie w przypadku animacji prezentacyjnych, do których wręcz potrzebujemy konkretnych informacji o tym, jakie dokładnie dane i statystyki chcesz przedstawić, w jakiej kolejności i w jaki sposób. W innych przypadkach samodzielne pisanie scenariusza może być ryzykowne, zwłaszcza jeśli nie macie na co dzień doświadczenia z animacją. Chociaż perspektywa zobaczenia jak wymyślone idee ożywają jest kusząca, to jest również bardzo ryzykowna pod kątem jakościowym i finansowym. Brak umiejętności optymalizacji technologicznej animacji na etapie tworzenia koncepcji może w ekstremalnych przypadkach zwiększyć koszt realizacji nawet trzykrotnie.'
			},
			{
				text: 'Jeżeli mimo wszystko zależy Wam na samodzielnym stworzeniu scenariusza do innego rodzaju animacji, prosimy o dostarczenie go w formie storyboardu (z rozrysowanymi w uproszczony sposób scenami odpowiadającymi konkretnym fragmentom wypowiedzi lektora). Pozwoli to Wam na wstępne rozpoznanie, czy Wasza wizja jest zrozumiała, a nam na odniesienie się do zagadnień technicznych.'
			}
		]
	},
	{
		title: 'Dlaczego Wasz proces i etap "Scenariusz" nie uwzględnia pisania jako takiego scenariusza?',
		textArray: [
			{
				text: 'Uważamy, że wbrew powszechnej opinii nie jest to optymalny krok w produkcji animacji reklamowych: bardziej utrudnia i opóźnia proces niż w czymkolwiek pomaga. Zamiast klasycznego scenariusza używamy storyboardu, który ułatwia przekazywanie wizji i jest bardziej komfortowy zarówno dla nas, jak i dla Was.'
			}
		]
	},
	{
		title: 'Czy możemy przygotować treść lektorską samodzielnie?',
		text: 'Tak. To bardzo dobry pomysł w sytuacji, kiedy zajmujecie się marketingiem i wiecie dokładnie, do czego potrzebujecie animacji i jaką dokładnie treść chcecie przekazać swoim odbiorcom. Naszą rolą w tym procesie będzie dobudowanie do Waszej treści wizualnego storytellingu i zaprezentowanie tego, o czym mówicie, w maksymalnie atrakcyjny sposób.'
	},
	{
		title: 'Czy możemy dostarczyć gotowe ilustracje, tak abyście je tylko zanimowali?',
		text: 'Tak - natomiast zanim je zanimujemy, będziemy musieli je w pierwszej kolejności zweryfikować pod kątem technicznym i być może odpowiednio przygotować'
	},
	{
		title: 'Jak długa powinna być animacja?',
		text: 'W zależności od tego, gdzie animacja będzie używana, około 15-30 sekund (social media) do 1,5-2 minut (strona internetowa). To optymalny czas, który pozwala utrzymać uwagę odbiorcy, jeżeli jest zainteresowany Waszym produktem. W przypadku dłuższych materiałów znacząco zwiększa się ryzyko, że odbiorca wyłączy Waszą animację przed końcem.'
	},
	{
		title: 'Czy zamiast robić kilka krótkich animacji możemy zrobić jedną długą, która będzie wypełniona wszystkimi niezbędnymi informacjami, a przez to będzie uniwersalna?',
		textArray: [
			{
				text: 'Możemy, ale jest bardzo duże ryzyko, że taka animacja będzie źle konwertować. Postawmy się na miejscu naszych klientów: rzadko jakakolwiek reklama przyciąga nas na tyle mocno, abyśmy poświęcili na nią więcej niż dwie minuty. Ile informacji z takiej produkcji przyswajamy bez "wyłączenia się", a ile zapamiętujemy? Błędem jest także zakładanie, że nasz odbiorca jest tak bardzo zainteresowany naszą usługą, że będzie na tyle zdeterminowany, aby przebrnąć przez kilkuminutowy materiał w poszukiwaniu konkretnej informacji.'
			},
			{
				text: 'Znacznie lepszą opcją może być podzielenie jednej długiej animacji na kilka, które nie tylko szerzej rozwiną poszczególne tematy bez atakowania odbiorcy natłokiem informacji, ale będą jednocześnie pełnić funkcję promocyjną. Takie animacje będziecie mogli umieścić np. na poszczególnych podstronach swojej strony internetowej, gdzie rozbudują treść, ale również w formie ciekawostek w social media.'
			}
		]
	},
	{
		title: 'Właśnie wpadliśmy na pomysł stworzenia krótkiej animacji, czy damy radę w dwa tygodnie?',
		text: 'Jeżeli animacja trwa 10-15 sekund i ma bardzo jasny i konkretny scenariusz, jest na to szansa.'
	},
	{
		title: 'Widzieliśmy taką animację u konkurencji, czy możemy stworzyć coś podobnego?',
		text: 'Możemy - ale być może lepszą opcją byłoby odkrycie własnego języka komunikacji wizualnej i stworzenie animacji, która będzie odpowiadać na Wasze własne potrzeby? Nie ma dwóch takich samych firm, nawet jeżeli działają w tym samym obszarze i realizują podobne zagadnienia: stworzenie animacji to niepowtarzalna możliwość na wyróżnienie firmy. Z chęcią Wam pokażemy, że niekoniecznie musicie wzorować się na konkurencji, aby otrzymać działające i skuteczne narzędzie video marketingu.'
	},
	{
		title: 'Czy wykonujecie animacje typu whiteboard?',
		text: 'Tak. Przed skontaktowaniem się z nami w tej sprawie zachęcamy jednak do rozważenia innych opcji. Chociaż whiteboard jest bardzo popularnym typem animacji, często nie jest to najlepszy pomysł. Whiteboard wyśmienicie sprawdza się w przypadku prezentacji wewnętrznych, wyjaśniania oferty kontrahentom i prezentacji wyników na spotkaniu z zarządem. Nie będzie to jednak dobre rozwiązanie, jeżeli zależy Wam na dotarciu do klientów, z racji braku walorów marketingowo-sprzedażowych, takich jak kolory, wyróżniająca się forma czy możliwość realizacji prawdziwego storytellingu.'
	},
	{
		title: 'Na czym polega storytelling i dlaczego jest tak ważny?',
		text: 'Storytelling polega na opowiedzeniu historii poprzez animację, tak aby była ona logicznym i ciekawym ciągiem scen wynikających z siebie wzajemnie, a nie pokazem slajdów, gdzie jeden nie wynika z drugiego. Opiera się na budowaniu relacji za pomocą emocji i inspiruje do działania. Umiejętnie poprowadzony storytelling zaciekawia i przyciąga uwagę odbiorców, a także pozwala im na dłużej zapamiętać Wasz produkt i to, co o nim mówiliście.'
	},
	{
		title: 'Zrobiliśmy już kiedyś animację, ale chcielibyśmy ją odświeżyć. Czy jest taka możliwość?',
		text: 'Animacja jest usługą wytwarzaną na zamówienie w sposób kaskadowy, co oznacza mówiąc prostymi słowy, że nie jesteśmy w stanie jej dosłownie poprawić poprzez ulepszenie tego, co zostało już zanimowane. Możemy za to przygotować jej nową wersję, która lepiej odpowie na Twoje bieżące potrzeby.\n'
	}
]

export { productFaq, animationFaq }
