import {createStore} from "vuex";

export default createStore({
	state: {
		currentPath: '',
		headerContent: {},
		animationContent: {},
		designContent: {},
		isMobile: false,
		isTablet: false,
		isEn: false
	},
	mutations: {},
	actions: {},
	modules: {},
});
