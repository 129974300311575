import BarIcon from '@/components/BarIcon/index.vue'

import {productLinks, headLinks, animationLinks, animationENLinks, headENLinks} from "./data";

export default {
	name: "navbar index",
	props: {
		navStatus: Boolean
	},
	components: {
		BarIcon: BarIcon
	},
	data: () => ({
		productLinks,
		animationLinks,
		headLinks,
		headENLinks,
		animationENLinks,
		productVisible: false,
		animationVisible: false
	}),
	computed: {
		handleHeadLinks () {
			if (this.$store.state.isEn) {
				return this.headENLinks
			}

			return this.headLinks
		},
		handleAnimationLinks () {
			if (this.$store.state.isEn) {
				return this.animationENLinks
			}

			return this.animationLinks
		}
	},
	methods: {
		closeNavbar () {
			this.$emit('clicked')
		},
		handleSubNavVisibility (type) {
			switch(true) {
				case type === 'product':
					if (this.animationVisible) {
						this.animationVisible = false
					}
					this.productVisible = !this.productVisible
					break

				case type === 'animation':
					if (this.productVisible) {
						this.productVisible = false
					}
					this.animationVisible = !this.animationVisible
					break

				default:
					break
			}
		}
	}
}
