import routeConstants from "../router/routePath";

const headers = [
	{
		id: 'home',
		logoColor: 'white',
		title: 'Rozwiązania customer experience',
		subtitle: 'dla biznesu i marketingu',
		image: require('../assets/images/header/main_banner.webp'),
		imageMobile: require('../assets/images/header/main_banner.webp'),
		buttons: [
			{
				text: 'PRODUCT & SERVICE DESIGN',
				subtext: 'dowiedz się więcej',
				link: ''
			},
			{
				text: 'ANIMACJA & ILUSTRACJA',
				subtext: 'dowiedz się więcej',
				link: ''
			}
		]
	},
	{
		id: routeConstants.ABOUT.path,
		logoColor: 'white',
		title: 'Nasza misja',
		image: require('../assets/images/header/about_banner.webp')
	},
	{
		id: routeConstants.CONTACT.path,
		logoColor: 'white',
		title: 'KONTAKT',
		smallTitle: 'W czym możemy pomóc?',
		image: require('@/assets/images/header/contact_banner.webp')
	},
	{
		id: '/animacja',
		logoColor: 'white',
		title: 'Tworzymy światowej klasy animacje jako skuteczne narzędzie video marketingu',
		image: require('../assets/images/animation/Animacja_home/header.webp'),
		imageMobile: require('@/assets/images/animation/Animacja_home/header-mobile.webp'),
		list: [
			{
				name: 'ANIMACJE PRODUKTOWE'
			},
			{
				name: 'ANIMACJE REKLAMOWE'
			},
			{
				name: 'ANIMACJE PROMOCYJNE'
			},
			{
				name: 'MOTION DESIGN'
			},
			{
				name: 'WHITEBOARDY '
			},
			{
				name: 'CHARACTER ANIMATION '
			},
		]
	},
	{
		id: routeConstants.ANIMATION_PRODUCTION_PROCESS.path,
		logoColor: 'white',
		title: 'Proces produkcji animacji',
		smallTitle: 'Animacja',
		image: require('@/assets/images/animation/Animacja_proces/01_header.webp'),
		imageMobile: require('@/assets/images/animation/Animacja_proces/01_header-mobile.webp')
	},
	{
		id: routeConstants.PRODUCT_ANIMATION.path,
		logoColor: 'white',
		title: "Animacja produktowa ",
		image: require('@/assets/images/animation/Animacja_produktowa/01_header.webp'),
		imageMobile: require('@/assets/images/animation/Animacja_produktowa/01_header-mobile.png')
	},
	{
		id: routeConstants.ADVERTISEMENT_ANIMATION.path,
		logoColor: 'white',
		title: "Animacja reklamowa ",
		image: require('@/assets/images/animation/Animacja_reklamowa/01_header.webp'),
		imageMobile: require('@/assets/images/animation/Animacja_reklamowa/01_header-mobile.webp')
	},
	{
		id: routeConstants.PROMOTIONAL_ANIMATION.path,
		logoColor: 'black',
		title: "Animacja promocyjna ",
		image: require('@/assets/images/animation/Animacja_promocyjna/01_header.webp'),
		imageMobile: require('@/assets/images/animation/Animacja_promocyjna/01_header-mobile.webp')
	},
	{
		id: routeConstants.ONBOARDING_ANIMATION.path,
		logoColor: 'black',
		title: "Animacja onboardingowa ",
		image: require('@/assets/images/animation/Animacja_onboardingowa/01_header.webp'),
		imageMobile: require('@/assets/images/animation/Animacja_onboardingowa/01_header-mobile.webp')
	},
	{
		id: routeConstants.PRESENTATION_ANIMATION.path,
		logoColor: 'black',
		title: "Animacja prezentacyjna ",
		image: require('@/assets/images/animation/Animacja_prezentacyjna/01_header.webp'),
		imageMobile: require('@/assets/images/animation/Animacja_prezentacyjna/01_header-mobile.webp')
	},
	{
		id: routeConstants.DESIGN_PROCESS.path,
		logoColor: 'white',
		title: 'Etapy tworzenia produktu cyfrowego',
		smallTitle: 'PRODUCT & SERVICE DESIGN',
		image: ''
	},
	{
		id: routeConstants.DESIGN_AUDITS.path,
		logoColor: 'white',
		title: 'Audyty, badania, warsztaty i konsultacje',
		image: require('@/assets/images/design/P&SD_usluga1/01_header.webp'),
		text: 'Przedsięwzięcie biznesowe budowane na niejasnych przesłankach i wyobrażeniach, bez dyspozycji realnych danych, niesie za sobą ryzyko nietrafionej inwestycji. Aby zapobiec takiej sytuacji i upewnić się w tym, że zbudowane rozwiązanie nie tylko będzie spełniało wszystkie wymogi i założenia, ale będzie również zasadne, rekomendujemy rozpoczęcie procesu od audytu i badań. W badaniach, które przeprowadzamy, posługujemy się zarówno metodami ilościowymi, jak i jakościowymi. Wyniki pozwalają nam zobaczyć pełen obraz problemu, a czasami nawet odkryć, że w rzeczywistości kryje się on w innym miejscu niż zakładaliśmy. Otwiera to również drzwi na tworzenie produktów future-fit, które będzie można skalować w zależności od potrzeb biznesu, a także dostosowywać je do wyzwań, jakie mogą pojawić się w przyszłości.',
		subtext: '<strong>Chcąc być o krok przed konkurencją, ważne jest, aby dobrze zrozumieć powody, dla których <br class="d-sm-none">użytkownicy dokonują konkretnych wyborów i wyjść im naprzeciw.</strong>'
	},
	{
		id: routeConstants.DESIGN_RESEARCH.path,
		logoColor: 'white',
		title: 'Research, strategia <br>i innowacje',
		image: require('@/assets/images/design/P&SD_usluga2/01_header.webp'),
		text: 'Obecnie produkty cyfrowe odbiegają znacząco od tego, jak wyglądały jeszcze dziesięć lat temu. Nie traktujemy już stron internetowych jako prostych modnych wizytówek, które zachęcą użytkownika do osobistej wizyty w punkcie sprzedaży, a pod hasłem "aplikacja" rozumiemy coś zupełnie innego niż kalkulator. Niezależnie od tego, czy motywacją do rozpoczęcia prac nad produktem cyfrowym jest niewielka modyfikacja dotychczasowego produktu i odświeżenie jego dotychczasowej prezentacji czy może wprowadzenie czegoś zupełnie nowego na rynek: potrzebna będzie strategia.',
		subtext: '<strong>Dzięki strategii jesteśmy w stanie zaplanować krótko- i długoterminowe działania dotyczące realizacji produktu: to, jakie czynności będziemy podejmować, jakie obszary i w jakim zakresie będziemy eksplorować na poszczególnych etapach czy w jaki sposób będzie wyglądać podstawowa wersja produktu (MVP). Jest to mapa działań, którą będziemy się posiłkować podczas realizacji, aby mieć pewność skutecznej finalizacji projektu.</strong>'
	},
	{
		id: routeConstants.DESIGN_FIX.path,
		logoColor: 'white',
		title: 'Poprawa użyteczności <br>i udoskonalanie produktów ',
		image: require('@/assets/images/design/P&SD_usluga3/01_header.webp'),
		text: 'Cykl życia produktu cyfrowego to ciągle zmieniające się fazy, które sprawiają, że nie da się na niego patrzeć jak na element raz na zawsze skończony. Powodów jest bardzo dużo, zaczynając od sytuacji na rynkach światowych, sytuacji ekonomicznej w kraju, poprzez rozwiązania technologiczne, języki programowania, a ostatecznie kończąc na zmieniających się trendach i przyzwyczajeniach użytkowników.',
		subtext: '<strong>Prędzej czy później dochodzi do sytuacji, w której trzeba dokonać ewaluacji i podjąć kroki, <br class="desktop-only">które pozwolą usprawnić i utrzymać konkurencyjność produktu.</strong>'
	},
	{
		id: routeConstants.DESIGN_PRODUCT.path,
		logoColor: 'white',
		title: 'Product & Service <br>Design',
		image: require('@/assets/images/design/P&SD_usluga4/01_header.webp'),
	},
	{
		id: routeConstants.DESIGN_DEVELOPMENT.path,
		logoColor: 'white',
		title: 'Web & Mobile <br>Development',
		image: require('@/assets/images/design/P&SD_usluga5/01_header.webp'),
	},
	{
		id: routeConstants.DESIGN_TRANSFORM.path,
		logoColor: 'white',
		title: 'Transformacje cyfrowe',
		image: require('@/assets/images/design/P&SD_usluga6/01_header.webp'),
	},
	{
		id: routeConstants.DESIGN_IOT.path,
		logoColor: 'white',
		title: 'IoT & Head-Up Display',
		image: require('@/assets/images/design/P&SD_usluga7/01_header.webp'),
		text: 'Tworzymy rozwiązania w zakresie produktów IoT poprzez tworzenie ich interfejsów oraz współdziałających aplikacji.',
	},
	{
		id: routeConstants.DESIGN_SUPPORT.path,
		logoColor: 'white',
		title: 'Wsparcie i zarządzanie',
		image: require('@/assets/images/design/P&SD_usluga8/01_header.webp'),
		text: 'W zależności od potrzeb wspomagamy firmy na poszczególnych etapach realizacji poprzez układanie planów działania i zarządzanie zasobami. Jesteśmy agentami zmian, którzy pomogą Wam wdrożyć Wasz produkt nie tylko tak, aby był dostępny dla Waszych klientów, ale również pozostawał zrozumiały dla współpracowników i umożliwił wszystkim realne korzystanie z niego.',
	},
]

const mainOffers = [
	[
		{
			title: 'Audyty, badania, warsztaty <br> i konsultacje',
			link: routeConstants.DESIGN_AUDITS.path
		},
		{
			title: 'Research, strategia <br> i innowacje',
			link: routeConstants.DESIGN_RESEARCH.path
		},
		{
			title: 'Poprawa użyteczności\n' +
				'<br> i udoskonalanie produktów',
			link: routeConstants.DESIGN_FIX.path
		}
	],
	[
		{
			title: 'Product & Service\n' +
				'<br>  Design',
			link: routeConstants.DESIGN_PRODUCT.path
		},
		{
			title: 'Web & Mobile\n' +
				'<br>  Development',
			link: routeConstants.DESIGN_DEVELOPMENT.path
		},
		{
			title: 'Transformacje <br> cyfrowe',
			link: routeConstants.DESIGN_TRANSFORM.path
		},
	],
	[
		{
			title: 'IOT & Head-Up Display <br> Interfaces',
			link: routeConstants.DESIGN_IOT.path
		},
		{
			title: 'Wsparcie\n' +
				'<br> i zarządzanie',
			link: routeConstants.DESIGN_SUPPORT.path
		},
		{
			title: 'Animacja \n' +
				'<br> i ilustracja',
			link: routeConstants.ANIMATION.path
		},
	]
]

const why = [
	{
		title: 'Przejrzysty, bezstresowy <br> proces realizacji',
		text: 'Na podstawie wieloletniego doświadczenia opracowaliśmy proces bezstresowej realizacji, dzięki któremu tworzymy produkty w sposób profesjonalny i maksymalnie efektywny. Wiemy, o co powinniśmy Was zapytać, o czym i na jakim etapie powinniśmy porozmawiać i w jakich sytuacjach potrzebujemy Waszej decyzyjności.',
		button: 'Więcej o naszym procesie',
		link: routeConstants.DESIGN_PROCESS.path
	},
	{
		title: 'Kompleksowa <br class="d-sm-none"> realizacja',
		text: 'Realizujemy projekty kompleksowo, rozpoczynając od idei, kończąc na gotowym, zrealizowanym produkcie. Jesteśmy ekspertami w zakresie critical thinking, czyli globalnego spojrzenia na projekt i przewidywania możliwych punktów zapalnych, a tym samym zapobiegania im, zanim wystąpią. Decydując się na projekt z nami otrzymujecie gwarancję pełnej realizacji.',
		button: '',
	},
	{
		title: 'Złożone, skomplikowane <br> projekty i trudne branże',
		text: 'Posiadamy doświadczenie w realizacji projektów o wysokim stopniu złożoności dla tak zwanych trudnych branż. Nie są nam straszne zaawansowane systemy wspomagające sprzedaż instrumentów finansowych, skomplikowane struktury telekomunikacyjne, ani też szeroki wachlarz produktów oferowanych przez firmę. Każde, nawet najtrudniejsze wyzwanie kończymy mistrzowskim, skutecznym rozwiązaniem.\n',
		button: 'Zobacz, w jaki sposób zapewniamy <br> bezpieczeństwo realizacji',
		section: '.main__why-worth'
	},
	{
		title: 'Ewaluacja dotychczasowych rozwiązań',
		text: 'Wielu z naszych dotychczasowych klientów przyszło do nas z niedokończonym produktem, który utknął w martwym punkcie. Pomagamy dokonać ewaluacji, zarekomendować rozwiązanie i dostarczyć je w formie gotowego produktu. Nie boimy się wyzwań, którym nie podołali inni.',
		button: 'Zobacz, w jaki sposób zapewnimy \n' +
			'<br>wyjście z problemu',
		link: routeConstants.DESIGN_FIX.path,
	},
	{
		title: 'Wieloletnie doświadczenie w pracy dla największych marek',
		text: 'Realizowaliśmy projekty dla największych i najbardziej rozpoznawalnych marek: nie tylko polskich, ale również zagranicznych z listy Fortune 500.',
		button: 'Zobacz, dla kogo <br> zrealizowaliśmy projekty',
		section: '.clients'
	},
]

const whyWorth = [
	{
		title: 'Transparentność',
		text: 'Przejrzystość w realizacji poszczególnych działań zapewnia pełną kontrolę nad procesem poprzez wgląd w dowolnym momencie w aktualnie wykonywane procesy i terminy dostarczenia. Każdy projekt rozłożony jest na poszczególne etapy z wyszczególnieniem konkretnych zadań, co pozwala lepsze dopasowanie do zasobów i możliwości finansowych. '
	},
	{
		title: 'Optymalizacja',
		text: 'Połączenie kompetencji projektowo technologicznych \n' +
			'z biznesowymi pozwala na optymalizację podejmowanych działań, biorąc pod uwagę takie czynniki jak czas, zasoby, możliwości finansowe, obecny stan firmy, plany rozwojowe, \n' +
			'cele, prognozy i dane analityczne oraz insighty konsumenckie. '
	},
	{
		title: 'Raportowanie',
		text: 'Każdy zamknięty etap realizacji oraz wszystkie warsztaty, badania i wywiady podsumowane są prezentacją raportu z wykonanych zadań wraz z wnioskami i rekomendacją dalszych działań. W zależności od potrzeby na życzenie przygotowujemy również dodatkowe raporty międzyzadaniowe.'
	},
	{
		title: 'Doradztwo indywidualne ',
		text: 'Doświadczenie w tworzeniu oraz prowadzeniu projektów pozwala na swobodne i skuteczne omijanie problemów. Przekłada się to na szeroki wachlarz dostępnych możliwości \n' +
			'i rozwiązań w nieprzewidzianych nagłych sytuacjach. \n'
	},
]

const approach = [
	{
		title: 'Realna odpowiedź na problemy \n' +
			'i wyzwania',
		text: 'Opieramy się na danych, wywiadach i badaniach dotyczących Waszej firmy, co pozwala nam na wymierną odpowiedź na problemy i wyzwania przed którymi stoicie. Weryfikujemy nie tylko to, czy założone źródło problemu jest tym prawdziwym, ale również dokonujemy analizy słabych punktów i rekomendujemy na ich podstawie zasadne rozwiązania. Elastycznie dobieramy metodyki badań w zależności od rodzaju problematyki i firmy, co pozwala na podejście do rozwiązania problemu w sposób globalny, techniczny i biznesowy.'
	},
	{
		title: 'Skuteczne narzędzie marketingowe future-fit',
		text: 'Tworzymy produkty, które działają i konwertują bardziej niż tylko zgodnie z założeniami, niezależnie od tego, czy główną funkcją ma być wielokanałowa sprzedaż biletów na koncert czy odciążenie działu obsługi klienta z natłoku powtarzających się zapytań. Zawsze pozostawiamy możliwość poszerzania gotowych produktów o dodatkowe funkcje w zależności od potrzeb, aby Wasza firma była dobrze przygotowana na nadchodzącą przyszłość i pojawiające się nowe wyzwania.'
	},
	{
		title: 'Jasne i proste ścieżki użytkownika',
		text: 'Dobra architektura informacji to przejrzysta struktura, która jest zrozumiała niezależnie od wieku i stopnia obycia ze światem cyfrowym jej odbiorcy. Naszą misją jest tworzenie produktów przystępnych i zrozumiałych niezależnie od stopnia skomplikowania. Stawiamy prawdziwego człowieka i jego wygodę w centrum naszych działań, co namacalnie zwiększa współczynniki pozytywnego user experience i przekłada się na zwiększenie konwersji.'
	},
	{
		title: 'Ułożenie procesów wewnątrzfirmowych',
		text: 'Chociaż nie jest to naszym bezpośrednim celem, aż 63% naszych klientów zdających się na nas w kwestii poprawy dotychczasowego produktu cyfrowego stwierdziło, że przeprowadzone przez nas warsztaty pomogły im uporządkować procesy wewnątrzfirmowe i uświadomiły istnienie dodatkowych produktów, jakich firma do tej pory nie sprzedawała. Umożliwiło to naszym klientom poprawę oferty i struktur firmowych, a tym samym globalne przygotowanie skutecznej strategii rozwoju i poprawę komunikacji z końcowymi użytkownikami.'
	},
	{
		title: 'Zwrot z inwestycji (ROI)',
		text: 'Budujemy produkty, które poza doraźnym rozwiązaniem problemu generują także oszczędność czasu i pieniędzy w perspektywie długofalowej. Upraszczamy i automatyzujemy procesy zakupowe, maksymalizujemy sprzedaż poprzez cross-selling i up-selling, ale także kreujemy narzędzia dla marketera, które z jednej strony odciążają go w codziennej pracy, co generuje oszczędność wykorzystywanych zasobów, a z drugiej ułatwiają mu sprawne dotarcie do zainteresowanych klientów. Posiadamy duże doświadczenie w realizacji projektów pozyskujących gorące leady.'
	}
]

const images = [
	[
		{
			src: require('@/assets/images/home/logs/01_facebook.png'),
			alt: 'facebook'
		},
		{
			src: require('@/assets/images/home/logs/02_Qualcomm.png'),
			alt: '02_Qualcomm'
		},
		{
			src: require('@/assets/images/home/logs/03_ExxonMobil.png'),
			alt: '03_ExxonMobil'
		},
		{
			src: require('@/assets/images/home/logs/04_Youtube.png'),
			alt: 'youtube'
		},
		{
			src: require('@/assets/images/home/logs/05_GE_Appliances.png'),
			alt: 'appliance'
		},
	],
	[
		{
			src: require('@/assets/images/home/logs/06_Kia.png'),
			alt: 'kia'
		},
		{
			src: require('@/assets/images/home/logs/07_WWF.png'),
			alt: 'wwf'
		},
		{
			src: require('@/assets/images/home/logs/08_Ikea.png'),
			alt: 'ikea'
		},
		{
			src: require('@/assets/images/home/logs/09_PhillipMorris.png'),
			alt: 'phillipmorris'
		},
		{
			src: require('@/assets/images/home/logs/10_Starbucks.png'),
			alt: 'starbucks'
		},
	],
	[
		{
			src: require('@/assets/images/home/logs/11_Medicover.png'),
			alt: 'medicover'
		},
		{
			src: require('@/assets/images/home/logs/12_Grupa Luxmed.png'),
			alt: 'luxmed'
		},
		{
			src: require('@/assets/images/home/logs/13_Cellivia.png'),
			alt: 'cellivia'
		},
		{
			src: require('@/assets/images/home/logs/14_Orlen.png'),
			alt: 'orlen'
		},
		{
			src: require('@/assets/images/home/logs/15_Amic.png'),
			alt: 'amic'
		},
	],
	[
		{
			src: require('@/assets/images/home/logs/16_Play.png'),
			alt: 'play'
		},
		{
			src: require('@/assets/images/home/logs/17_Tmobile.png'),
			alt: 'tmobile'
		},
		{
			src: require('@/assets/images/home/logs/18_Exatel.png'),
			alt: 'exatel'
		},
		{
			src: require('@/assets/images/home/logs/19_UPC.png'),
			alt: 'upc'
		},
		{
			src: require('@/assets/images/home/logs/20_PLL_LOT.png'),
			alt: 'lot'
		},
		{
			src: require('@/assets/images/home/logs/21_czechairlines.png'),
			alt: 'czech airlines'
		},
	],
	[
		{
			src: require('@/assets/images/home/logs/22_PZU.png'),
			alt: 'pzu'
		},
		{
			src: require('@/assets/images/home/logs/23_citi_handlowy.png'),
			alt: 'citi handlowy'
		},
		{
			src: require('@/assets/images/home/logs/24_ING_Bank.png'),
			alt: 'ing'
		},
		{
			src: require('@/assets/images/home/logs/25_Multisport.png'),
			alt: 'multisport'
		},
		{
			src: require('@/assets/images/home/logs/26_Decathlon.png'),
			alt: 'decathlon'
		},
		{
			src: require('@/assets/images/home/logs/27_Pandora.png'),
			alt: 'pandora'
		},
		{
			src: require('@/assets/images/home/logs/28_loreal.png'),
			alt: 'loreal'
		},
		{
			src: require('@/assets/images/home/logs/29_Maybelline.png'),
			alt: 'maybelline'
		},
	],
	[
		{
			src: require('@/assets/images/home/logs/30_scjohnson.png'),
			alt: 'scjohnson'
		},
		{
			src: require('@/assets/images/home/logs/31_chicco.png'),
			alt: 'chicco'
		},
		{
			src: require('@/assets/images/home/logs/32_Nivea.png'),
			alt: 'nivea'
		},
		{
			src: require('@/assets/images/home/logs/33_Ferrero.png'),
			alt: 'ferrero'
		},
		{
			src: require('@/assets/images/home/logs/34_Mentos.png'),
			alt: 'mentos'
		},
		{
			src: require('@/assets/images/home/logs/35_chupa_chups.png'),
			alt: 'chupa chups'
		},
		{
			src: require('@/assets/images/home/logs/36_Danone.png'),
			alt: 'danone'
		},
		{
			src: require('@/assets/images/home/logs/37_Auchan.png'),
			alt: 'auchan'
		},
		{
			src: require('@/assets/images/home/logs/38_LeroyMerlin.png'),
			alt: 'merlin'
		},
		{
			src: require('@/assets/images/home/logs/39_SMYK.png'),
			alt: 'smyk'
		},
	],
	[
		{
			src: require('@/assets/images/home/logs/40_Premio.png'),
			alt: 'premio'
		},
		{
			src: require('@/assets/images/home/logs/41_Huhtamaki.png'),
			alt: 'huhtamaki'
		},
	]
]

export {headers, mainOffers, why, whyWorth, approach, images}
