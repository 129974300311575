import {animation} from '@/data/animation'
import {en} from '@/data/en/data'
import FAQComponent from '@/components/FAQComponent/index'

import Home from './content/Home'
import SubPage from '@/components/SubPage/SubPage'

export default {
	name: 'Animation',
	components: {
		Home,
		SubPage,
		FAQComponent
	},
	computed: {
		content() {
			if (this.$store.state.animationContent) {
				return this.$store.state.animationContent
			}
		}
	},
	data() {
		return {
			animation,
			en
		}
	}
}
