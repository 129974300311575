import routeConstants from "@/router/routePath";

const enHeaders = [
	{
		id: '/en',
		logoColor: 'white',
		title: 'We create top-notch animations as an effective video marketing tool.',
		image: require('@/assets/images/animation/Animacja_home/header.webp'),
		imageMobile: require('@/assets/images/animation/Animacja_home/header-mobile.webp'),
		list: [
			{
				name: 'PRODUCT ANIMATIONS'
			},
			{
				name: 'ANIMATED COMMERCIALS'
			},
			{
				name: 'PROMOTIONAL VIDEO'
			},
			{
				name: 'MOTION DESIGN'
			},
			{
				name: 'WHITEBOARD ANIMATIONS'
			},
			{
				name: 'CHARACTER ANIMATION'
			},
		]
	},
	{
		id: routeConstants.ANIMATION_PRODUCTION_PROCESS.alias,
		logoColor: 'white',
		title: 'Animation Production Process',
		smallTitle: 'Animation',
		image: require('@/assets/images/animation/Animacja_proces/01_header.webp'),
		imageMobile: require('@/assets/images/animation/Animacja_proces/01_header-mobile.webp')
	},
	{
		id: routeConstants.PRODUCT_ANIMATION.alias,
		logoColor: 'white',
		title: "Product & Explainer Videos",
		image: require('@/assets/images/animation/Animacja_produktowa/01_header.webp'),
		imageMobile: require('@/assets/images/animation/Animacja_produktowa/01_header-mobile.png')
	},
	{
		id: routeConstants.ADVERTISEMENT_ANIMATION.alias,
		logoColor: 'white',
		title: "Animated commercials",
		image: require('@/assets/images/animation/Animacja_reklamowa/01_header.webp'),
		imageMobile: require('@/assets/images/animation/Animacja_reklamowa/01_header-mobile.webp')
	},
	{
		id: routeConstants.PROMOTIONAL_ANIMATION.alias,
		logoColor: 'black',
		title: "Promotional videos",
		image: require('@/assets/images/animation/Animacja_promocyjna/01_header.webp'),
		imageMobile: require('@/assets/images/animation/Animacja_promocyjna/01_header-mobile.webp')
	},
	{
		id: routeConstants.ONBOARDING_ANIMATION.alias,
		logoColor: 'black',
		title: "Onboarding animations",
		image: require('@/assets/images/animation/Animacja_onboardingowa/01_header.webp'),
		imageMobile: require('@/assets/images/animation/Animacja_onboardingowa/01_header-mobile.webp')
	},
	{
		id: routeConstants.PRESENTATION_ANIMATION.alias,
		logoColor: 'black',
		title: "Animated presentations",
		image: require('@/assets/images/animation/Animacja_prezentacyjna/01_header.webp'),
		imageMobile: require('@/assets/images/animation/Animacja_prezentacyjna/01_header-mobile.webp')
	},
	{
		id: routeConstants.ABOUT.alias,
		logoColor: 'white',
		title: "Our mission",
		image: require('@/assets/images/header/about_banner.webp'),
		imageMobile: require('@/assets/images/animation/Animacja_prezentacyjna/01_header-mobile.webp')
	},

	//contact
	{
		id: routeConstants.CONTACT.alias,
		logoColor: 'white',
		title: 'CONTACT',
		smallTitle: 'How can we help?',
		image: require('@/assets/images/header/contact_banner.webp')
	},
]

export {enHeaders}
