const routeConstants = {
	HOME: {
		path: '/',
		name: 'Home'
	},
	ABOUT: {
		path: '/misja',
		name: 'O nas',
		alias: '/en/our-mission'
	},
	CARRIER: {
		path: '/kariera',
		name: 'Kariera'
	},
	CONTACT: {
		path: '/kontakt',
		name: 'kontakt',
		alias: '/en/contact'
	},
	FAQ: {
		path: '/faq',
		name: 'FAQ'
	},
	PRODUCT_FAQ: {
		path: '/faq/service-design',
		name: 'FAQComponent Produkt'
	},
	ANIMATION_FAQ: {
		path: '/faq/animacja',
		name: 'FAQComponent Animacja',
		alias: '/faq/en/animation'
	},
	ANIMATION: {
		path: '/animacja',
		name: 'Animacja',
		alias: '/en'
	},
	ANIMATION_PRODUCTION_PROCESS: {
		path: '/animacja-proces',
		name: 'Animacja proces produkcji',
		alias: '/en/animation-process'
	},
	PRODUCT_ANIMATION: {
		path: '/animacja-produktowa',
		name: 'Animacja Produktowa',
		alias: '/en/explainer-videos'
	} ,
	ADVERTISEMENT_ANIMATION: {
		path: '/animacja-reklamowa',
		name: 'Animacja reklamowa',
		alias: '/en/animated-commercials'
	} ,
	PROMOTIONAL_ANIMATION: {
		path: '/animacja-promocyjna',
		name: 'Animacja promocyjna',
		alias: '/en/promotional-videos'
	} ,
	ONBOARDING_ANIMATION: {
		path: '/animacja-onboardingowa',
		name: 'Animacja onboardingowa',
		alias: '/en/onboarding-animations'
	} ,
	PRESENTATION_ANIMATION: {
		path: '/animacja-prezentacyjna',
		name: 'Animacja prezentacyjna',
		alias: '/en/animated-presentations'
	},
	DESIGN_PROCESS: {
		path: '/service-design-proces',
		name: 'Proces produkcji designu'
	},
	DESIGN_AUDITS: {
		path: '/badania',
		name: 'Audyty, badania warsztaty i konsultacje'
	},
	DESIGN_RESEARCH: {
		path: '/research-strategia-innowacje',
		name: 'Research, strategia i innowacje'
	},
	DESIGN_FIX: {
		path: '/poprawa-uzytecznosci',
		name: 'Poprawa użyteczności i udoskonalanie produktów'
	},
	DESIGN_PRODUCT: {
		path: '/service-design',
		name: 'Product & Service Design'
	},
	DESIGN_DEVELOPMENT: {
		path: '/development',
		name: 'Web & Mobile Development'
	},
	DESIGN_TRANSFORM: {
		path: '/transformacje-cyfrowe',
		name: 'Transformacje cyfrowe'
	},
	DESIGN_IOT: {
		path: '/iot',
		name: 'IOT & Head-Up Display Interfaces'
	},
	DESIGN_SUPPORT: {
		path: '/wsparcie-i-zarzadzanie',
		name: 'Wsparcie i zarządzanie'
	}
}

export default routeConstants
