export const checkboxData = [
	{
		text: 'Myślimy o projekcie, szukamy wykonawcy',
		for: 'new',
		checked: true
	},
	{
		text: 'Jesteśmy w trakcie, ale coś poszło nie tak...',
		for: 'help',
		checked: false
	},
	{
		text: 'Potrzebujemy udoskonalić obecny projekt',
		for: 'improve',
		checked: false
	},
	{
		text: 'Nic z powyższych, chcę opisać nasz problem.',
		for: 'other',
		checked: false
	},
]
