<template>
	<header
		class="header background--dark" :style="banner"
		:class="{
			'header__main' : this.$route.fullPath === '/',
            'header__animation-process' : this.$route.fullPath === '/animacja-proces' || this.$route.fullPath === '/en/animation-process',
            'header__mission' : this.$route.fullPath === '/misja' || this.$route.fullPath === '/en/our-mission',
            'header__contact' : this.$route.fullPath === '/kontakt' || this.$route.fullPath === '/en/contact',
            'header__animation-mobile' :
            (
                this.$route.fullPath.includes('animacja') ||
                this.$route.fullPath.includes('/en')
            ) && this.$store.state.isMobile,
            'header__animation-mobile-fix-padding' :
            (
                this.$route.fullPath.includes('animacja') ||
                this.$route.fullPath.includes('/en')
            )
		}"
	>

		<video
			v-if="this.$route.fullPath === '/' &&
				!this.$store.state.isMobile
			"
			src="@/assets/images/movie.mp4"
			autoplay
			poster="@/assets/images/header/main_banner.webp"
			loop
			muted
			class="video"
		/>

		<div
			v-if="content"
			class="header__content"
			:style="contentStyle"
		>
			<div class="header__top-section">
				<router-link :to="this.$store.state.isEn ? '/en' : '/'">
					<img :src="logo" :alt="content.alt">
				</router-link>

				<Button
					v-if="this.$route.fullPath !== '/kontakt' && this.$route.fullPath !== '/en/contact'"
					buttonStyle="default"
					:buttonLink="this.$store.state.isEn ? '/en/contact' : '/kontakt'"
					:buttonText="this.$store.state.isEn ? 'Let\'s talk' : 'Porozmawiajmy'">
				</Button>
			</div>

			<div
				class="header__titles-box"
				:class="{
					 'mobile-header' : this.$store.state.isMobile,
				     'main-mobile-header' : (
				        this.$route.fullPath === '/' && this.$store.state.isMobile
			        )
				 }"
			>
				<h4 v-if="content.smallTitle" class="h4-style">{{ content.smallTitle }}</h4>
				<h1 v-html="content.title"/>
				<h2 v-if="content.subtitle">{{ content.subtitle }}</h2>
				<p v-if="content.text" class="header-p-text">{{ content.text }}</p>
				<p v-if="content.subtext" class="header-p-subtext" v-html="content.subtext"/>
			</div>

			<div class="a-process-01" v-if="this.$route.fullPath === '/animacja-proces' ||
			this.$route.fullPath === '/en/animation-process'">
				<span class="p-number-style">01</span>
				<h2 class="p-h2-style" style="color: #d8d8d8">
					{{ this.$store.state.isEn ? 'Identifying needs' : 'badanie potrzeb' }} </h2>
				<p class="p-p-style" style="color: #d8d8d8">
					{{
						this.$store.state.isEn ?
							'Let\'s get to know each other! We start our animation process with a talk about your expectations and how we can help you. This stage serves to identify your needs and define the initial scope of work.' :
							'Poznajmy się! Nasz proces realizacji animacji rozpoczynamy od rozmowy na temat oczekiwań i tego, jak możemy Ci pomóc. Ten etap służy rozpoznaniu potrzeb i określeniu, jaki jest wstępny zakres prac.'
					}}
				</p>
			</div>

			<div v-if="this.$route.fullPath === '/kontakt' || this.$route.fullPath === '/en/contact'"
			     class="header__contact-content">
				<div class="col">
					<h2>hello@hylite.pl</h2>
					<h2>(+48) 792 586 279</h2>
				</div>
				<div class="col col-2">
					<p>HYLITE<br>
						<span>Williama Heerleina Lindleya 16</span><br>
						<span>02-013 Warszawa</span><br>
						<span>NIP: 558 18 42 222</span><br>
						<span>REGON: 369 322 350</span><br>
					</p>
				</div>
			</div>

			<div v-if="content.buttons || content.list" class="header__bottom-section">
				<div v-if="content.buttons && !this.$store.state.isMobile"
				     class="header__nav-button-box"
				>
					<h3>{{ content.buttons[0].text }}</h3>

					<div class="header__nav-button-box-link">
						<Button
							class="header--product-button"
							buttonStyle="primary"
							:buttonText="content.buttons[0].subtext"
							@click.prevent="scrollToElement('.main__offer')"
						/>
<!--						<a href="/service-design-proces" target="_blank">{{ content.buttons[0].subtext }}</a>-->
						<img src="@/assets/images/arrow.svg" alt="arrow">
					</div>
				</div>

				<div v-if="content.buttons && !this.$store.state.isMobile"
				     class="header__nav-button-box"
				>
					<h3>{{ content.buttons[1].text }}</h3>

					<div class="header__nav-button-box-link">
						<a href="/animacja" target="_blank">{{ content.buttons[1].subtext }}</a>
						<img src="../../assets/images/arrow.svg" alt="arrow">
					</div>
				</div>

				<!--				//mobile-->
				<div v-if="content.buttons && this.$store.state.isMobile"
				     class="header__nav-button-box"
				>
					<div class="header__nav-button-box-link">
						<a href="/service-design-proces" target="_blank">{{ content.buttons[0].text }}</a>
						<img src="../../assets/images/arrow.svg" alt="arrow">
					</div>
				</div>

				<div v-if="content.buttons && this.$store.state.isMobile"
				     class="header__nav-button-box"
				>
					<div class="header__nav-button-box-link">
						<a href="/animacja" target="_blank">{{ content.buttons[1].text }}</a>
						<img src="../../assets/images/arrow.svg" alt="arrow">
					</div>
				</div>

				<div
					v-if="content.list"
					class="header__bottom-list-section"
				>
					<ul class="header__bottom-list">
						<li
							v-for="(item, i) in content.list"
							:key="i"
							class="header__bottom-list-item"
						>
							<span>{{ item.name }}</span>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</header>
</template>

<script src="./component.js"></script>
<style src="./style.sass" scoped lang="sass"></style>
