import {headers} from '@/data/data'
import {enHeaders} from '@/data/en/headers'
import variables from '@/assets/styles/variables.sass'
import routeConstants from "@/router/routePath";

export default {
	name: "header index",
	watch: {
		'$route.fullPath': {
			handler() {
				let currRoute = this.$route.fullPath

				if (currRoute === '/') {
					this.$store.state.headerContent = this.headers.find(x => x.id === 'home')
				}

				if (currRoute !== '/' && !currRoute.includes('/en')) {
					this.$store.state.headerContent = this.headers.find(x => x.id === currRoute)
				}

				if (currRoute.includes('/en')) {
					this.$store.state.headerContent = this.enHeaders.find(x => x.id === currRoute)
				}
			}
		}
	},
	created() {
		this.handleHeaderContent()
	},
	computed: {
		content() {
			return this.$store.state.headerContent
		},
		logo() {
			switch (true) {
				case this.content.logoColor === 'white':
					return require('@/assets/images/logo_white.svg')
				case this.content.logoColor === 'black':
					return require('@/assets/images/logo_black.svg')

				default:
					return require('@/assets/images/logo_black.svg')
			}
		},
		banner() {
			let bgSize = 'cover'
			let banner

			if (
				this.$route.fullPath === routeConstants.PRODUCT_ANIMATION.path ||
				this.$route.fullPath === routeConstants.ANIMATION.path ||
				this.$route.fullPath === routeConstants.ADVERTISEMENT_ANIMATION.path ||
				this.$route.fullPath === routeConstants.PROMOTIONAL_ANIMATION.path ||
				this.$route.fullPath === routeConstants.ONBOARDING_ANIMATION.path ||
				this.$route.fullPath === routeConstants.PRESENTATION_ANIMATION.path ||
				this.$route.fullPath === routeConstants.ANIMATION_PRODUCTION_PROCESS.path
			) {
				if (this.$store.state.isMobile) {
					banner = this.setBanner()
				}
			}

			banner = this.setBanner()

			return {
				'--bg-size': bgSize,
				'--banner-image': banner
			}
		},
		contentStyle() {
			let padding
			let listPadding
			let bgSize

			// titles h1, h2
			let fontSize
			let fontWeight
			let fontColor

			//h2
			let h2FontColor

			switch (this.$route.fullPath) {
				case routeConstants.HOME.path:
					fontSize = '62px'
					fontWeight = variables.weightSemiBold
					fontColor = variables.colorLight
					h2FontColor = variables.colorGray
					padding = '181px 107px 0'
					listPadding = '0 174px 0 120px'
					break;

				case routeConstants.ANIMATION.path:
				case routeConstants.ANIMATION.alias:
					bgSize = '100% 100vh'
					fontSize = '50px'
					fontColor = variables.colorLight
					fontWeight = variables.weightSemiBold
					padding = '181px 0 50px 107px'
					listPadding = '0 100px'
					break;

				case routeConstants.PRODUCT_ANIMATION.path:
				case routeConstants.ADVERTISEMENT_ANIMATION.path:
				case routeConstants.PROMOTIONAL_ANIMATION.path:
				case routeConstants.ONBOARDING_ANIMATION.path:
				case routeConstants.PRESENTATION_ANIMATION.path:
				case routeConstants.PRODUCT_ANIMATION.alias:
				case routeConstants.ADVERTISEMENT_ANIMATION.alias:
				case routeConstants.PROMOTIONAL_ANIMATION.alias:
				case routeConstants.ONBOARDING_ANIMATION.alias:
				case routeConstants.PRESENTATION_ANIMATION.alias:
					bgSize = '100% 100%'
					padding = '371px 0 0 107px'
					fontSize = '62px'
					fontColor = variables.colorLight
					fontWeight = variables.weightSemiBold
					break;

				case routeConstants.ANIMATION_PRODUCTION_PROCESS.path:
				case routeConstants.ANIMATION_PRODUCTION_PROCESS.alias:
					padding = '100px 0 0 100px'
					fontSize = '62px'
					fontColor = variables.colorLight
					fontWeight = variables.weightSemiBold
					break;

				//design

				case routeConstants.DESIGN_AUDITS.path:
				case routeConstants.DESIGN_RESEARCH.path:
				case routeConstants.DESIGN_FIX.path:
				case routeConstants.CONTACT.path:
				case routeConstants.CONTACT.alias:
					padding = '95px 0 0 107px'
					fontSize = '62px'
					fontColor = variables.colorLight
					fontWeight = variables.weightSemiBold
					break;

				case routeConstants.ABOUT.path:
				case routeConstants.ABOUT.alias:
				case routeConstants.DESIGN_PRODUCT.path:
				case routeConstants.DESIGN_DEVELOPMENT.path:
				case routeConstants.DESIGN_TRANSFORM.path:
				case routeConstants.DESIGN_IOT.path:
				case routeConstants.DESIGN_SUPPORT.path:
					padding = '240px 0 0 107px'
					fontSize = '62px'
					fontColor = variables.colorLight
					fontWeight = variables.weightSemiBold
					break;

				default:
					break;
			}

			return {
				'--list-padding': listPadding,
				'--header-padding': padding,
				'--header-font-size': fontSize,
				'--header-font-weight': fontWeight,
				'--header-font-color': fontColor,
				'--header-h2-font-color': h2FontColor
			}
		},
	},
	data() {
		return {
			headers,
			enHeaders
		}
	},
	methods: {
		handleHeaderContent() {
			let currRoute = this.$route.fullPath

			if (currRoute !== '/' && !this.$store.state.isEn) {
				this.$store.state.headerContent = this.headers.find(x => x.id === currRoute)
			}

			if (currRoute === '/') {
				this.$store.state.headerContent = this.headers.find(x => x.id === 'home')
			}

			if (this.$store.state.isEn) {
				if (currRoute === '/en') {
					this.$store.state.headerContent = this.enHeaders.find(x => x.id === '/en')
				} else {
					this.$store.state.headerContent = this.enHeaders.find(x => x.id === currRoute)
				}
			}
		},
		scrollToElement(elem) {
			const e = document.querySelector(elem)
			e.scrollIntoView({
				behavior: "smooth",
				block: "start"
			})
		},
		setBanner() {
			if (this.$store.state.isMobile) {
				if (this.$store.state.headerContent) {
					if (this.$store.state.headerContent.imageMobile) {
						return `url("${this.$store.state.headerContent.imageMobile}")`
					} else {
						return `url("${this.$store.state.headerContent.image}")`
					}
				}
			}

			if (this.$store.state.headerContent) {
				return `url("${this.$store.state.headerContent.image}")`
			}
		}
	}
}
