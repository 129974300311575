export const animationENFaq = [
	{
		title: 'How much time does it take to create a business animation?',
		text: 'The process of production takes 8-14 weeks on average. In practice, it depends on factors such as the length of the animation and its complexity, but also the decision chain on the client\'s side. Creating the video can be accelerated by simplifying, but please note that it may cause the animation to look unattractive. Thus it may not fully perform its expected function.',
	},
	{
		title: 'If we want to have an animation, at what time should we contact you to start the project?',
		text: 'In most cases, production takes around 8-14 weeks. Still, it depends on the length of the animation, the overall complexity of the project, and the rate of the response on the client\'s side. So the sooner you contact us, the better we\'ll be able to respond to your query.'
	},
	{
		title: 'What do we need to provide to start the project?',
		textArray: [
			{
				text: 'First of all, before we start the project, we\'d like to hear what your needs and expectations are. It will also allow us to ask you specific questions.'
			},
			{
				text: 'As for the information and materials, we will need: '
			},
			{
				text: `- a brief containing information on what you need;<br>
					- voice-over text (if you want to create it on your side);<br>
					- information on how long the animation should be and whether the time limit is a necessary condition (e.g. Youtube advertisement time limitations);<br>
					- all necessary technical information (we deliver animations in *.avi, full HD 1920x1080 as standard unless specified otherwise);<br>
					- information about where and how you'd like to use the animation.<br>
				`
			},
			{
				text: 'Also, it would help us a lot, but it\'s not obligatory:'
			},
			{
				text: `
					- any advertising materials of the company that may be a hint (e.g. dominant colours)<br>
					- links to animations you like (if you have any);<br>
					- links to your previous animations, including the information whether you want the new animation to refer to them or not.<br>		
				`
			}
		]
	},
	{
		title: 'Can we skip the storyboard stage? All scenes are clearly and precisely described in the brief I already provided.',
		text: 'Unfortunately, we give up on creating storyboards in very rare cases, and it applies to short animations only (up to 15 seconds). We do storyboards for each project because we need to check if your ideas are feasible and what tools we need. It\'s also necessary to prevent the wrong timing and introduce any changes and corrections if required. Drawing a storyboard is much simpler and also cheaper than making changes to the illustration or animation stage.'
	},
	{
		title: 'Why is the quality of the animation important?',
		text: 'Business animation has become common. It means that it\'s not just enough to have it. It has to be attractive enough to make the customers interested in it, watch it and keep their attention through the video. What\'s more, business animation, just like any other brand material, influences the company\'s credibility.'
	},
	{
		title: 'Can I do the entire scenario (script + description of the visual representation of the scenes) by myself?',
		text: 'If you\'re a marketing specialist, it\'s perfectly fine (and even recommended) for you to write the script (voice-over text). You know best what you want to tell your audience!',
		textArray: [
			{
				text: 'When it comes to describing the scenes, you can do it by yourself, of course. Still, we recommend this approach only for animated presentations. In other cases, writing the scenes by yourself can be risky, especially if you don\'t have experience with animation daily. While it\'s tempting to see your ideas come to life, it\'s also dangerous for quality and finance optimization. Lack of skills in optimizing animation technologically at the concept development stage may, in extreme cases, increase the cost of production up to three times. It may also influence the timings and dynamics and make the animation dull.'
			},
			{
				text: 'If you\'re still very determined about following a specific vision of yours, let us know: we\'ll try to optimize your ideas as much as possible.'
			}
		]
	},
	{
		title: 'Why doesn\'t your process and stage "Scenario" involve a typical scenario? Isn\'t it more helpful for both sides to have descriptions of the scenes before they get drafted?',
		textArray: [
			{
				text: 'While creating classic scenarios is common for many animation studios, they\'re usually a pain for the customers and the production department and often negatively influences the final effect.'
			},
			{
				text: 'It\'s easy to imagine a couple sitting in a park under a tree and talking to each other while drinking coffee. It is harder to imagine the story of the transformation of an energy sphere in outer space, splitting into lines forming waves that begin to vibrate back and forth on the horizontal axis.\n'
			},
			{
				text: 'The storyboard helps to describe the scenes and visualize them in the form of loose sketches simultaneously. So what does it give us?'
			},
			{
				text: `
					- Better explanation and presentation of particular ideas;<br>
					- Easy adjustments when necessary;<br>
					- More comfort for you: you can focus on valuing the vision, not on judging if our interpretation of your descriptions is correct;<br>
					- Everyone can understand the vision in the same way;<br>
					- A comprehensive view of the project while creating the scenes. Thanks to this, we avoid the repetition of scenes and ideas;<br>
					- Full control over timing so that none of the scenes is neither too short nor too long. Too short scenes are confusing, too long bore your viewers;<br>
					- Possibility to evaluate the applied visual solutions at an early stage of implementation;<br>
					- Optimizing the animation at an early stage when it is crucial for both the attractive effect and technological and financial aspects;<br>
					- Possibility to quickly show the overview to the rest of your team or board members.<br>
				`
			}
		]
	},
	{
		title: 'Can I prepare the script (voice-over text) on my own?',
		text: 'Yes! It\'s an excellent idea when you\'re a marketing specialist, and you know what you need the animation for and what exactly the content you need. Our role in this process will be to visually complement what you\'re talking about in a possible attractive way.'
	},
	{
		title: 'Can we provide illustrations so you could animate them?',
		text: 'Yes! It\'s an excellent idea when you\'re a marketing specialist, and you know what you need the animation for and what exactly the content you need. Our role in this process will be to visually complement what you\'re talking about in a possible attractive way.'
	},
	{
		title: 'Can we provide illustrations so you could animate them?',
		text: 'Yes - but before we animate them, we\'ll have to verify first, if they\'re correctly prepared, and if not - we\'ll have to make some technical adjustments.\n'
	},
	{
		title: 'How long should the animation be?',
		text: 'Depending on where you want to use the video, it\'s approximately 15-30 seconds (social media) up to 1.5-2 minutes (website). It\'s the recommended time to keep the customer\'s attention if they are interested in your product. In the case of more extended materials, the risk of skipping your animation before the end increases significantly.'
	},
	{
		title: 'Can we make one long animation filled with all the necessary information instead of making a few shorter videos? Then, it would be universal, right?',
		text: 'We can do it, but there\'s a very high risk that such animation will convert poorly. Let us put ourselves in our customers\' shoes: rarely any advertisement attracts us so much that we spend more than two minutes watching it. How much information do we absorb without shutting it down, and how much do we remember? It\'s also a common mistake to assume that our customer is so interested in our service that they\'ll be determined enough to go through a few minutes of material. A much better option may be to cut one long animation into a few shorter ones. It will help develop specific topics without overwhelming the customer and have a promotional function at the same time. You\'ll be able to put such animations, for example, on subpages of your website, where they\'ll explain the content, but also in the form of interesting facts in social media.'
	},
	{
		title: 'We just came up with the idea of creating a short animation. Can we do it in two weeks?',
		text: 'There is a chance if the animation is 10-15 seconds long and has an obvious and specific scenario!'
	},
	{
		title: 'We\'ve just watched our competitors\' business animation, could you create something similar for us?',
		textArray: [
			{
				text: 'Of course, we can do it, but are you sure you want it to look similar to your competitors videos?'
			},
			{
				text: 'There are no two companies alike, even if they operate in the same area and solve similar issues. Creating an animation is a unique opportunity to highlight your company\'s voice. We\'ll be happy to show you that you don\'t have to follow your competition to get a working and effective video marketing tool: let\'s discover your own visual voice together!'
			}
		]
	},
	{
		title: 'Do you do whiteboard animations?',
		textArray: [
			{
				text: 'Yes. Please note, however, while whiteboard animation is a popular type of animation right now, it\'s often not the best option. Whiteboard animation is perfect for internal presentations, explaining the offer to contractors and presenting the results with the management board. Still, it may not be a good solution for you if your goal is to reach customers due to the lack of many marketing and sales values.'
			},
			{
				text: 'Suppose you intend to make clear, minimalistic animation with toned colours - in that case, there are better ways to do it. We\'re happy to help with minimalistic yet attractive forms of videos.'
			}
		]
	},
	{
		title: 'Why is storytelling so important, and how does it work?',
		textArray: [
			{
				title: 'It\'s vital to show a story through animation: the storyline should be a logical and exciting sequence of scenes permeating smoothly rather than a slideshow of cut still boring pictures. In addition, storytelling helps in building relationships with the brand with the help of emotions and engagement.'
			},
			{
				text: 'It doesn\'t necessarily have to be a story about a character and their adventures. It\'s important to maintain continuity and consistency of the video as much as possible, however.'
			},
			{
				text: 'Skillfully conducted storytelling arouses interest, attracts customers\' attention, and allows them to remember your product and what you said about it for a longer time.'
			}
		]
	},
	{
		title: 'We\'ve done animation before, but we\'d like to refresh it. If it is possible?',
		text: 'Animation production is a custom cascade service which means, in simple words, that we can\'t improve it by changing what has already been animated. We can, however, prepare a new version that will better meet your current needs!'
	}
]
