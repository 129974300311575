import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import ClickOutside from './helpers/clickOutside'

import Header from './components/Header/index.vue'
import Navbar from './components/Navbar/index.vue'
import MobileNavbar from './components/Navbar/MobileNavbar/index.vue'
import Button from './components/Button/index.vue'
import BarIcon from './components/BarIcon/index.vue'
import Slider from './components/Slider/index.vue'
import FooterApp from './components/Footer/index.vue'
import FormApp from './components/Form/index.vue'
import Clients from './components/Clients/index.vue'
// views
import Animation from './views/Animation'
import Main from './views/Main/index.vue'
import Faq from './views/FAQ/index.vue'

const app = createApp(App)

app.component('Navbar', Navbar)
app.component('MobileNavbar', MobileNavbar)
app.component('Header', Header)
app.component('Button', Button)
app.component('BarIcon', BarIcon)
app.component('Slider', Slider)
app.component('Main', Main)
app.component('FormApp', FormApp)
app.component('FooterApp', FooterApp)
app.component('Animation', Animation)
app.component('Clients', Clients)
app.component('Faq', Faq)
app.directive('click-outside', ClickOutside)
app.use(store)
app.use(router)

router.isReady().then(() => app.mount("body"))
