<template>
	<section class="faq">
		<div class="faq__heading">
			<router-link :to="this.$store.state.isEn ? '/en' : '/'">
				<img src="@/assets/images/logo_black.svg" alt="logo">
			</router-link>
			<Button
				buttonStyle="default"
				:buttonText="this.$store.state.isEn ? 'Let\'s talk' : 'Porozmawiajmy'"
				buttonSize="normal"
				:buttonLink="this.$store.state.isEn ? '/en/contact' : '/kontakt'">
			</Button>
		</div>

		<div class="faq__content">
			<div class="faq__content-heading">
				<div class="box">
					<h4 class="h4-style">{{ title }}</h4>
					<h2 class="h2-style h2-sm-style">FAQ</h2>
				</div>

				<Button
					v-if="!this.$store.state.isEn"
					buttonStyle="primary"
					:additionalElement="true"
					:buttonLink="link"
					additionalElementStyle="arrowInCircle"
					:buttonText="buttonText"
					@click="isOpened = !isOpened"
				>
				</Button>
			</div>

			<div class="faq__container">
				<Dropdown
					v-for="(item, index) in faq"
					:key="index"
					:isOpened="isOpened"
				>
					<template #title> <h3 class="dropdown__title"> {{ item.title }} </h3></template>
					<template #content>
						<ul class="faq__list">
							<li
								class="faq__item"
							>
								<p
									v-if="item.textArray"
									v-for="subItem in item.textArray"
									v-html="subItem.text"
								/>

								<p v-else v-html="item.text"/>
							</li>
						</ul>
					</template>
				</Dropdown>

			</div>
			<Button
				v-if="!this.$store.state.isEn"
				buttonStyle="primary"
				:additionalElement="true"
				buttonType="routerLink"
				:buttonLink="link"
				additionalElementStyle="arrowInCircle"
				:buttonText="buttonText"
				@click="isOpened = !isOpened"
			>
			</Button>
		</div>
	</section>
</template>

<script src="./component.js"></script>
<style src="./style.sass" lang="sass"></style>
