const productSlides = [
	// {
	// 	id: 'product1',
	// 	src: require('@/assets/images/slider/product/01_Amic.webp'),
	// 	company: 'Amic',
	// 	title: 'Aplikacja dla sieci paliwowej',
	// 	text: 'Pracowaliśmy nad aplikacją dla klientów sieci paliwowej, zwiększającą sprzedaż i wspomagającą cross-seling: w tym celu przeprowadziliśmy warsztaty, opracowaliśmy strategię rozwoju, stworzyliśmy architekturę informacji (UX) i interaktywne makiety, a także zaprojektowaliśmy warstwę wizualną (UI).'
	// },
	{
		id: 'product2',
		src: require('@/assets/images/slider/product/02_ZdrowyPakiet.webp'),
		company: 'Zdrowy Pakiet',
		title: 'Pakiety medyczne online',
		text: 'Ustrukturyzowaliśmy procesy i usługi, zbudowaliśmy proces obsługi zamówień i doboru pakietów, zbudowaliśmy architekturę informacji (UX) wraz z interaktywnymi makietami, a także stworzyliśmy warstwę wizualną (UI). Dzięki temu ułatwiliśmy klientom łatwy dobór pakietu medycznego bezpośrednio pod swoje potrzeby.\n'
	},
	{
		id: 'product3',
		src: require('@/assets/images/slider/product/03_Exatel.webp'),
		company: 'Exatel',
		title: 'Serwis dla operatora telekomunikacyjnego',
		text: 'Wspomogliśmy zmianę funkcji serwisu z informacyjnej na sprzedażową. Projekt obejmował przeprowadzenie serii warsztatów wewnętrznych, utworzenie nowej wersji MVP, opracowanie strategii rozwoju serwisu na dalszych etapach, stworzenie matrycy treści, a także zaprojektowanie interaktywnych makiet oraz przetestowanie ich w badaniach IDI.'
	},
	{
		id: 'product4',
		src: require('@/assets/images/slider/product/04_WWF.webp'),
		company: 'WWF',
		title: 'Zwiększenie darowizn na fundację ekologiczną',
		text: 'Przeprowadziliśmy szczegółowy audyt kanałów komunikacji i funkcjonalności strony, wskazując elementy wymagające optymalizacji. Dzięki temu ułatwiliśmy proces przekazywania funduszy przez darczyńców, zwiększając ich ilość.'
	},
]

const animationSlides = [
	{
		id: 'animation1',
		src: require('@/assets/images/slider/animation/01.webp'),
		company: '01',
		title: 'Animacja explainer video dla aplikacji',
		text: 'Dla klienta z branży rozrywkowej stworzyliśmy animację ukazującą sposób działania aplikacji i specjalnie stworzonego landing page\'a konkursowego.'
	},
	{
		id: 'animation2',
		src: require('@/assets/images/slider/animation/02.webp'),
		company: '02',
		title: 'Animacja promocyjna dla sklepu ogrodniczego',
		text: 'Wyprodukowaliśmy animację dla firmy prowadzącej sprzedaż roślin i artykułów ogrodniczych, która poprzez krótką historię pozwoliła zapoznać się z ogólną ofertą sklepu i zachęcić do kupna produktów.'
	},
	{
		id: 'animation3',
		src: require('@/assets/images/slider/animation/03.webp'),
		company: '03',
		title: 'Animacja reklamowa dla laboratorium',
		text: 'Stworzyliśmy animację dla jednego z polskich laboratoriów, której celem było podkreślenie, że profesjonalne usługi laboratoryjne dostępne są również dla przeciętnego człowieka.'
	},
	{
		id: 'animation4',
		src: require('@/assets/images/slider/animation/04.webp'),
		company: '04',
		title: 'Animacja reklamowa dla producenta win',
		text: 'Opracowaliśmy animację dla producenta win deserowych, której celem było zaprezentowanie wina jako atrakcyjnego trunku na spotkania.'
	},
]

const animationHomeSlides = [
	{
		id: 'slide 1',
		number1: '01',
		text1: 'Dotarcie bezpośrednio do wybranej przez siebie grupy docelowej z najbardziej atrakcyjną formą marketingu, której popularność dynamicznie rośnie;',
		number2: '02',
		text2: 'Pokazuje działanie produktu lub usługi oraz jakie korzyści przez to oferuje;',
		number3: '03',
		text3: 'Pozornie nudne procesy i skomplikowane zagadnienia stają się proste oraz jednocześnie atrakcyjne;'
	},
	{
		id: 'slide 2',
		number1: '04',
		text1: 'Przyciąga uwagę odbiorców na dłużej, oraz zachęca do dalszego zainteresowania się produktem;',
		number2: '05',
		text2: 'Podkreśla wyjątkowość oferowanego produktu lub usługi;',
		number3: '06',
		text3: 'Pomaga w budowie pozytywnego odbioru marki;'
	},
	{
		id: 'slide 3',
		number1: '07',
		text1: 'Pozwala wyjaśnić trudne i skomplikowane zagadnienia, dzięki użyciu abstrakcyjnych form i wizualnych uproszczeń;',
		number2: '08',
		text2: 'Daje możliwość zaangażowania emocjonalnego, co wpływa na lepsze zapamiętywanie i rozpoznawalność produktu, usługi oraz samej marki;',
		number3: '09',
		text3: 'W atrakcyjny sposób dostarcza odpowiedzi na częste pytania, co pozwala na odciążenie działu sprzedaży;'
	}
]

const animationHomeSlidesMobile = [
	{
		id: 'slide 1',
		number: '01',
		text: 'Dotarcie bezpośrednio do wybranej przez siebie grupy docelowej z najbardziej atrakcyjną formą marketingu, której popularność dynamicznie rośnie;',
	},
	{
		id: 'slide 2',
		number: '02',
		text: 'Pokazuje działanie produktu lub usługi oraz jakie korzyści przez to oferuje;',
	},
	{
		id: 'slide 3',
		number: '03',
		text: 'Pozornie nudne procesy i skomplikowane zagadnienia stają się proste oraz jednocześnie atrakcyjne;'
	},
	{
		id: 'slide 4',
		number: '04',
		text: 'Przyciąga uwagę odbiorców na dłużej, oraz zachęca do dalszego zainteresowania się produktem;',
	},
	{
		id: 'slide 5',
		number: '05',
		text: 'Podkreśla wyjątkowość oferowanego produktu lub usługi;',
	},
	{
		id: 'slide 6',
		number: '06',
		text: 'Pomaga w budowie pozytywnego odbioru marki;'
	},
	{
		id: 'slide 7',
		number: '07',
		text: 'Pozwala wyjaśnić trudne i skomplikowane zagadnienia, dzięki użyciu abstrakcyjnych form i wizualnych uproszczeń;',
	},
	{
		id: 'slide 8',
		number: '08',
		text: 'Daje możliwość zaangażowania emocjonalnego, co wpływa na lepsze zapamiętywanie i rozpoznawalność produktu, usługi oraz samej marki;',
	},
	{
		id: 'slide 9',
		number: '09',
		text: 'W atrakcyjny sposób dostarcza odpowiedzi na częste pytania, co pozwala na odciążenie działu sprzedaży;'
	}
]

export {productSlides, animationSlides, animationHomeSlides, animationHomeSlidesMobile}
