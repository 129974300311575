<template>
	<section class="main">
		<div class="main__content">
			<div class="main__about container">
				<div class="main__about-heading">
					<h2>
						Jesteśmy digital housem, który specjalizuje się w tworzeniu wysokiej klasy produktów cyfrowych
						dla
						firm.
					</h2>

					<p>
						Łączymy najlepsze cechy agencji reklamowych i software house'ów. Spajamy marketing oraz cele
						biznesowe
						z kompetencjami kreatywnymi i technologicznymi, dzięki czemu dostarczamy dedykowane rozwiązania
						dla
						biznesu.
					</p>
				</div>

				<div class="main__about-nav">
					<Button
						:additionalElement="true"
						additionalElementStyle="arrowInCircle"
						buttonStyle="primary"
						buttonLink="/"
						buttonText="Sprawdź, jak możemy Wam pomóc"
						@click.prevent="scrollToElement('.main__offer')"
					>
					</Button>
				</div>
			</div>

			<div class="main__belt-placeholder container">
				<ul class="main__belt-placeholder-list" v-if="!this.$store.state.isMobile">
					<li class="main__belt-placeholder-item">WEB / MOBILE / PWA</li>
					<li class="main__belt-placeholder-item">ANDROID / iOS</li>
					<li class="main__belt-placeholder-item">DIGITAL POS</li>
					<li class="main__belt-placeholder-item">HEAD UP DISPLAY</li>
					<li class="main__belt-placeholder-item">IOT</li>
				</ul>

				<div v-else>
					<ul class="main__belt-placeholder-list">
						<li class="main__belt-placeholder-item">WEB / MOBILE / PWA</li>
						<li class="main__belt-placeholder-item">ANDROID / iOS</li>
					</ul>

					<ul class="main__belt-placeholder-list" style="margin-top: 10px">
						<li class="main__belt-placeholder-item">DIGITAL POS</li>
						<li class="main__belt-placeholder-item">HEAD UP DISPLAY</li>
						<li class="main__belt-placeholder-item">IOT</li>
					</ul>
				</div>
			</div>

			<section class="main__projects container">
				<div class="main__projects-content">

					<h2 class="main__projects-title">product & service design</h2>
					<div class="main__projects--slider">
						<Slider carouselType="product" :slides="productSlides"/>
					</div>

					<h2 class="main__projects-title" style="margin-bottom: 10px">ANIMACJA & ILUSTRACJA</h2>
					<div class="main__projects--slider">
						<Slider carouselType="animation" :slides="animationSlides"/>
					</div>

				</div>
			</section>

			<section class="main__offer section--full-width">
				<div class="main__offer-heading container">
					<h4 class="h4-style">Oferta</h4>
					<h2 class="h2-style h2-sm-style">Sprawdź, co możemy dla Was zrobić</h2>
				</div>

				<div class="main__offer-content container">
					<div class="main__offer-row">
						<div
							v-for="offer in mainOffers[0]"
							class="main__offer-box"
						>
							<h3 class="h3-style" v-html="offer.title"></h3>
							<Button
								buttonStyle="secondary"
								:additionalElement="true"
								buttonText="więcej"
								:buttonLink="offer.link"
								additionalElementStyle="arrowWithoutCircle"
							/>
						</div>
					</div>

					<div class="main__offer-row">
						<div
							v-for="offer in mainOffers[1]"
							class="main__offer-box"
						>
							<h3 class="h3-style" v-html="offer.title"></h3>
							<Button
								buttonStyle="secondary"
								:additionalElement="true"
								buttonText="więcej"
								:buttonLink="offer.link"
								additionalElementStyle="arrowWithoutCircle"
							/>
						</div>
					</div>

					<div class="main__offer-row">
						<div
							v-for="offer in mainOffers[2]"
							:key="offer.title"
							class="main__offer-box"
						>
							<h3 class="h3-style" v-html="offer.title"></h3>
							<Button
								buttonStyle="secondary"
								:additionalElement="true"
								buttonText="więcej"
								:buttonLink="offer.link"
								additionalElementStyle="arrowWithoutCircle"
							/>
						</div>
					</div>

					<Button
						buttonStyle="default"
						buttonText="Porozmawiajmy"
						buttonLink="/kontakt"
						class="v-btn-chat"
					></Button>
				</div>
			</section>

			<section class="main__why container">
				<div class="main__why-heading">
					<h4 class="h4-style">dlaczego my?</h4>
					<h2 class="h2-style h2-sm-style">Z nami masz pewność</h2>
				</div>

				<div class="main__why-content">
					<div
						v-for="box in why"
						:key="box.title"
						class="main__why-content-box"
					>
						<h3 class="h3-style" v-html="box.title"></h3>
						<p class="p-style">{{ box.text }}</p>

						<Button
							v-if="box.button !== '' && box.link"
							buttonStyle="primary"
							:additionalElement="true"
							additionalElementStyle="arrowInCircle"
							:buttonText="box.button"
							:buttonLink="box.link"
						/>

						<Button
							v-if="box.button !== '' && !box.link"
							buttonStyle="primary"
							:additionalElement="true"
							additionalElementStyle="arrowInCircle"
							:buttonText="box.button"
							buttonLink="/"
							@click.prevent="scrollToElement(box.section)"
						/>
					</div>
				</div>
			</section>

			<section class="main__mokup">
				<img src="@/assets/images/home/m1.webp" alt="">
			</section>

			<section class="main__why-worth section--full-width">
				<div class="main__why-worth-heading container">
					<h4 class="h4-style">Dlaczego warto?</h4>
					<h2 class="h2-style h2-sm-style">Gwarancja bezpieczeństwa</h2>
				</div>

					<img class="shield" src="../../assets/images/Shape.png" alt="shield">
				<div class="main__why-worth-content container --dark-content-sm">

					<div
						v-if="this.$store.state.isMobile === false"
						v-for="box in whyWorth"
						:key="box.title"
						class="main__why-worth-box"
					>
						<h3 class="h3-style">{{ box.title }}</h3>
						<p class="p-style">{{ box.text }}</p>
					</div>

					<Slider v-else carouselType="main-worth" :slides="whyWorth"/>
				</div>
			</section>

			<Clients/>

			<section class="main__section-dark main__approach section--full-width">
				<div class="main__approach-heading container">
					<h4 class="h4-style">NASZE PODEJŚCIE</h4>
					<h2 class="h2-style h2-sm-style">Nowy wymiar architektury informacji i user experience</h2>
				</div>
				<div class="main__approach-content container --dark-content-sm">
					<div
						v-if="!this.$store.state.isMobile"
						v-for="box in approach"
						:key="box.title"
						class="main__approach-box"
					>
						<h3 class="h3-style">{{ box.title }}</h3>
						<p class="p-style">{{ box.text }}</p>
					</div>

					<Slider v-else carouselType="main-approach" :slides="approach"/>
				</div>
			</section>

			<FAQComponent currentButton="produkt"/>
		</div>
	</section>
</template>

<script src="./component.js"></script>
<style src="./style.sass" scoped lang="sass"></style>
