<template>
	<Swiper :class="
	{
		'product-carousel' : carouselType === 'product',
		'animation-carousel': carouselType === 'animation',
		'animation-home' : carouselType === 'animationHome',
		'animation-home-mobile' : carouselType === 'animationHomeMobile',
		'animation-subpage' : carouselType === 'subpage',
		'slider-main-approach' : carouselType === 'main-approach',
		'slider-main-worth' : carouselType === 'main-worth',
		'summary-slider' : carouselType === 'summary',
		'improvement-slider' : carouselType === 'improvement'
	}"
	        :slides-per-view="1"
            :space-between="50"
            navigation
            :pagination="{ clickable: true }"
	        :loop="true"
	        :style="style"
	>
		<swiper-slide
			v-if="carouselType === 'product' || carouselType === 'animation'"
			v-for="slide in slides"
			:key="slide.id"
		>
			<div class="carousel__item" v-if="carouselType === 'product'">
				<div class="item__content">
					<div class="item__content-info">
						<h4>{{ slide.company }}</h4>
						<h2>{{ slide.title }}</h2>
						<p>{{ slide.text }}</p>
					</div>
				</div>
				<div class="item__image-box">
					<img :src="slide.src" :alt="slide.id">
				</div>
			</div>

			<div class="carousel__item" v-if="carouselType === 'animation'">
				<div class="item__image-box">
					<img :src="slide.src" :alt="slide.id">
				</div>
				<div class="item__content">
					<div class="item__content-info">
						<h2>{{ slide.title }}</h2>
						<p>{{ slide.text }}</p>
					</div>
				</div>
			</div>
		</swiper-slide>

		<swiper-slide v-else
		       v-for="slide in slides" :key="slide.id"
		>
			<div
				v-if="carouselType === 'animationHome'"
				class="slide__box">
				<div class="item-1 animation-home-item">
					<span>{{ slide.number1 }}</span>
					<p>{{ slide.text1 }}</p>
				</div>
				<div class="item-2 animation-home-item">
					<span>{{ slide.number2 }}</span>
					<p>{{ slide.text2 }}</p>
				</div>
				<div class="item-3 animation-home-item">
					<span>{{ slide.number3 }}</span>
					<p>{{ slide.text3 }}</p>
				</div>
			</div>

			<div class="slide__box" v-if="carouselType === 'animationHomeMobile'">
				<div class="animation-home-item">
					<span>{{ slide.number }}</span>
					<p>{{ slide.text }}</p>
				</div>
			</div>

			<div class="slide__box" v-if="carouselType === 'subpage'">
				<div class="animation-subpage-item">
					<p>{{ slide.text }}</p>
				</div>
			</div>

			<div class="slide__box" v-if="carouselType === 'summary'">
				<div class="summary-slider-item">
					<p>{{ slide }}</p>
				</div>
			</div>

			<div class="slide__box" v-if="carouselType === 'improvement'">
				<div class="summary-slider-item">
					<p>{{ slide.text }}</p>
				</div>
			</div>

			<div class="slide__box" v-if="carouselType === 'main-approach'">
				<div class="animation-home-item">
					<h3 class="h3-style">{{ slide.title }}</h3>
					<p class="p-style">{{ slide.text }}</p>
				</div>
			</div>

			<div class="slide__box" v-if="carouselType === 'main-worth'">
				<div class="animation-home-item">
					<h3 class="h3-style">{{ slide.title }}</h3>
					<p class="p-style">{{ slide.text }}</p>
				</div>
			</div>
		</swiper-slide>
	</Swiper>
</template>

<script>
import {defineComponent} from 'vue'
import SwiperCore, { Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";

import 'swiper/swiper-bundle.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';

SwiperCore.use([Navigation, Pagination, A11y]);

export default defineComponent({
	name: 'Autoplay',
	props: {
		carouselType: {
			type: String
		},
		slides: {
			type: Array
		}
	},
	components: {
		Swiper,
		SwiperSlide
	},
	data() {
		return {
			settings: {
				itemsToShow: 1,
				snapAlign: 'center',
			},
		}
	},
	computed: {
		reverseSlides() {
			return this.slides.reverse()
		},
		style() {
			let dFlexDir
			let elemWidth
			let infoWidth
			let padding
			let headerMargin
			let imageWidth

			// navbar styles
			let navPosition
			let navWidth
			let navHeight
			let navDisplay
			let navTop
			let navLeft

			if (this.carouselType === 'product') {
				dFlexDir = 'row'
				elemWidth = '100%'
				padding = '60px 120px 0 0'
				infoWidth = '100%'
				headerMargin = '46px'
				imageWidth = 'unset'

				navPosition = 'relative'
				navWidth = '241px'
				navHeight = '50px'
				navDisplay = 'flex'
				navTop = '-100px'
				navLeft = '50px'
			}

			if (this.carouselType === 'animation') {
				dFlexDir = 'column'
				elemWidth = '1120px'
				imageWidth = '100%'
				padding = '50px 20px 0 65px'
				infoWidth = '645px'
				headerMargin = '21px'

				navPosition = 'absolute'
				navWidth = '241px'
				navHeight = '50px'
				navDisplay = 'flex'
				navTop = 'calc(100% - 125px)'
				navLeft = 'calc(100% - 300px)'
			}

			return {
				'--carousel-flex-direction': dFlexDir,
				'--carousel-width': elemWidth,
				'--carousel-padding': padding,
				'--carousel-info-width': infoWidth,
				'--carousel-header-margin': headerMargin,
				'--carousel-image-width': imageWidth,

				'--carousel-nav-position': navPosition,
				'--carousel-nav-width': navWidth,
				'--carousel-nav-height': navHeight,
				'--carousel-nav-display': navDisplay,
				'--carousel-nav-top': navTop,
				'--carousel-nav-left': navLeft
			}
		}
	},
	mounted () {
		this.prepareArrowIcons()
	},
	methods: {
		prepareArrowIcons() {
			const prevButtons = document.querySelectorAll('.swiper-button-prev')
			const nextButtons = document.querySelectorAll('.swiper-button-next')

			prevButtons.forEach((button) => {
				button.innerHTML = `<svg class="carousel__icon" viewBox="0 0 24 24" role="img"><title>arrowLeft</title><path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"></path></svg>`
				button.children[0].style.transform = 'rotateZ(180deg)'
			})

			nextButtons.forEach((button) => {
				button.innerHTML = `<svg class="carousel__icon" viewBox="0 0 24 24" role="img"><title>arrowRight</title><path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"></path></svg>`
			})

		}
	}
});
</script>


<style scoped lang="sass" src="./style.sass"/>
<style scoped lang="sass" src="./product-carousel-navbar.sass"/>
<style scoped lang="sass" src="./animation-carousel-navbar.sass"/>
<style scoped lang="sass" src="./other-main-carousel-navbar.sass"/>
<style scoped lang="sass" src="./animation-home-mobile-carousel-navbar.sass"/>
<style scoped lang="sass" src="./animation-home-desktop-carousel-navbar.sass"/>
<style scoped lang="sass" src="./subpage-carousel-navbar.sass"/>
<style scoped lang="sass" src="./summary-slider.sass"/>
<style scoped lang="sass" src="./improvement-carousel.sass"/>
