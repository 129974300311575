<template>
	<div :class="['dropdown', { opened: dropdownIsOpened }]" @click="toggle">
		<div class="dropdown__header">
			<slot name="title"></slot>
			<img src="../../assets/images/chevron_down.svg" :class="['chevron', { rotate: dropdownIsOpened }]" alt="">
		</div>

		<transition name="slide" mode="out-in">
			<div v-show='dropdownIsOpened' class="dropdown__content" :class="[{show: dropdownIsOpened}]">
				<slot name="content"></slot>
			</div>
		</transition>
	</div>
</template>

<script>
export default {
	name: "dropdown",
	props: {
		isOpened: {
			type: Boolean,
			default: false,
		}
	},
	data () {
		return {
			dropdownIsOpened: false
		}
	},
	watch: {
		isOpened () {
			this.close()
		}
	},
	methods: {
		toggle () {
			if (this.dropdownIsOpened) {
				this.close()
			} else {
				this.open()
			}
		},
		open () {
			this.dropdownIsOpened = true
			this.$emit('opened')
		},
		close () {
			this.dropdownIsOpened = false
			this.$emit('closed')
		}
	}
}
</script>

<style lang="sass">
.dropdown
	position: relative
	width: 100%
	padding: 40px 30px 40px 0
	border-bottom: 1px solid #dddddd
	display: flex
	align-items: center
	flex-direction: column
	justify-content: center
	cursor: pointer
	z-index: 20

	p
		font-size: 15px !important

	@include view('sm')
	@include view('md')
		width: 100%

	&__header
		width: 100%
		display: flex
		justify-content: space-between

	&__title
		padding: 0 50px 0 0
		font-size: 17px
		font-weight: $weight--semi-bold

	&__content
		padding: 0 150px 0 0
		transition: all .2s ease
		line-height: 0
		overflow: hidden
		color: transparent

		@include view('sm')
		@include view('md')
			padding: 0

.show
	line-height: 1.5
	color: $color--dark

.chevron
	transition: all .3s ease
	width: 21px

.rotate
	transform: rotateZ(180deg)

.slide-enter, .slide-leave
	opacity: 0

.slide-enter-active
	transition: all 4s ease
</style>
