import routeConstants from '../../router/routePath'

export const productLinks = [
	{
		name: 'Informacje ogólne',
		link: '/'
	},
	{
		name: 'Audyty, badania, warsztaty i konsultacje',
		link: routeConstants.DESIGN_AUDITS.path
	},
	{
		name: 'Research, strategia i innowacje',
		link: routeConstants.DESIGN_RESEARCH.path
	},
	{
		name: 'Poprawa użyteczności i udoskonalanie produktów',
		link: routeConstants.DESIGN_FIX
	},
	{
		name: 'Product & Service Design',
		link: routeConstants.DESIGN_PRODUCT.path
	},
	{
		name: 'Web & Mobile Development',
		link: routeConstants.DESIGN_DEVELOPMENT.path
	},
	{
		name: 'Transformacje cyfrowe',
		link: routeConstants.DESIGN_TRANSFORM.path
	},
	{
		name: 'IOT & Head-Up Display Interfaces',
		link: routeConstants.DESIGN_IOT.path
	},
	{
		name: 'Wsparcie i zarządzanie',
		link: routeConstants.DESIGN_SUPPORT.path
	},
	{
		name: 'Etapy tworzenia produktu cyfrowego',
		link: routeConstants.DESIGN_PROCESS.path
	},
]

export const animationLinks = [
	{
		name: 'Dowiedz się więcej o animacji',
		link: routeConstants.ANIMATION.path
	},
	{
		name: 'Animacja produktowa',
		link: routeConstants.PRODUCT_ANIMATION.path
	},
	{
		name: 'Animacja reklamowa',
		link: routeConstants.ADVERTISEMENT_ANIMATION.path
	},
	{
		name: 'Animacja promocyjna',
		link: routeConstants.PROMOTIONAL_ANIMATION.path
	},
	{
		name: 'Animacja onboardingowa',
		link: routeConstants.ONBOARDING_ANIMATION.path
	},
	{
		name: 'Animacja prezentacyjna',
		link: routeConstants.PRESENTATION_ANIMATION.path
	},
	{
		name: 'Proces produkcji animacji',
		link: routeConstants.ANIMATION_PRODUCTION_PROCESS.path
	},
]

export const animationENLinks = [
	{
		name: 'Animation',
		link: routeConstants.ANIMATION.alias
	},
	{
		name: 'Product & Explainer Videos',
		link: routeConstants.PRODUCT_ANIMATION.alias
	},
	{
		name: 'Animated commercials',
		link: routeConstants.ADVERTISEMENT_ANIMATION.alias
	},
	{
		name: 'Promotional videos',
		link: routeConstants.PROMOTIONAL_ANIMATION.alias
	},
	{
		name: 'Onboarding animations',
		link: routeConstants.ONBOARDING_ANIMATION.alias
	},
	{
		name: 'Animated presentations',
		link: routeConstants.PRESENTATION_ANIMATION.alias
	},
	{
		name: 'Animation Production Process',
		link: routeConstants.ANIMATION_PRODUCTION_PROCESS.alias
	},
]

export const headLinks = [
	// {
	// 	name: 'Blog',
	// 	link: ''
	// },
	{
		name: 'Nasza misja',
		link: routeConstants.ABOUT.path
	},
	{
		name: 'kariera',
		link: routeConstants.CARRIER.path
	},
	{
		name: 'faq',
		link: routeConstants.PRODUCT_FAQ.path
	},
	{
		name: 'kontakt',
		link: routeConstants.CONTACT.path
	},
]

export const headENLinks = [
	{
		name: 'Our mission',
		link: routeConstants.ABOUT.alias
	},
	{
		name: 'faq',
		link: routeConstants.ANIMATION_FAQ.alias
	},
	{
		name: 'Contact',
		link: routeConstants.CONTACT.alias
	},
]
