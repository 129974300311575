import routePath from "@/router/routePath";

const en = [
	{
		id: '/en',
		awards: [
			{
				image: require('@/assets/images/animation/Animacja_home/be_tag.png'),
				alt: 'behance',
			},
			{
				image: require('@/assets/images/animation/Animacja_home/il_tag.png'),
				alt: 'illustrator',
				number: '2',
			},
			{
				image: require('@/assets/images/animation/Animacja_home/mo_tag.png'),
				alt: 'image',
				number: '5'
			},
			{
				image: require('@/assets/images/animation/Animacja_home/gr_tag.png'),
				alt: 'image',
			},
			{
				image: require('@/assets/images/animation/Animacja_home/after_tag.png'),
				alt: 'after effect',
				number: '2'
			},
			{
				image: require('@/assets/images/animation/Animacja_home/ps_tag.png'),
				alt: 'photoshop',
			}
		],

		whyAnimation: {
			image: require('@/assets/images/animation/Animacja_home/02_ilustracja.webp'),
			title: 'Why should businesses use animation?',
			text: 'Animations are a great sales and marketing tool at every stage of product development. Thanks to them, you can sell the idea to investors, explain how it works to your first clients, show the benefits to the next ones, and even build the organisation\'s culture by creating an onboarding animation. That’s why, regardless of what stage you’re at and what you want to do, animations will support you in your actions, helping clients and employees build a relationship with your brand.'
		},

		animationList: [
			{
				title: 'Product and explainer videos',
				text: 'Explain how your service works and what benefits it offers your clients.',
				link: '/en/explainer-videos'
			},
			{
				title: 'Animated commercials',
				text: 'Advertise your brand or product through top-class animations.',
				link: '/en/animated-commercials'
			},
			{
				title: 'Promotional videos',
				text: 'Raise awareness of your brand and mark your position on the market.',
				link: '/en/promotional-videos'
			},
			{
				title: 'Onboarding videos',
				text: 'Introduce your employees to internal processes and the culture of your organisation.',
				link: '/en/onboarding-animations'
			},
			{
				title: 'Animated presentations',
				text: 'Explain to your contractors why cooperation with your company is profitable.',
				link: '/en/animated-presentations'
			}
		],

		showreel: {
			title: 'Explore our skills',
			link: ''
		},

		whyUs: [
			{
				title: 'The transparent, stress-free production process',
				text: 'Thanks to our developed stress-free production process, we create productions professionally and effectively, painlessly guiding you through the steps. We know what we should ask you about, what we should talk about, and in what situations we need your decision-making. Furthermore, we keep you informed about the stage of creation, and if necessary, we are ready for consultations at any time. Thanks to this, we not only finish the process with an animation that meets the established business assumptions and constitutes a top-notch marketing tool but also your satisfaction and peace of mind.'
			},
			{
				title: 'Complex projects and challenging industries',
				text: 'We specialise in transforming difficult things into simple ones because animation allows us to show and simplify even the most complex ideas. We have many years of experience in the implementation of projects on complex topics for the average customers, such as, for example, modern technologies or financial instruments.'
			},
			{
				title: 'The highest visual quality',
				text: 'We create exquisite animations of a wide stylistic variety. Furthermore, we pay attention to quality at every stage of the project, so your video stands out from others and does not get lost in the multitude of other videos.'
			},
			{
				title: 'Experience in working for the most significant brands',
				text: 'We have created projects for the largest and most recognisable brands: Polish and foreign from the Fortune 500 list.'
			},
			{
				title: 'Guarantee of safety',
				text: 'You do not need to have previous experience in commissioning animation projects. We know how to guide you through the process, optimise your costs, and what communication and visual solutions to use to make the animation effective. On the technical side, we take care of the appropriate dynamics of video and its visual aspects. In terms of business, we fulfil marketing assumptions.'
			},
			{
				title: 'Engaging storytelling',
				text: 'Animation is becoming more common, meaning dull messages get lost in space in the blink of an eye. Thanks to a professionally built narrative and attractive visuals, we engage your customers, arouse their emotions, thus increasing the conversion rate.'
			},
		],

		whatUGet: [
			{
				text: '81% of companies actively use video as their marketing tool'
			},
			{
				text: 'In the digital stream of information, customers are attracted by the unique. The development of technology and the emergence of advertising animations as daily support for marketing and sales activities has significantly facilitated this. Animation makes it easier to show and explain what is real and what is abstract and intangible. The only limit is your imagination. '
			},
			{
				text: 'In our productions, we follow accurate data and the highest standards of creativity. We implement appealing storytelling that engages your customers, and we take care of consistent and smooth animation at the same time. Thanks to this, we create powerful tools to support your daily duties.'
			}
		],
		images: [
			{
				image: require('@/assets/images/animation/Animacja_home/1.webp'),
				alt: 'ilustration'
			},
			{
				image: require('@/assets/images/animation/Animacja_home/2.webp'),
				alt: 'ilustration'
			},
			{
				image: require('@/assets/images/animation/Animacja_home/3.webp'),
				alt: 'ilustration'
			},
			{
				image: require('@/assets/images/animation/Animacja_home/4.webp'),
				alt: 'ilustration'
			},
			{
				image: require('@/assets/images/animation/Animacja_home/5.webp'),
				alt: 'ilustration'
			},
			{
				image: require('@/assets/images/animation/Animacja_home/6.webp'),
				alt: 'ilustration'
			},
			{
				image: require('@/assets/images/animation/Animacja_home/7.webp'),
				alt: 'ilustration'
			},
			{
				image: require('@/assets/images/animation/Animacja_home/8.png'),
				alt: 'ilustration'
			},
			{
				image: require('@/assets/images/animation/Animacja_home/9.webp'),
				alt: 'ilustration'
			},
		],

		possibilities: [
			{
				title: 'Website',
				text: 'Implement the animation on your website as an element explaining how your product/service or its specific part works.',
				subtext: 'A video hosted on a website can increase conversion by <strong style="color: white">86%</strong>',
				info: 'data: Wordstream'
			},
			{
				title: 'Social media',
				text: 'Publish the animation on social media to encourage customers to learn more about your service or product.',
				subtext: 'By posting videos on social media <strong style="color: white">93%</strong> of marketers turned their leads into sales.',
				info: 'data: Animoto'
			},
			{
				title: 'Mailing',
				text: 'Make your customers interested in the animation included in your mailing.',
				subtext: 'Video increases CTR by <strong style="color: white">65%</strong> and reduces the number of unsubscribes by <strong>26%.</strong>',
				info: 'data: Campaign Monitor'
			},
			{
				title: 'Advertising campaign',
				text: 'Attract new clients with an appealing visual form and intrigue them enough to want to learn more. Then, show them why your product is worth their money!',
				subtext: '<strong style="color: white">90%</strong> of customers say the video helps them make purchase decisions and that they are more likely to buy the product after watching the video.',
				info: 'data: Alexa'
			},
			{
				title: 'Business presentation',
				text: 'Intrigue your contractors and colleagues with something more effective than a static data presentation.',
				subtext: 'People are 22 times more likely to remember a fact when it has been wrapped in a story.',
				info: 'data: Visme'
			},
			{
				title: 'Individual Video Display',
				text: 'Do you wish to attract customers by animation on large displays at the service point, or maybe you need an advertising brand presentation in the showroom? The flexibility of animation allows you to achieve any goal on the display of your choice, regardless of the size of your LCD screen.',
				subtext: '<strong style="color: white">Let us know what you need, <br>and we will help you achieve it.</strong>',
				info: ''
			},
		],

		process: {
			text: 'Thanks to the appropriate production process, we can create animations that achieve business goals and maintain the highest quality.',
			image: require('@/assets/images/animation/Animacja_home/process.png'),
			link: '/en/animation-process'
		},

		worth: [
			{
				title: 'Get others interested',
				text: 'On average, people spend <strong style="color: white">2,6</strong> times more time on websites with video than on websites without them.',
				subtext: 'Marketers who include video in their campaigns experience <strong style="color: white">34%</strong> higher conversion rates than campaigns without video.'
			},
			{
				title: 'Showcase your brand',
				text: '<strong style="color: white">72%</strong> of customers would preferably watch a video to learn about a product or service.',
				subtext: '<strong style="color: white">94%</strong> of marketers believe that video has increased customers\' understanding of their product or service.'
			},
			{
				title: 'Sell',
				text: '<strong style="color: white">51%</strong> of marketing specialists worldwide identify video as the type of content with the best ROI.',
				subtext: '<strong style="color: white">78%</strong> of marketers say the video directly helped them increase sales.'
			},
		]
	},
	{
		id: routePath.PRODUCT_ANIMATION.alias,
		list: [
			{
				text: 'Before they purchase, many customers want to make sure that they understand your product or service. They want to see if that will be useful to them. Product animation will allow you to show your product and explain more about how it works and how to use it - in a simple, attractive, and accessible way.<br><br> This type of animation is one of the most powerful marketing and sales tools. When used actively and wisely, it will become an invaluable element of direct selling.',
				image: require('@/assets/images/animation/Animacja_produktowa/02_ilu.webp')
			},
			{
				text: 'Now that customers have entered your website, make sure they get to know your product\'s most significant advantages and benefits quickly, without having to browse the entire site, especially when they don\'t have the time to do so.',
				image: require('@/assets/images/animation/Animacja_produktowa/03_ilu.webp')
			},
			{
				text: 'Nothing will make a better impression than a well-thought-out animation that explains all the most essential elements of your product/service and its profits. It\'s your chance to reach out to the customer and make sure they remember your product and are likely to return to it.',
				image: require('@/assets/images/animation/Animacja_produktowa/04_ilu.webp')
			},
		],
		recommended: {
			title: 'We recommend the product & explainer animation when:',
			list: [
				{
					text: 'There\'s a need to explain how the product or service works'
				},
				{
					text: 'You want to encourage your customers to further explore your product/service'
				},
				{
					text: 'You want to reach the customers who are potentially interested in your product and are looking for more information about it'
				},
				{
					text: 'You want to highlight the benefits of your product/service'
				},
				{
					text: 'You want to place an animation on your website so that it can generate ROI and sustain traffic'
				},
			]
		}
	},
	{
		id: routePath.ADVERTISEMENT_ANIMATION.alias,
		list: [
			{
				text: 'In today’s world of information overload and communication chaos, it’s hard to stand out with something that looks the same as everything else around you. To look credible and convey to your customer the values of your brand and your product, you need an adequate tool. Animated commercials will allow you to emphasise the brand\'s presence on the market and distinguish it from competitors.',
				image: require('@/assets/images/animation/Animacja_reklamowa/02_ilu.webp')
			},
			{
				text: 'The proper form of communication is crucial for us. In our animated commercials, we use engaging storytelling, which is why we not only get customers interested in your product but also help to establish an emotional bond with your brand. Thanks to this, the customers absorb your message much better than with static information.  <br><br>Our mission is to make animated content highlighting the message without being separated from the narrative content. Thanks to this, what we create leads to better engagement and remains longer in the customer\'s memory.',
				image: require('@/assets/images/animation/Animacja_reklamowa/03_ilu.webp')
			},
			{
				text: 'Appearance and presence are undeniably some of the most crucial aspects. Animated commercials are a chance for a phenomenal first impression. In our productions, we treat your story with the greatest care and attention to detail. Together with you, we select the most appropriate visual language that will complement the other elements of the brand. As a result, our illustrations instantly catch the customers\' eyes in the stream of other content.',
				image: require('@/assets/images/animation/Animacja_reklamowa/04_ilu.webp')
			},
		],
		recommended: {
			title: 'We recommend an animat`ed commercial when:',
			list: [
				{
					text: 'You want to mark your presence on the market firmly'
				},
				{
					text: 'You must stand out with your product/brand among the competitors'
				},
				{
					text: 'You want to reach new audiences, especially by indirect marketing'
				},
			]
		}
	},
	{
		id: routePath.PROMOTIONAL_ANIMATION.alias,
		list: [
			{
				text: 'You know your customers, but do they know your brand and its products? <br><br>In the chaos of various multilevel marketing activities and messages, it sometimes happens that it\'s unclear what the organisation offers among the full range of solutions. So your customers buy only single services, without knowing the whole spectrum of your products.',
				image: require('@/assets/images/animation/Animacja_promocyjna/02_ilu.webp'),
			},
			{
				text: 'Although your company already has many regular customers, you still see opportunities to expand your cooperation fields and sell more services and products. Moreover, if your current customers are satisfied with the services, they will likely be interested in other products you offer - if they only get to know them.  <br><br>We create promotional animations as all-purpose materials that you\'ll be able to use anywhere as a supplement to your existing marketing activities. It\'s an opportunity for you to show off a wide range of your products and services without focusing on one specific product.',
				image: require('@/assets/images/animation/Animacja_promocyjna/03_ilu.webp'),
			},
			{
				text: 'Thanks to fantastic storytelling, we establish an emotional bond with your customers by showing them the product and the human face of your brand. In addition, we present the values conveyed by your organisation and emphasise its mission.',
				image: require('@/assets/images/animation/Animacja_promocyjna/04_ilu.webp'),
			},
		],
		recommended: {
			title: 'We recommend the promotional video when:',
			list: [
				{
					text: 'You want to reach not only new but also existing customers who may be not aware of the wide range of your brand\'s products'
				},
				{
					text: 'The targets of your animation are extensive, and you need one as an introduction to further marketing'
				},
				{
					text: 'You don\'t need a specific CTA, but you’d prefer to make your audience aware of the brand\'s products/services instead'
				},
				{
					text: 'You need an animation for a gala or a business meeting with contractors'
				},
				{
					text: 'You want to remind your audience of the existence of your company and mark your position on the market'
				},
			]
		}
	},
	{
		id: routePath.ONBOARDING_ANIMATION.alias,
		list: [
			{
				text: 'Onboarding a new employee involves familiarising them with the company\'s structure, carrying them through the applicable procedures and standards, implementing them in the rules, and handing over responsibilities and amenities. <br><br>With the help of onboarding animation, you can do it in an enjoyable, non-standard way, ensuring easy access to this content at any moment without engaging the rest of the team. It\'s also an opportunity to support the employer branding strategy in your organisation.',
				image: require('@/assets/images/animation/Animacja_onboardingowa/02_ilu.webp'),
			},
			{
				text: 'An onboarding animation will help you present the organisation\'s strategy, mission, and business goals in an attractive way and show the employment structure or stress the procedures. It is also an excellent opportunity to explain how promotions and benefits work within the organisation.',
				image: require('@/assets/images/animation/Animacja_onboardingowa/03_ilu.webp'),
			},
		],
		recommended: {
			title: 'We recommend onboarding animation when:',
			list: [
				{
					text: 'You need to introduce your employees to the internal structures of the company'
				},
				{
					text: 'You want to make your employees aware of new features or changes'
				},
				{
					text: 'You want to take care of employer branding in your company'
				},
				{
					text: 'You want to increase the company\'s value in the eyes of current employees'
				}
			]
		}
	},
	{
		id: routePath.PRESENTATION_ANIMATION.alias,
		list: [
			{
				text: 'Consider an animated presentation if your organisation is preparing for a critical presentation with a major investor or strategic contractor. Whether you want a whiteboard or a full-colour display with plenty of data, the animation will display everything you want to show and more.',
				image: require('@/assets/images/animation/Animacja_prezentacyjna/02_ilu.webp'),
			},
			{
				text: 'Replace the chart board and Powerpoint presentation with an animation that will keep your audience\'s attention and help you present everything you want both concisely and attractively.',
				image: require('@/assets/images/animation/Animacja_prezentacyjna/03_ilu.webp'),
			}
		],
		recommended: {
			title: 'We recommend animated presentation when:',
			list: [
				{
					text: 'The animation will be shown directly during a presentation and won\'t be an integral part of the campaign or website'
				},
				{
					text: 'It is more necessary for you to show specific data than to establish an emotional connection with the customers through storytelling'
				},
				{
					text: 'Your main goal is to present the idea to the investor, not to sell the product to clients'
				}
			]
		}
	}
]

export {en}
