<template>
	<router-link
		:buttonStyle="buttonStyle"
		:to="buttonLink"
		:style="style"
		class="v-btn"
	>
		<p style="display: inline-block;" v-html="buttonText"></p>

		<span v-if="additionalElement && additionalElementStyle === 'arrowInCircle'">
			<img v-if="!whiteArrow" src="@/assets/images/icon_arrow.svg" alt="arrow">
			<img v-else src="@/assets/images/arrow_right_white_1.svg" alt="arrow">
		</span>

		<span v-if="additionalElement && additionalElementStyle === 'arrowWithoutCircle'">
			<img v-if="!whiteArrow" src="@/assets/images/icon_arrow.svg" alt="">
			<img v-else src="@/assets/images/arrow_right-white.svg" alt="">
		</span>
	</router-link>
</template>

<script src="./component.js"></script>
<style src="./style.sass" scoped lang="sass"></style>
