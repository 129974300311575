<template>
	<form method="post" class="form">
		<span class="dsadsadever" style="display: none">
			<label for="accaa">Jeżeli jesteś człowiekiem, nie wypełniaj tego pola</label>
			<input type="text" id="accaa" name="hany" v-model="formData.honey">
		</span>

		<div class="form__container">
			<div class="form__header">
				<div class="form__text-box">
					<h4 class="h4-style">porozmawiajmy o waszych potrzebach</h4>
					<h2 class="h2-style">Jak możemy pomóc?</h2>
				</div>

				<div class="form__contact">
					<p>(+48) 000 000 000</p>
					<p>hello@hylite.pl</p>
				</div>
			</div>

			<div class="form__content">
				<div
					v-if="currentStep === 1"
					class="form__box step-1"
				>
					<div class="form__box-input">
						<input
							type="text"
							placeholder="Imię i nazwisko"
							required
							id="fullname"
							@keyup="charCounter"
							@keydown.space.enter="preventLeading"
							v-model="formData.personName"
						>
						<input
							type="text"
							placeholder="Stanowisko"
							required
							id="position"
							@keyup="charCounter"
							@keydown.space.enter="preventLeading"
							v-model="formData.jobPosition"
						>
						<input
							type="text"
							placeholder="Nazwa firmy"
							required
							id="company"
							@keyup="charCounter"
							@keydown.space.enter="preventLeading"
							v-model="formData.companyName"
						>
						<input
							type="email"
							placeholder="Email"
							required
							id="email"
							@keyup="charCounter"
							@keydown.space.enter="preventLeading"
							v-model="formData.email"
						>
					</div>

					<div class="form__box-check-list">
						<h3 class="h3-style">Na jakim etapie jest projekt?</h3>

						<ul class="form__box-list">
							<li
								v-for="(item, i) in checkboxData"
								:key="i"
								class="form__box-list-item"
							>
								<label class="checkbox" :for="item.for">
									{{item.text}}

									<input
										@click="handleCheckBox($event, i)"
										type="checkbox"
										class="checkbox-step-1"
										:id="item.for"
										:checked="item.checked"
									>
									<span class="checkmark"></span>
								</label>
							</li>
						</ul>
					</div>
				</div>

				<div
					v-if="currentStep === 2"
					class="form__box step-2"
				>
					<div class="step-2--header">
						<p>
							Twój wybór:

							<strong>Przykładowy tekst z jednej z opcji</strong>
						</p>
					</div>

					<textarea
						name="step-2-area"
						id="area"
						placeholder="Napisz coś o projekcie"
						required
						@keyup="charCounter"
						@keydown.space.enter="preventLeading"
						v-model="formData.message"
					/>
				</div>

				<div v-if="currentStep === 3"
				     class="form__box step-3"
				>
					<div class="step-3--header">
						<p>
							Podaj nam swój numer telefonu, abyśmy mogli do Ciebie oddzwonić tak szybko, jak to tylko
							możliwe.
						</p>

						<div class="step-3--number-box">
							<h3>+48</h3>
							<input
								type="tel"
								placeholder="000000000"
								id="phone"
								@keyup="charCounter"
								@keydown.space.enter="preventLeading"
								v-model="formData.phoneNumber"
							>
						</div>
					</div>

					<div class="disclaimer">

						<label class="checkbox" for="disclaimer">
							<input type="checkbox" id="disclaimer">
							<span class="checkmark"></span>
						</label>

						<p class="disclaimer__text">
							Administratorem Twoich danych osobowych jest HYLITE, do którego kierujesz zapytanie, ul.
							Lindleya 16, Warszawa (02-013). Dane osobowe przetwarzane będą w celu udzielenia odpowiedzi
							na
							zapytanie ofertowe. Pełna informacja o przetwarzaniu Twoich danych osobowych znajduje się w
							naszej Polityce prywatności.
						</p>
					</div>
				</div>

				<div class="form__bottom">
					<p class="info" v-if="currentStep !== 4">
						Jeśli istnieje potrzeba podpisania umowy NDA, <br>
						napisz do nas na adres <strong>hello@hylite.pl</strong>
					</p>

					<h3 v-else class="step-4--claim">
						W międzyczasie jeżeli potrzebujesz podpisania przez nas NDA albo jeżeli <br>jest coś, o czym
						zapomniałeś/aś wspomnieć, napisz do nas na:

						<br><br><strong>hello@hylite.pl</strong>
					</h3>

					<Button
						v-if="currentStep === 1"
						buttonStyle="default"
						buttonText="Dalej"
						buttonLink=""
						@click="handleStep; validFirstStepForm"
						style="padding: 15px 57px;"
					/>

					<Button
						v-if="currentStep === 2"
						buttonStyle="default"
						buttonText="Dalej"
						buttonLink=""
						@click="handleStep"
						style="padding: 15px 57px;"
					/>

					<Button
						v-if="currentStep === 3"
						buttonStyle="default"
						buttonText="Wyślij"
						buttonLink=""
						@click="handleStep"
						style="padding: 15px 57px;"
					/>

					<Button
						v-if="currentStep === 4 && $route.path !== '/'"
						buttonStyle="primary"
						buttonText="Powrót do strony głównej"
						:additionalElement="true"
						additionalElementStyle="arrowInCircle"
						:whiteArrow="true"
						color="white"
						buttonLink="/"
						style="padding: 15px 57px; align-self: flex-end;"
					/>
				</div>
			</div>

			<div
				v-if="currentStep === 4"
				class="form__box step-4"
			>
				<div class="step-4--content">
					<h2>
						Dziękujemy, zapoznamy się z Twoimi odpowiedziami <br>
						i już wkrótce się z Tobą skontaktujemy!
					</h2>
				</div>
			</div>
		</div>
	</form>
</template>

<script src="./component.js"></script>

<style scoped lang='sass'>
.checkbox
	position: relative
	cursor: pointer
	font-size: 15px
	font-weight: $weight--light
	color: $color--gray
	user-select: none
	padding-left: 30px

	input:checked ~ .checkmark
		background: $color--accent
		border: 0

	input:checked ~ .checkmark:after
		display: block

	input
		position: absolute
		opacity: 0
		width: 0
		height: 0

	.checkmark
		position: absolute
		top: 0
		left: 0
		display: block
		height: 19px
		width: 19px
		border: 1px solid $color--gray
		border-radius: 4px

		&::after
			position: absolute
			content: ''
			left: 7px
			top: 1px
			display: none
			width: 7px
			height: 14px
			border: solid $color--dark
			border-width: 0 2px 2px 0
			transform: rotate(45deg)

.form
	position: relative
	width: 100vw
	height: 720px
	background: $color--dark
	color: $color--light
	padding: 116px 0 0 0

	&::after
		position: absolute
		content: ''
		height: 30px
		width: 216px
		bottom: -15px
		left: 425px
		background: $color--accent
		z-index: 30

	&__container
		width: 1132px
		padding: 0 50px 80px 50px
		margin: auto

	&__header
		width: 100%
		height: 100px
		display: flex
		justify-content: space-between
		align-items: center
		padding-right: 160px

		h2
			font-size: 43px
			margin-bottom: 0

	&__contact
		p
			font-size: 28px
			font-weight: $weight--semi-bold
			padding: 11px 0

	&__content
		display: flex
		flex-direction: column

	&__box
		display: flex

		&-input
			display: flex
			flex-direction: column
			margin-right: 263px

			input
				border: 1px solid white
				width: 380px
				padding: 11px 10px
				margin: 5px 0

		&-list-item
			margin-top: 15px
			height: 22px

		&-check-list
			h3
				font-size: 20px
				color: $color--gray
				margin-bottom: 32px

	&__bottom
		position: absolute
		bottom: 84px
		width: 854px
		display: flex
		justify-content: space-between

		.info
			font-size: 12px
			font-weight: $weight--light
			color: $color--gray
			line-height: 19px

.step-1
	margin-top: 95px

.step-2
	margin-top: 72px
	display: flex
	flex-direction: column

	&--header
		padding: 0 0 0 20px
		margin-bottom: 16px

		p
			font-size: 15px
			font-weight: $weight--light
			color: $color--gray-primary

			strong
				padding-left: 20px
				color: $color--light

	textarea
		width: 878px
		min-height: 242px
		resize: none
		background: transparent
		border: 1px solid $color--gray
		outline: none
		padding: 15px
		font-size: 15px
		color: $color--gray
		font-weight: $weight--light

.step-3
	margin-top: 80px
	height: 254px
	display: flex
	justify-content: space-between
	padding-right: 80px

	&--header
		width: 440px

		p
			margin-bottom: 20px
			font-size: 15px
			font-weight: $weight--light-plus
			color: $color--gray
			line-height: 22px

	&--number-box
		display: flex
		align-items: center

		h3, input
			font-size: 30px
			font-weight: $weight--light
			color: $color--gray
			line-height: 22px

		input
			margin-left: 30px
			width: 270px
			padding: 15px
			border: 1px solid $color--gray

.disclaimer
	display: flex
	width: 360px
	align-self: flex-end

	p
		font-size: 11px
		font-weight: $weight--light
		line-height: 17px
		color: $color--gray

.step-4
	margin-top: 134px

	h2
		font-size: 27px
		font-weight: $weight--light
		color: $color--gray
		margin-bottom: 73px
		line-height: 34px

	&--claim
		font-size: 15px
		font-weight: $weight--light
		color: $color--gray
		line-height: 22px
</style>
