<template>
	<div v-if="this.$store.state.isMobile === false" class="bar-icon--desktop" @click="handleNavbar()">
		<div class="bar--div"></div>
		<div class="bar--div"></div>
		<div class="bar--div"></div>
	</div>

	<div v-else class="bar-icon bar-icon--mobile" @click="handleMobileNavbar()">
		<div class="bar--div"></div>
		<div class="bar--div"></div>
		<div class="bar--div"></div>
	</div>
</template>

<script>
export default {
	name: "barIcon index",
	methods: {
		handleNavbar () {
			this.$emit('navbar')
		},
		handleMobileNavbar() {
			this.$emit('mobile-navbar')
		}
	}
}
</script>

<style lang="sass" scoped>
.bar-icon--desktop
	position: fixed
	top: calc(50% - 8.5px)
	left: 50px
	width: 28px
	height: 17px
	display: flex
	flex-direction: column
	justify-content: space-between
	cursor: pointer
	z-index: 995

	@include view('sm')
		display: none

	@include view('md')
		left: calc(50px - (28px / 1.5))

	div
		&:nth-child(1), &:nth-child(2), &:nth-child(3)
			width: 100%
			height: 3px
			background: #aeaeae

		&:nth-child(2)
			width: 22px !important

.bar-icon--mobile
	position: relative
	width: 28px
	height: 17px
	display: flex
	flex-direction: column
	justify-content: space-between
	cursor: pointer
	z-index: 995

	div
		&:nth-child(1), &:nth-child(2), &:nth-child(3)
			width: 100%
			height: 3px
			background: #aeaeae

		&:nth-child(2)
			width: 22px !important
</style>
