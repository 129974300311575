// data
import {animationSlides, productSlides} from '@/data/slider.js'
import {approach, images, logs, mainOffers, why, whyWorth} from '@/data/data'

// components
import FAQComponent from '@/components/FAQComponent/index'
import Form from '@/components/Form/index'

export default {
	name: "main index",
	components: {Form, FAQComponent},
	data: () => ({
		productSlides,
		animationSlides,
		mainOffers,
		why,
		whyWorth,
		logs,
		images,
		approach,
	}),
	methods: {
		scrollToElement (elem) {
			const e = document.querySelector(elem)
			e.scrollIntoView({
				behavior: "smooth",
				block: "start"
			})
		}
	}
}
