import routeConstants from "@/router/routePath";

const footerData = [
	[
		{
			position: 'Managing Director',
			title: 'PRODUCT & SERVICE <br>DESIGN',
			text: 'Piotr Cegielski',
			email: 'piotr.cegielski@hylite.pl'
		},
		{
			position: 'Managing Director',
			title: 'ANIMACJA & ILUSTRACJA',
			text: 'Ewa Geruzel',
			email: 'ewa.geruzel@hylite.pl'
		},
		{
			title: 'KONTAKT',
			email: 'hello@hylite.pl',
			phone: '(+48) 792 586 279'
		},
		{
			title: 'REKRUTACJA',
			email: 'jobs@hylite.pl'
		},
		{
			title: 'HYLITE',
			address: 'Williama Heerleina Lindleya 16',
			post: '02-013 Warszawa',
			nip: 'NIP: 558 18 42 222',
			regon: 'REGON: 369 322 350'
		}
	],
	{
		title: 'USŁUGI',
		list: [
			[
				{
					title: 'Audyty, badania, warsztaty i konsultacje',
					link: routeConstants.DESIGN_AUDITS.path
				},
				{
					title: 'Research, strategia i innowacje',
					link: routeConstants.DESIGN_RESEARCH.path
				},
				{
					title: 'Poprawa użyteczności i udoskonalanie produktów',
					link: routeConstants.DESIGN_FIX.path
				},

			],
			[

				{
					title: 'Product & Service Design',
					link: routeConstants.DESIGN_PRODUCT.path
				},
				{
					title: 'Web & Mobile Development',
					link: routeConstants.DESIGN_DEVELOPMENT.path
				},
				{
					title: 'Transformacje cyfrowe',
					link: routeConstants.DESIGN_TRANSFORM.path
				},

			],
			[
				{
					title: 'IOT & Head-Up Display Interfaces',
					link: routeConstants.DESIGN_IOT.path
				},
				{
					title: 'Wsparcie i zarządzanie',
					link: routeConstants.DESIGN_SUPPORT.path
				},
				{
					title: 'Video marketing i animacje reklamowe',
					link: routeConstants.ANIMATION.path
				},
			]
		]
	},
	{
		title: 'BRANŻE',
		list: [
			{
				name1: 'Fintech',
				name2: 'Financial services'
			},
			{
				name1: 'Healthcare',
				name2: 'Professional services'
			},
			{
				name1: 'Biotech',
				name2: 'Medycyna'
			},
			{
				name1: 'Farmaceutyka',
				name2: 'Nowe technologie'
			},
			{
				name1: 'Informatyka',
				name2: 'Energetyka'
			},
			{
				name1: 'Kosmetyka',
				name2: 'Ekologia'
			},
			{
				name1: 'FMCG',
				name2: 'Handel i dystrybucja'
			},
			{
				name1: 'Motoryzacja',
				name2: 'Start upy'
			},
		]
	}
]

export {footerData}
