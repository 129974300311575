import routePath from "../router/routePath";

const animation = [
	{
		id: '/animacja',
		awards: [
			{
				image: require('@/assets/images/animation/Animacja_home/be_tag.png'),
				alt: 'behance',
			},
			{
				image: require('@/assets/images/animation/Animacja_home/il_tag.png'),
				alt: 'illustrator',
				number: '2',
			},
			{
				image: require('@/assets/images/animation/Animacja_home/mo_tag.png'),
				alt: 'image',
				number: '5'
			},
			{
				image: require('@/assets/images/animation/Animacja_home/gr_tag.png'),
				alt: 'image',
			},
			{
				image: require('@/assets/images/animation/Animacja_home/after_tag.png'),
				alt: 'after effect',
				number: '2'
			},
			{
				image: require('@/assets/images/animation/Animacja_home/ps_tag.png'),
				alt: 'photoshop',
			}
		],

		whyAnimation: {
			image: require('@/assets/images/animation/Animacja_home/02_ilustracja.webp'),
			title: 'Dlaczego animacja?',
			text: 'Animacja jest doskonałym narzędziem sprzedażowo-marketingowym na każdym etapie rozwoju produktu. Dzięki niej łatwiej jest przekazać ideę inwestorom, wyjaśnić sposób działania pierwszym klientom, pokazać korzyści następnym, a nawet zbudować kulturę organizacji dzięki stworzeniu animacji onboardingowej. Niezależnie od tego, na jakim etapie jesteście i co chcecie zrobić, animacja skutecznie wesprze Wasze działania, pomagając zarówno klientom, jak i pracownikom zbudować relację z marką.'
		},

		animationList: [
			{
				title: 'Animacja produktowa',
				text: 'Wyjaśni, jak działa usługa i jakie niesie za sobą korzyści dla klienta.',
				link: '/animacja-produktowa'
			},
			{
				title: 'Animacja reklamowa',
				text: 'Skutecznie zareklamuje markę bądź produkt.',
				link: '/animacja-reklamowa'
			},
			{
				title: 'Animacja promocyjna',
				text: 'Atrakcyjna forma podkreśli wartość  marki i wyraźnie zaznaczy zajmowaną pozycję na rynku.',
				link: '/animacja-promocyjna'
			},
			{
				title: 'Animacja onboardingowa',
				text: 'W łatwy oraz ciekawy sposób pozwoli wdrożyć pracowników w procesy wewnątrzfirmowe i kulturę obowiązującą w  organizacji.',
				link: '/animacja-onboardingowa'
			},
			{
				title: 'Animacja prezentacyjna',
				text: 'Ze stylem przekaże kontrahentom, dlaczego współpraca jest korzystna i co dzięki niej osiągną.',
				link: '/animacja-prezentacyjna'
			}
		],

		showreel: {
			title: 'Sprawdź nasze możliwości',
			link: ''
		},

		whyUs: [
			{
				title: 'Jasny, bezstresowy <br>proces realizacji',
				text: 'Dzięki naszemu opracowanemu procesowi bezstresowej realizacji tworzymy produkcje w sposób profesjonalny i efektywny, bezboleśnie przeprowadzając Was przez poszczególne kroki. Wiemy, o co powinniśmy Was zapytać, o czym powinniśmy porozmawiać i w jakich sytuacjach potrzebujemy Waszej decyzyjności. Na bieżąco informujemy Was o tym, na jakim etapie realizacji jesteśmy, a w razie potrzeby w każdym momencie jesteśmy gotowi na konsultacje. Dzięki temu nie tylko kończymy proces animacją spełniającą ustalone założenia biznesowe i stanowiącą najwyższej klasy narzędzie marketingowe, ale również Waszym zadowoleniem i spokojem.'
			},
			{
				title: 'Złożone, skomplikowane projekty i trudne branże',
				text: 'Specjalizujemy się w przekładaniu skomplikowanych tematów na proste i przystępne. Elastyczność animacji daje nam pełen wachlarz możliwości upraszczania nawet najbardziej złożonych idei czy procesów. Mamy wieloletnie doświadczenie w realizacji projektów o trudnych tematykach takich jak nowe technologie czy instrumenty finansowe.'
			},
			{
				title: 'Najwyższa jakość <br>wizualna',
				text: 'Tworzymy animacje najwyższej klasy o różnym stopniu zaawansowania. Przykładamy wagę do jakości na każdym etapie realizacji projektu, tak, aby Wasza animacja wyróżniała się na tle innych i nie ginęła w natłoku innych komunikatów video.'
			},
			{
				title: 'Doświadczenie w pracy dla największych marek',
				text: 'Realizowaliśmy projekty dla największych i najbardziej rozpoznawalnych marek: nie tylko polskich, ale również zagranicznych z listy Fortune 500.'
			},
			{
				title: 'Gwarancja <br>bezpieczeństwa',
				text: 'Nie musicie posiadać wcześniejszego doświadczenia w realizacji projektów animacyjnych. Wiemy, jak przeprowadzić Was przez proces, jak zoptymalizować Wasze koszty i jakie rozwiązania komunikacyjne i wizualne zastosować, aby były one skuteczne. Technicznie dbamy o odpowiednią dynamikę animacji i aspekty wizualne, biznesowo o założenia i kwestie sprzedażowe realizowanego produktu.'
			},
			{
				title: 'Angażujący storytelling',
				text: 'Animacja staje się coraz powszechniejsza, co oznacza, że bezbarwne komunikaty giną w przestrzeni w mgnieniu oka. Dzięki profesjonalnie budowanej narracji i atrakcyjnym wizualnym komunikatom angażujemy Waszych odbiorców, wzbudzamy w nich emocje, a tym samym podnosimy wskaźnik konwersji.'
			},
		],

		whatUGet: [
			{
				text: '81% firm aktywnie używa video jako swojego narzędzia marketingowego '
			},
			{
				text: 'W cyfrowym strumieniu informacji użytkowników przyciąga to, co unikalne i wyjątkowe. Rozwój technologii, a wraz z nim pojawienie się animacji reklamowych jako codziennego wsparcia działań marketingowych i sprzedażowych, znacząco to ułatwiło. Dzięki animacji łatwiej jest przekazać nie tylko to, co prawdziwe, ale również to, co abstrakcyjne i niematerialne. Ograniczeniem jest jedynie wyobraźnia.'
			},
			{
				text: 'W naszych produkcjach kierujemy się realnymi danymi i najwyższymi standardami realizacji. Wdrażamy atrakcyjny storytelling angażujący odbiorcę oraz dbamy o spójną i płynną animację. Dzięki temu tworzymy potężne narzędzie, które realnie wesprze Wasze codzienne działania. '
			}
		],

		images: [
			{
				image: require('@/assets/images/animation/Animacja_home/1.webp'),
				alt: 'ilustration'
			},
			{
				image: require('@/assets/images/animation/Animacja_home/2.webp'),
				alt: 'ilustration'
			},
			{
				image: require('@/assets/images/animation/Animacja_home/3.webp'),
				alt: 'ilustration'
			},
			{
				image: require('@/assets/images/animation/Animacja_home/4.webp'),
				alt: 'ilustration'
			},
			{
				image: require('@/assets/images/animation/Animacja_home/5.webp'),
				alt: 'ilustration'
			},
			{
				image: require('@/assets/images/animation/Animacja_home/6.webp'),
				alt: 'ilustration'
			},
			{
				image: require('@/assets/images/animation/Animacja_home/7.webp'),
				alt: 'ilustration'
			},
			{
				image: require('@/assets/images/animation/Animacja_home/8.png'),
				alt: 'ilustration'
			},
			{
				image: require('@/assets/images/animation/Animacja_home/9.webp'),
				alt: 'ilustration'
			},
		],

		possibilities: [
			{
				title: 'Strona internetowa',
				text: 'Będzie świetnym elementem wyjaśniającym usługę bądź aspekty poszczególnych zagadnień z nią związanymi',
				subtext: 'Film umieszczony na stronie internetowej może zwiększyć konwersję o <strong style="color: white">86%</strong>',
				info: 'dane: Wordstream'
			},
			{
				title: 'Social media',
				text: 'Skutecznie zachęci odbiorców do dalszego zapoznania \n' +
					'się z usługą bądź produktem',
				subtext: 'Dzięki zamieszczaniu filmów w mediach społecznościowych <strong style="color: white">93%</strong> marketerów przekształciło swoje leady w sprzedaż. ',
				info: 'dane: Animoto'
			},
			{
				title: 'Mailing',
				text: 'Mailing z załączoną animacją będzie atrakcyjną formą na zwiększenie zainteresowania Waszych odbiorców',
				subtext: 'Video zwiększa CTR o <strong style="color: white">65%</strong> i zmniejsza liczbę wypisów z subskrypcji o <strong>26%.</strong>',
				info: 'dane: Campaign Monitor'
			},
			{
				title: 'Kampania reklamowa',
				text: 'Atrakcyjna forma wizualna zaintryguje oraz przyciągnie nowych klientów, sprawiając że będą chcieli dowiedzieć się więcej. W ten sposób pokażecie również, dlaczego Wasz produkt jest warty swojej ceny.',
				subtext: '<strong style="color: white">90%</strong> klientów twierdzi, że video pomaga im podejmować decyzje zakupowe i jest bardziej prawdopodobne, że kupią produkt po obejrzeniu filmu.',
				info: 'dane: Alexa'
			},
			{
				title: 'Prezentacja wewnątrzfirmowa',
				text: 'Warto wywołać dobre wrażenie na swoich kontrahentach lub kliencie wewnętrznym czymś bardziej efektownym niż statyczna prezentacja danych',
				subtext: 'Ludzie są 22 razy bardziej skłonni do zapamiętania faktu, który został podany \n' +
					'z użyciem storytellingu',
				info: 'dane: Visme'
			},
			{
				title: 'Ekran reklamowy',
				text: 'Przyciągnięcie klientów na wielkich wyświetlaczach w punkcie obsługi klienta, prezentacja marki w salonie? Elastyczność animacji pozwala na taką realizację niezależnie od wielkości wybranego ekranu LCD.',
				subtext: '<strong style="color: white">Opowiedzcie nam, czego potrzebujecie, <br>pomożemy Wam to zrealizować.</strong>',
				info: ''
			},
		],

		process: {
			text: 'Niezwykle ważnym elementem jest sposób tworzenia animacji. Dzięki odpowiedniemu procesowi jesteśmy w stanie tworzyć animacje, które będą realizować cele biznesowe oraz zachowają najwyższą jakość. ',
			image: require('@/assets/images/animation/Animacja_home/process.png'),
			link: '/animacja-proces'
		},

		worth: [
			{
				title: 'Zainteresuj',
				text: 'Ludzie spędzają średnio <strong style="color: white">2,6</strong> razy więcej czasu na stronach z video niż tymi bez nich.',
				subtext: 'Marketerzy, którzy włączają video do swoich kampanii, doświadczają o <strong style="color: white">34%</strong> wyższych współczynników konwersji niż w przypadku kampanii bez video.'
			},
			{
				title: 'Zaprezentuj',
				text: '<strong style="color: white">72%</strong> klientów preferuje zapoznać się z produktem bądź usługą za pomocą video.',
				subtext: '<strong style="color: white">94%</strong> marketerów jest zdania, że video zwiększyło zrozumienie ich produktu lub usługi przez użytkowników.'
			},
			{
				title: 'Sprzedaj',
				text: '<strong style="color: white">51%</strong> specjalistów ds. marketingu na całym świecie określa video jako rodzaj treści o najlepszym ROI.',
				subtext: '<strong style="color: white">78%</strong> marketerów twierdzi, że video bezpośrednio pomogło zwiększyć sprzedaż.'
			},
		]
	},

	{
		id: routePath.PRODUCT_ANIMATION.path,
		list: [
			{
				text: 'Animacja produktowa pozwoli opowiedzieć o  produkcie i sposobie jego działania bądź korzystania z niego w prosty i przystępny sposób. Ten typ animacji jest jednym z najpotężniejszych narzędzi marketingowych i sprzedażowych. Aktywnie i mądrze używany stanie się  nieocenionym elementem sprzedaży bezpośredniej. <br><br>Nic nie wywrze lepszego wrażenia niż kunsztowna, dobrze przemyślana animacja, która wyjaśni wszystkie najbardziej kluczowe elementy.',
				image: require('@/assets/images/animation/Animacja_produktowa/02_ilu.webp')
			},
			{
				text: 'Bardzo częstym zachowaniem klientów przed zakupem jest upewnienie się, że rozumieją Wasz produkt czy usługę i czy te na pewno będą dla nich przydatne. <br><br>Skoro weszli już na Waszą stronę, upewnij się, że poznają największe zalety i korzyści Waszego produktu w szybki i maksymalnie atrakcyjny sposób, bez konieczności przeglądania całej strony, zwłaszcza gdy nie mają na to czasu. To Wasza szansa na wyciągnięcie ręki do klienta i upewnienie się, że zapamięta on Wasz produkt i do niego wróci.',
				image: require('@/assets/images/animation/Animacja_produktowa/03_ilu.webp')
			},
			{
				text: 'Odbiorcy przed zakupem lubią upewnić się, że rozumieją produkt i chcą wiedzieć, jakie będzie niósł dla nich korzyści. Skoro weszli już na stronę, warto upewnić się, że poznają największe zalety i korzyści produktu w szybki i maksymalnie atrakcyjny sposób, bez konieczności zwiedzania całej strony, zwłaszcza gdy nie mają na to czasu. To okazja na wyciągnięcie ręki do klienta i upewnienie się, że zapamięta on produkt i do niego wróci.',
				image: require('@/assets/images/animation/Animacja_produktowa/04_ilu.webp')
			},
		],
		recommended: {
			title: 'Animacja produktowa jest rekomendowana, gdy:',
			list: [
				{
					text: 'Istnieje potrzeba wyjaśnienia, jak działa produkt bądź usługa;'
				},
				{
					text: 'Chcecie zachęcić do dalszej eksploracji swojego produktu/usługi;'
				},
				{
					text: 'Chcecie wyjść naprzeciw osobom, które są wstępnie zainteresowane Waszym produktem i szukają garści konkretnych informacji na jego temat;'
				},
				{
					text: 'Zamierzacie podkreślić korzyści płynące z Waszego produktu/usługi;'
				},
				{
					text: 'Zależy Wam na możliwości umieszczenia animacji na stronie, aby tam generowała ROI i podtrzymywała ruch.'
				},
			]
		}
	},
	{
		id: routePath.ADVERTISEMENT_ANIMATION.path,
		list: [
			{
				text: 'W obecnym chaosie komunikacyjnym trudno wyróżnić się czymś, co wygląda tak samo jak wszystko inne dookoła. Aby wyglądać wiarygodnie i przekazać swojemu odbiorcy wartości marki i jej produkt, potrzebne jest do tego odpowiednie narzędzie. Animacja reklamowa pozwoli na podkreślenie obecności marki na rynku i wyróżnienie jej  spośród konkurencji.',
				image: require('@/assets/images/animation/Animacja_reklamowa/02_ilu.webp')
			},
			{
				text: 'Kluczowa jest dla nas forma przekazu. W naszych animacjach reklamowych stosujemy atrakcyjny storytelling, wobec czego nie tylko zaciekawiamy Waszych odbiorców produktem, ale także pozwalamy im nawiązać emocjonalną więź z Waszą marką. Dzięki temu odbiorcy przyswajają ją znacznie lepiej niż tylko statyczne informacje. <br><br>Naszą misją jest sprawienie, aby ruchome treści dopełniały przekaz, a nie stanowiły odrębnej formy od komunikatu. Dzięki temu rzeczy, które tworzymy, nie tylko są doskonale odbierane i angażujące, ale także znacznie lepiej zapamiętywalne.',
				image: require('@/assets/images/animation/Animacja_reklamowa/03_ilu.webp')
			},
			{
				text: 'Wygląd i prezencja jest bezspornie jednym z najważniejszych aspektów. Animacja reklamowa to szansa na fenomenalne pierwsze wrażenie. <br><br>W naszych produkcjach podchodzimy do Waszej historii z największą dbałością i przywiązaniem do szczegółów. Wspólnie z Wami dobieramy najbardziej trafny język wizualny, który uzupełni pozostałe elementy marki. Nasze ilustracje wyróżniają się kunsztem i umiejętnością bezbłędnego dobierania środków wyrazu i błyskawicznie przyciągają wzrok odbiorców w strumieniu innych treści.',
				image: require('@/assets/images/animation/Animacja_reklamowa/04_ilu.webp')
			},
		],
		recommended: {
			title: 'Animacja reklamowa jest rekomendowana, gdy:',
			list: [
				{
					text: 'Kluczowe jest dla Was wyróżnienie Waszego produktu/marki;'
				},
				{
					text: 'Zależy Wam na wyróżnieniu Twojego produktu, również pośród konkurencji;'
				},
				{
					text: 'Chcecie dotrzeć do nowych odbiorców, zwłaszcza w formie marketingu pośredniego.'
				},
			]
		}
	},
	{
		id: routePath.PROMOTIONAL_ANIMATION.path,
		list: [
			{
				text: 'Znacie swoich odbiorców, a oni znają Waszą markę i jej produkty - ale czy na pewno? <br><br>W ferworze różnych prowadzonych wielopoziomowo działań marketingowych i personalizowanych ofert zdarza się, że gdzieś umyka marketing skierowany na pokazanie pełnego wachlarza rozwiązań oferowanych przez organizację. Chociaż Wasza firma ma już wielu stałych klientów, wciąż widzicie możliwości poszerzenia współpracy i sprzedaży większej liczby usług i produktów. Jeżeli Wasi dotychczasowi klienci są zadowoleni z Waszych usług, całkiem prawdopodobne, że zainteresują się również innymi elementami Waszej oferty, o ile tylko będą mieli o niej pojęcie.',
				image: require('@/assets/images/animation/Animacja_promocyjna/02_ilu.webp'),
			},
			{
				text: 'Tworzymy animacje promocyjne jako uniwersalne materiały, które będziecie w stanie wykorzystać w dowolnym miejscu jako uzupełnienie swoich dotychczasowych działań marketingowych. To okazja, abyście pochwalili się szerokim spektrum tego, co oferuje Wasza firma, bez skupiania się na konkretnym produkcie.',
				image: require('@/assets/images/animation/Animacja_promocyjna/03_ilu.webp'),
			},
			{
				text: 'Dzięki fantastycznemu storytellingowi nawiązujemy emocjonalną więź z Waszymi odbiorcami poprzez pokazanie im nie tylko oferty, ale również ludzkiej twarzy Waszej marki. Prezentujemy wartości, jakie przekazuje Wasza organizacja, i podkreślamy jej misję.',
				image: require('@/assets/images/animation/Animacja_promocyjna/04_ilu.webp'),
			},
		],
		recommended: {
			title: 'Animacja promocyjna jest rekomendowana, gdy:',
			list: [
				{
					text: 'Chcecie dotrzeć nie tylko do nowych, ale również dotychczasowych klientów, którzy mogą nie mieć świadomości istnienia szerokiego wachlarza produktów Waszej marki;'
				},
				{
					text: 'Grono odbiorców Waszej animacji ma być bardzo szerokie i potrzebujecie animacji jako wstępu do dalszego marketingu;'
				},
				{
					text: 'Nie zależy Wam na konkretnym CTA, bardziej wolicie uświadomić odbiorców o rozwiązaniach wielu ich problemów;'
				},
				{
					text: 'Potrzebujecie animacji na galę bądź spotkanie biznesowe z kontrahentami;'
				},
				{
					text: 'Chcecie przypomnieć o istnieniu swojej firmy;'
				},
			]
		}
	},
	{
		id: routePath.ONBOARDING_ANIMATION.path,
		list: [
			{
				text: 'Wdrożenie nowego pracownika wiąże się zapoznaniem go ze strukturą firmy, przeprowadzeniem przez obowiązujące procedury i standardy, wdrożeniem w zasady, przekazanie obowiązków oraz udogodnień. <br><br>Za pomocą animacji można to zrobić w ciekawy, niestandardowy oraz zapadający w pamięć sposób, zapewniając łatwy dostęp do tych treści w razie konieczności przypomnienia sobie zasad przez nowicjusza. Jest to też możliwość, aby wesprzeć działania employer brandingowe w Waszej organizacji.',
				image: require('@/assets/images/animation/Animacja_onboardingowa/02_ilu.webp'),
			},
			{
				text: 'Animacja onboardingowa pomoże Wam atrakcyjnie przedstawić strategię, misję oraz cele biznesowe organizacji, ale także zaprezentować strukturę zatrudnienia lub wyjaśnić procedury. To również znakomita okazja, aby wyjaśnić działanie promocji i benefitów wewnątrz struktur.',
				image: require('@/assets/images/animation/Animacja_onboardingowa/03_ilu.webp'),
			},
		],
		recommended: {
			title: 'Animacja onboardingowa jest rekomendowana, gdy:',
			list: [
				{
					text: 'Potrzebujecie wdrożyć swoich pracowników w struktury wewnętrzne;'
				},
				{
					text: 'Zależy Wam na wsparciu employer brandingu;'
				},
				{
					text: 'Chcecie podnieść wartość firmy w oczach obecnych pracowników.'
				},
			]
		}
	},
	{
		id: routePath.PRESENTATION_ANIMATION.path,
		list: [
			{
				text: 'Jeżeli Waszą organizację czeka kluczowa prezentacja z głównym inwestorem albo strategicznym kontrahentem, warto rozważyć animację prezentacyjną. Niezależnie od tego, czy zależy Wam na na whiteboardzie czy kolorowej prezentacji pełnej danych, animacja pokaże wszystko to, co chcecie pokazać, a nawet jeszcze więcej.',
				image: require('@/assets/images/animation/Animacja_prezentacyjna/02_ilu.webp'),
			},
			{
				text: 'Zamień chartboard i prezentację Powerpoint na animację, która utrzyma uwagę Waszych odbiorców i pomoże Wam zaprezentować zwięźle i atrakcyjnie wszystko to, co chcecie przekazać.',
				image: require('@/assets/images/animation/Animacja_prezentacyjna/03_ilu.webp'),
			}
		],
		recommended: {
			title: 'Animacja prezentacyjna jest rekomendowana, gdy:',
			list: [
				{
					text: 'Animacja będzie pokazywana bezpośrednio podczas prezentacji, a nie stanowiła integralny element kampanii albo strony internetowej.'
				},
				{
					text: 'Istotniejsze jest dla Was pokazanie twardych danych niż nawiązanie emocjonalnej więzi z odbiorcą poprzez storytelling;'
				},
				{
					text: 'Waszym głównym celem jest zaprezentowanie idei inwestorowi, a nie sprzedaż produktu klientom;'
				},
				{
					text: 'Posiadacie niewielki, nieprzekraczalny budżet;'
				}
			]
		}
	}
]

export {animation}
