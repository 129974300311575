import {animationFaq, productFaq} from '@/data/faq'
import {animationENFaq} from '@/data/en/faq'
import Dropdown from '@/components/Dropdown/index.vue'

export default {
	name: "faq",
	components: {
		Dropdown
	},
	computed: {
		faq() {
			switch (true) {
				case this.$route.fullPath.includes('/faq/service-design'):
					return this.productFaq
				case this.$route.fullPath.includes('/animacja'):
					return this.animationFaq
				case this.$store.state.isEn:
					return this.animationENFaq

				default:
					return this.productFaq
			}
		},

		link() {
			switch (true) {
				case this.$route.fullPath.includes('/faq/service-design'):
					this.buttonText = 'Zobacz FAQ dla Animacji'
					return '/faq/animacja'
				case this.$route.fullPath.includes('/animacja'):
					this.buttonText = 'Zobacz FAQ dla <br>Product&Service Design'
					return '/faq/service-design'

				default:
					this.buttonText = 'Zobacz FAQ dla Animacji'
					return '/faq/service-design'
			}
		},

		title() {
			switch (true) {
				case this.$route.fullPath.includes('/faq/service-design'):
					return 'PRODUCT&SERVICE DESIGN'
				case this.$route.fullPath.includes('/animacja'):
					return 'ANIMACJA'
				case this.$store.state.isEn:
					return 'ANIMATION'

				default:
					return 'PRODUCT&SERVICE DESIGN'
			}
		}
	},
	data() {
		return {
			productFaq,
			animationFaq,
			animationENFaq,
			smallHeader: 'PRODUCT&SERVICE DESIGN',
			buttonText: 'Zobacz FAQ dla Animacji'
		}
	}
}
