<template>
	<section class="animation">
		<div v-if="content.awards" class="animation__awards">
			<ul class="animation__awards-list">
				<li
					v-for="image in content.awards"
					:key="image.alt"
					class="animation__awards-list-item">
					<img :src="image.image" :alt="image.alt">
					<span v-if="image.number">{{ image.number }}</span>
				</li>
			</ul>
		</div>

		<div class="animation__content">
			<section
				v-if="
					content.id === '/animacja' ||
					content.id === '/en'
				"
		         class="animation__home"
			>
				<Home :content="content"/>
			</section>

			<section class="animation__subpage" v-else>
				<SubPage :content="content" subpage="animation"/>
			</section>
		</div>

		<div class="check__process">
			<h2>{{ this.$store.state.isEn? 'Learn more about the animation production process' : 'Zobacz jak wygląda proces produkcji animacji' }}</h2>
			<Button
				buttonStyle="primary"
				:additionalElement="true"
				additionalElementStyle="arrowInCircle"
				:whiteArrow="false"
				:buttonText="this.$store.state.isEn ? 'Animation production process' : 'Proces produkcji animacji'"
				:buttonLink="this.$store.state.isEn ? '/en/animation-process' : '/animacja-proces'"
			></Button>
		</div>

		<FAQComponent :currentButton="this.$store.state.isEn ? 'animation' : 'animacja'" />
	</section>
</template>

<script src="./component.js"></script>
<style src="./style.sass" scoped lang="sass"></style>
<style lang="sass" scoped>
.check__process
	width: 144em
	margin: 160px auto 90px
	padding-left: 211px
	height: 100px

	@include view('md')
		width: 100%
		padding: 60px 100px
		margin: 0 auto
		height: unset

	@include view('sm')
		width: 100%
		padding: 60px 33px
		margin: 0 auto
		height: unset

	h2
		font-size: 28px
		font-weight: $weight--light-plus
		margin-bottom: 30px

		@include view('sm')
			font-size: 21px !important

::v-deep .main__faq

	@include view('sm')
		padding: 60px 33px
</style>
