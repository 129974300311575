<template>
	<div class="row" v-if="content">
		<div
			class="why-animation"
		>
			<div class="why-animation-image">
				<img :src="content.whyAnimation.image" alt="ilustracja">
			</div>

			<div class="why-animation-text">
				<h3>{{ content.whyAnimation.title }}</h3>
				<p>{{ content.whyAnimation.text }}</p>
			</div>
		</div>
	</div>

	<div class="animation__types">
		<ul class="animation__types-list">
			<li
				v-if="this.$store.state.isMobile || this.$store.state.isTablet"
				v-for="item in content.animationList"
				:key="item.title"
				class="animation__types-list-item"
			>
				<h3>{{ item.title }}</h3>
				<p>{{ item.text }}</p>

				<Button
					buttonStyle="secondary"
					:additionalElement="true"
					additionalElementStyle="arrowWithoutCircle"
					:buttonText="handleButtonText"
					:whiteArrow="false"
					:buttonLink="item.link"
				/>
			</li>

			<div class="animation__types-desktop" v-else>
				<div class="animation__types-list-row">
					<li
						v-for="item in content.animationList.slice(0, 3)"
						:key="item.title"
						class="animation__types-list-item"
					>
						<h3>{{ item.title }}</h3>
						<p>{{ item.text }}</p>

						<Button
							buttonStyle="secondary"
							:additionalElement="true"
							additionalElementStyle="arrowWithoutCircle"
							:buttonText="handleButtonText"
							:buttonLink="item.link"
						></Button>
					</li>
				</div>
				<div class="animation__types-list-row">
					<li
						v-for="item in content.animationList.slice(3, 5)"
						:key="item.title"
						class="animation__types-list-item"
					>
						<h3>{{ item.title }}</h3>
						<p>{{ item.text }}</p>

						<Button
							buttonStyle="secondary"
							:additionalElement="true"
							additionalElementStyle="arrowWithoutCircle"
							:buttonText="handleButtonText"
							:whiteArrow="false"
							:buttonLink="item.link"
						></Button>
					</li>
				</div>
			</div>
		</ul>
	</div>

	<div class="showreel">
		<h3>{{ content.showreel.title }}</h3>
		<Button
			buttonStyle="showreel"
			:buttonText="this.$store.state.isEn ? 'Check the showreel' : 'Zobacz showreel'"
			:buttonLink="content.showreel.link"
			@click.prevent="handleShowreel()"
			:style="[this.$store.state.isEn ? {width: '250px'} : '']"
		>
		</Button>

	</div>
	<div class="showreel-iframe" v-if="showreelVisibility">

		<i class="fas fa-times showreel-iframe-icon" @click="handleShowreel()"/>

		<iframe src="https://player.vimeo.com/video/641181417?h=76d7365ca0" width="940" height="660" frameborder="0"
		        allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
		<p><a href="https://vimeo.com/641181417">Hylite - 2022 Showreel</a> from <a href="https://vimeo.com/hylite">Hylite</a>
			on <a href="https://vimeo.com">Vimeo</a>.</p>
	</div>

	<div class="why-us">
		<div class="why-us__header">
			<h4 class="h4-style"> {{ this.$store.state.isEn ? 'Why us?' : 'Dlaczego my?' }} </h4>
			<h2 class="h2-style h2-sm-style">{{
					this.$store.state.isEn ? 'Knowledge, skills and vast experience' : 'Wiedza, umiejętności, doświadczenie'
				}} </h2>
		</div>

		<div class="why-us__content">
			<div
				v-for="box in content.whyUs"
				:key="box.title"
				class="why-us__box"
			>
				<h3 v-html="box.title"/>
				<p>{{ box.text }}</p>
			</div>
		</div>
	</div>

	<div class="home__image">
		<img src="@/assets/images/animation/Animacja_home/04_ilustracja.webp" alt="ilustracja">
	</div>

	<div class="what-u-get">
		<div class="what-u-get__header">
			<h4 class="h4-style">{{ this.$store.state.isEn ? 'What will you gain?' : 'co zyskasz?' }} </h4>
			<h2 class="h2-style h2-sm-style">
				{{ this.$store.state.isEn ? 'Sales and marketing tool' : 'Narzędzie sprzedażowo-marketingowe' }} </h2>
		</div>

		<div class="what-u-get__content">
			<div class="what-u-get__left">
				<div class="what-u-get__box">
					<p>{{ content.whatUGet[0].text }}</p>
				</div>
			</div>

			<div class="what-u-get__right">
				<div class="what-u-get__box">
					<p>{{ content.whatUGet[1].text }}</p>
				</div>
				<div class="what-u-get__box">
					<p>{{ content.whatUGet[2].text }}</p>
				</div>
			</div>
		</div>
	</div>

	<div class="animation__slider">
		<div class="animation__slider-div">
			<h2 class="h2-style h2-sm-style">
				{{ this.$store.state.isEn ? 'How can business animation help you?' : 'Co daje animacja?' }} </h2>
			<Slider v-if="!this.$store.state.isMobile" carouselType="animationHome"
			        :slides="handleSlidesForDesktop"/>
		</div>
		<Slider v-if="this.$store.state.isMobile" carouselType="animationHomeMobile"
		        :slides="handleSlidesForMobile"/>
	</div>

	<div class="home__images">
		<div
			v-for="image in content.images"
			:key="image.alt"
			class="home__images-box"
		>
			<img :src="image.image" :alt="image.alt">
		</div>
	</div>

	<div class="possibilities">
		<div class="possibilities__header">
			<h4 class="h4-style">{{ this.$store.state.isEn ? 'Sea of possibilities' : 'możliwości' }} </h4>
			<h2 class="h2-style h2-sm-style">
				{{ this.$store.state.isEn ? 'Where to use business animation?' : 'Gdzie można użyć animacji?' }} </h2>
		</div>

		<div class="possibilities__content">
			<div
				v-for="item in content.possibilities"
				:key="item.title"
				class="possibilities__box"
			>
				<h3>{{ item.title }}</h3>
				<p v-html="item.text"></p>

				<div class="possibilities__sub-box">
					<p v-html="item.subtext"></p>
					<span v-html="item.info"></span>
				</div>
			</div>
		</div>
	</div>

	<Clients/>

	<section class="process">
		<div class="process__header">
			<h4 class="h4-style">{{ this.$store.state.isEn ? 'Step by step' : 'krok po kroku' }} </h4>
			<h2 class="h2-style h2-sm-style">
				{{ this.$store.state.isEn ? 'Animation Production Process' : 'Proces produkcji animacji' }} </h2>
		</div>

		<div class="process__content">
			<p>{{ content.process.text }}</p>

			<div v-if="!this.$store.state.isEn">
				<img class="process__content-image-desktop" :src="content.process.image" alt="process">
				<img class="process__content-image-mobile"
				     src="@/assets/images/animation/Animacja_home/process_mobile.png"
				     alt="process">
			</div>

			<div v-else>
				<img class="process__content-image-desktop" src="@/assets/images/animation/Animacja_home/process_en.png"
				     alt="process">
				<img class="process__content-image-mobile"
				     src="@/assets/images/animation/Animacja_home/process_en_mobile.png"
				     alt="process">
			</div>

			<Button
				buttonStyle="default"
				:buttonLink="content.process.link"
				:buttonText="this.$store.state.isEn ? 'Learn more about the process' : 'Dowiedz się więcej o procesie'"
			></Button>
		</div>
	</section>

	<section class="worth">
		<div class="worth__header">
			<h2 class="h2-style h2-sm-style">{{ this.$store.state.isEn ? 'Is it worth it?' : 'Czy warto?' }}</h2>
		</div>

		<div class="worth__content">
			<div
				v-for="box in content.worth"
				:key="box.title"
				class="worth__box"
			>
				<h3 :style="[this.$store.state.isEn ? {'line-height': '33px'} : '']">{{ box.title }}</h3>
				<p v-html="box.text"></p>
				<p v-html="box.subtext"></p>
			</div>
		</div>
	</section>
</template>

<script>
import {animationHomeSlides, animationHomeSlidesMobile} from '@/data/slider.js'
import {animationHomeSlidesEN, animationHomeSlidesMobileEN} from '@/data/en/slider.js'

export default {
	name: "animation Home index",
	props: {
		content: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			animationHomeSlides,
			animationHomeSlidesMobile,
			animationHomeSlidesEN,
			animationHomeSlidesMobileEN,
			showreelVisibility: false
		}
	},
	computed: {
		handleSlidesForDesktop() {
			if (this.$store.state.isEn) {
				return this.animationHomeSlidesEN
			}

			return this.animationHomeSlides
		},
		handleSlidesForMobile() {
			if (this.$store.state.isEn) {
				return this.animationHomeSlidesMobileEN
			}

			return this.animationHomeSlidesMobile
		},
		handleButtonText() {
			if (this.$store.state.isEn) {
				return 'more'
			}

			return 'więcej'
		}
	},
	methods: {
		handleShowreel() {
			this.showreelVisibility = !this.showreelVisibility
		}
	}
}
</script>

<style scoped lang="sass" src="./style.sass"/>
