import {checkboxData} from './data'

export default {
	name: "form",
	data() {
		return {
			checkboxData,
			currentStep: 1,
			charLimit: {
				personName: {
					min: 2,
					max: 50
				},
				jobPosition: {
					min: 2,
					max: 100
				},
				companyName: {
					min: 1,
					max: 100
				},
				phoneNumber: {
					min: 9,
					max: 9,
				},
				message: {
					min: 1,
					max: 7200
				}
			},
			formData: {
				honey: '',
				personName: '',
				jobPosition: '',
				companyName: '',
				email: '',
				message: '',
				phoneNumber: null
			}
		}
	},
	methods: {
		validFirstStepForm() {

		},
		handleCheckBox(e, index) {
			const currCheckbox = e.target
			const checkBoxes = document.querySelectorAll('.checkbox-step-1')
			checkBoxes.forEach((x, i) => {
				if(x.checked === false && i === index) {
					x.checked = true
					return
				}

				if (x.checked === true) {
					x.checked = false
					currCheckbox.checked = true
				}
			})
		},
		testEmail(email) {
			const regex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
			return regex.test(email)
		},
		testPhoneNumber(phone) {
			const regex = /(?:(?:(?:\+|00)?48)|(?:\(\+?48\)))?(?:1[2-8]|2[2-69]|3[2-49]|4[1-8]|5[0-9]|6[0-35-9]|[7-8][1-9]|9[145])\d{7}/
			return regex.test(phone)
		},
		preventLeading(e) {
			if (!e.target.value)
				e.preventDefault();
			else if (e.target.value[0] === ' ' || e.target.value[0] === '\n')
				e.target.value = e.target.value.replace(/^\s*/, "")
		},
		charCounter() {
			if (this.currentStep === 1) {
				let personName = document.querySelector('#fullname').value
				let jobPosition = document.querySelector('#position').value
				let companyName = document.querySelector('#company').value

				if (personName.length >= this.charLimit.personName) {
					personName = personName.substr(0, this.charLimit.personName)
				}

				if (jobPosition.length >= this.charLimit.jobPosition) {
					jobPosition = jobPosition.substr(0, this.charLimit.jobPosition)
				}

				if (companyName.length >= this.charLimit.companyName) {
					companyName = companyName.substr(0, this.charLimit.companyName)
				}
			}

			if (this.currentStep === 2) {
				let message = document.querySelector('#area').value

				if (message.length >= this.charLimit.message) {
					message = message.substr(0, this.charLimit.message)
				}
			}

			if (this.currentStep === 3) {
				let phoneNumber = document.querySelector('#phone').value

				if (phoneNumber.length >= this.charLimit.phoneNumber) {
					phoneNumber = phoneNumber.substr(0, this.charLimit.phoneNumber)
				}
			}
		},
		handleStep() {
			switch (true) {
				case this.currentStep === 1:
					this.currentStep = 2
					break;
				case this.currentStep === 2:
					this.currentStep = 3
					break;
				case this.currentStep === 3:
					this.currentStep = 4
					break;

				default:
					this.currentStep = 1
					break;
			}
		}
	}

}
