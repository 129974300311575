<template>
	<section class="subpage">
		<div class="subpage__content">
			<div
				v-if="content.list && this.$route.fullPath === '/badania'"
				v-for="item in content.list.slice(0, 3)"
				:key="item.id"
				class="subpage__row"
			>
				<div class="subpage__box">
					<h2 class="h2-style h2-sm-style">{{ item.title }}</h2>

					<div class="subpage__text">
						<p v-html="item.text"/>
					</div>
				</div>

				<div class="subpage__image" :class="{'custom-img' : this.$route.fullPath === '/badania'}">
					<img v-if="item.imageMobile && this.$store.state.isMobile" class="only-mobile"
					     :src="item.imageMobile" alt="illustration">
					<img v-else :src="item.image" alt="illustration">
				</div>
			</div>

			<div v-if="this.$route.fullPath === '/badania' && content.text"
			     class="subpage__info">
				<div class="subpage__info-text">
					<p class="p-style">{{ content.text }}</p>
				</div>
			</div>

			<div
				v-if="this.$route.fullPath === '/badania' && content.list"
				v-for="item in content.list.slice(3, 4)"
				:key="item.id"
				class="subpage__row"
			>
				<div class="subpage__box">
					<h2 class="h2-style h2-sm-style">{{ item.title }}</h2>

					<div class="subpage__text">
						<p v-html="item.text"/>
					</div>
				</div>

				<div class="subpage__image">
					<img :src="item.image" alt="illustration">
				</div>
			</div>

			<div
				v-else
				v-for="item in content.list"
				:key="item.id"
				class="subpage__row"
			>
				<div class="subpage__box">
					<h2 class="h2-style h2-sm-style">{{ item.title }}</h2>

					<div class="subpage__text">
						<p v-html="item.text"/>
					</div>
				</div>

				<div class="subpage__image">
					<img :src="item.image" alt="illustration">
				</div>
			</div>
		</div>

		<div v-if="!this.$store.state.isMobile && content.summary"
		     class="summary"
		>
			<div class="summary__header">
				<h2 class="h2-style h2-sm-style">{{ content.summary.title }}</h2>
			</div>

			<div v-if="content.summary.list" class="summary__content">
				<div
					v-for="item in content.summary.list"
					:key="item.text"
					class="summary__box">
					<p class="p-style" v-html="item"/>
				</div>
			</div>
		</div>

		<div class="summary-mobile"
		     v-if="
				content.summary &&
				this.$store.state.isMobile && (
				this.$route.fullPath === '/research-strategia-innowacje' ||
				this.$route.fullPath === '/badania' )
			">
			<div class="summary__header-mobile">
				<h2 class="h2-style h2-sm-style">{{ content.summary.title }}</h2>
			</div>

			<Slider
				v-if="content.summary"
				carouselType="summary"
				:slides="content.summary.list"
			/>
		</div>

		<div v-if="content.recommended" class="recommended">
			<div class="recommended__header">
				<h2>{{ content.recommended.title }}</h2>
			</div>

			<div v-if="!this.$store.state.isMobile" class="recommended__content">
				<div class="recommended__row">
					<div
						v-for="(box, i) in content.recommended.list.slice(0,3)"
						:key="i"
						class="recommended__box"
					>
						<p v-html="box.text"/>
					</div>
				</div>
				<div class="recommended__row">
					<div
						v-for="(box, i) in content.recommended.list.slice(3, 6)"
						:key="i"
						class="recommended__box"
					>
						<p v-html="box.text"/>
					</div>
				</div>
			</div>

			<Slider
				v-else-if="
				content.recommended &&
				this.$store.state.isMobile
			"
				carouselType="subpage"
				:slides="content.recommended.list"
			/>
		</div>
	</section>
</template>

<script>
export default {
	name: "SubPage",
	props: {
		content: {
			type: Object
		},
		subpage: {
			type: String
		}
	},
}
</script>
<style scoped lang="sass" src="./style.sass"/>

