<template>
	<section class="clients section--full-width">
		<div class="clients-content container">
			<section class="clients-mobile"
			         v-if="this.$store.state.isTablet === true || this.$store.state.isMobile === true">
				<div class="clients-mobile__content">
					<div
						v-for="image in allImages"
						:key="image.alt"
						class="clients-mobile__box"
					>
						<img :src="image.src" :alt="image.alt">
					</div>
				</div>
			</section>

			<div v-else class="clients-desktop">
				<div class="clients-row"
				>
					<div
						v-for="box in images[0]"
						class="clients-box"
					>
						<img :src="box.src" :alt="box.alt">
					</div>
				</div>

				<div class="clients-row"
				>
					<div
						v-for="box in images[1]"
						class="clients-box"
					>
						<img :src="box.src" :alt="box.alt">
					</div>
				</div>

				<div class="clients-row"
				>
					<div
						v-for="box in images[2]"
						class="main__clients-box"
					>
						<img :src="box.src" :alt="box.alt">
					</div>
				</div>

				<div class="clients-row"
				>
					<div
						v-for="box in images[3]"
						class="clients-box"
					>
						<img :src="box.src" :alt="box.alt">
					</div>
				</div>

				<div class="clients-row"
				>
					<div
						v-for="box in images[4]"
						class="clients-box"
					>
						<img :src="box.src" :alt="box.alt">
					</div>
				</div>

				<div class="clients-row"
				>
					<div
						v-for="box in images[5]"
						class="clients-box"
					>
						<img :src="box.src" :alt="box.alt">
					</div>
				</div>

				<div class="clients-row"
				>
					<div
						v-for="box in images[6]"
						class="clients-box"
					>
						<img :src="box.src" :alt="box.alt">
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import {images} from '@/data/data'

export default {
	name: "index",
	computed: {
		allImages() {
			let arr = []
			const arr0 = images[0]
			const arr1 = images[1]
			const arr2 = images[2]
			const arr3 = images[3]
			const arr4 = images[4]
			const arr5 = images[5]
			const arr6 = images[6]

			return arr = arr0.concat(arr1, arr2, arr3, arr4, arr5, arr6)
		}
	},
	data() {
		return {
			images
		}
	}
}
</script>

<style scoped lang="sass">
@include view('md')
	.clients-mobile
		&__content
			gap: 6rem

@include view('sm')
@include view('md')
	.clients-mobile
		&__content
			gap: 6rem 3rem
			display: flex
			flex-wrap: wrap
			justify-content: space-between

		&__box
			text-align: center
			max-width: 80px
			height: 40px
			display: flex
			align-items: center
			justify-content: center

			img
				width: 100%

.clients
	position: relative
	background: $color--gray-4
	width: 100vw

	@include view('md')
		padding-left: 0

	@include view('sm')
		padding-left: 0

	&-content
		margin: 0 auto

		.clients-desktop
			width: 100%
			display: flex
			flex-direction: column
			justify-content: flex-start
			padding: 137px 0 150px 0
			gap: 3rem 0

		@include view('md')
			width: 100%
			padding: 60px 100px
			gap: 0

		@include view('sm')
			width: 100%
			padding: 60px 33px

	&-row
		width: 100%
		display: flex
		flex-direction: row
		justify-content: space-between
		align-items: center

		@include view('md')
			flex-wrap: wrap
			margin: 0

		@include view('sm')
			gap: 3em
			justify-content: unset
			flex-wrap: wrap

		&:nth-child(4)
			margin: 35px 0 160px 0

			@include view('md')
				margin: 0

		&:nth-child(7)
			justify-content: flex-start

			.clients-box
				margin: 0 50px 0 0

				@include view('md')
					margin: 0

	&-box
		@include view('md')
			text-align: center

		@include view('sm')
			width: 80px

			img
				width: 100%
				max-height: 80px
</style>
