<template>
	<section class="main__faq container">
		<div class="main__faq-heading">
			<div class="main__faq-heading-text">
				<h4 v-if="!this.$store.state.isEn" class="h4-style">masz pytania?</h4>
				<h2 class="h2-style h2-sm-style">FAQ</h2>
			</div>

			<ul class="main__faq-nav" v-if="!this.$store.state.isEn">
				<a
					v-if="!this.$store.state.isEn"
					class="main__faq-nav-item"
					:class="{ 'main__faq-nav-item-active' : currentButton === 'produkt' }"
					@click="currentButton = 'produkt'; isOpened = !isOpened"
				>
					PRODUCT & SERVICE DESIGN
				</a>
				<a
					class="main__faq-nav-item"
					:class="{ 'main__faq-nav-item-active' : currentButton === 'animacja' }"
					@click="currentButton = 'animacja'; isOpened = !isOpened"
				>
					animacja & ilustracja
				</a>

			</ul>
		</div>

		<div class="main__faq-content">
			<Dropdown
				v-for="(item, index) in faq"
				:key="index"
				:isOpened="isOpened"
			>
				<template #title><h3 class="dropdown__title"> {{ item.title }} </h3></template>
				<template #content>
					<ul class="faq__list">
						<li
							class="faq__item"
						>
							<p
								v-if="item.textArray"
								v-for="subItem in item.textArray"
								v-html="subItem.text"
							/>

							<p v-else v-html="item.text"/>
						</li>
					</ul>
				</template>
			</Dropdown>
		</div>
		<Button
			buttonStyle="primary"
			:additionalElement="true"
			additionalElementStyle="arrowInCircle"
			:buttonText="this.$store.state.isEn ? 'I want to see more questions' : 'Chcę zobaczyć więcej pytań'"
			:buttonLink="buttonLink"
		/>
	</section>
</template>

<script>
import Dropdown from '@/components/Dropdown/index'
import {animationFaq, productFaq} from '@/data/faq'
import {animationENFaq} from '@/data/en/faq'

export default {
	name: "FaqComponent",
	components: {
		Dropdown
	},
	props: {
		currentButton: {
			type: String
		}
	},
	computed: {
		faq() {
			switch (true) {
				case this.currentButton === 'produkt':
					this.buttonLink = '/faq/service-design'
					return this.productFaq.slice(0, 5)
				case this.currentButton === 'animacja':
					this.buttonLink = '/faq/animacja'
					return this.animationFaq.slice(0, 5)
				case this.$store.state.isEn || this.currentButton === 'animation':
					this.buttonLink = '/faq/en/animation'
					return this.animationENFaq.slice(0, 5)

				default:
					this.buttonLink = '/faq/service-design'
					return this.productFaq
			}
		}
	},
	data() {
		return {
			animationFaq,
			productFaq,
			animationENFaq,
			buttonLink: '',
			isOpened: false
		}
	}
}
</script>

<style scoped lang="sass">
.main__faq
	padding-top: 95px
	padding-bottom: 95px
	margin: auto

	@include view('md')
		width: 100%
		padding: 60px 100px

	@include view('sm')
		width: 100%
		padding: 60px 33px

	&-heading
		display: flex
		align-items: center
		justify-content: space-between
		margin-bottom: 73px

		@include view('sm')
		@include view('md')
			align-items: flex-start
			flex-direction: column

		h2
			margin-bottom: 0

	&-content
		padding-right: 150px
		margin-bottom: 70px

		@include view('sm')
		@include view('md')
			padding-right: 0

	&-nav
		width: 650px
		display: flex

		@include view('sm')
		@include view('md')
			width: 100%
			margin-top: 30px

		&-item
			position: relative
			display: block
			width: 280px
			text-transform: uppercase
			font-size: 18px
			font-weight: $weight--semi-bold
			font-family: $font--primary
			color: $color--gray
			text-align: center
			transition: all .3s ease
			cursor: pointer

			@include view('sm')
			@include view('md')
				width: 100%
				text-align: left
				font-size: 14px

			&:hover
				color: $color--dark !important

			&:hover::after
				opacity: 1

			&::after
				position: absolute
				content: ''
				bottom: -8px
				left: 50%
				margin: 0 auto
				transition: all .3s ease
				transform: translateX(-50%)
				width: 180px
				height: 4px
				background: $color--dark
				opacity: 0

				@include view('md')
					left: 35%

				@include view('sm')
					width: 90px !important
					left: 0 !important
					transform: unset !important

			&:nth-child(1)
				margin-right: 93px

				@include view('sm')
					margin-right: 50px

			&-active
				color: $color--dark !important

				&::after
					position: absolute
					content: ''
					bottom: -8px
					left: 50%
					transform: translateX(-50%)
					width: 182px
					height: 4px
					opacity: 1
					background: $color--dark

					@include view('md')
						left: 35%

::v-deep a[buttonStyle="default"]
	margin: 0 auto 60px auto
	padding: 25px 45px
</style>
