export default {
	name: 'Button',
	computed: {
		style() {
			let color

			if (this.color === 'black') {
				color = '#171719'
			}

			if (this.color === 'white') {
				color = "white"
			}

			return {
				'--button-color': color
			}
		}
	},
	props: {
		listName: {
			type: String
		},
		icon: {
			type: Boolean
		},
		buttonText: {
			type: String,
			required: true,
		},
		buttonStyle: {
			type: String,
		},
		additionalElement: {
			type: Boolean,
		},
		additionalElementStyle: {
			type: String
		},
		buttonLink: {
			type: String,
			default: ''
		},
		whiteArrow: {
			type: Boolean
		},
		color: {
			type: String,
			default: 'black'
		}
	}
}
