import routeConstants from "@/router/routePath";

const footerEN = [
	[
		{
			position: 'Managing Director',
			title: 'Animation & Illustration',
			text: 'Ewa Geruzel',
			email: 'ewa.geruzel@hylite.pl'
		},
		{
			title: 'CONTACT',
			email: 'hello@hylite.pl',
			phone: '(+48) 792 586 279'
		},
		{
			title: 'HYLITE',
			address: 'Williama Heerleina Lindleya 16',
			post: '02-013 Warsaw, Poland',
			nip: 'VAT UE: PL5581842222',
			regon: 'REGON: 369 322 350'
		}
	],
	{
		title: 'Services',
		list: [
			[
				{
					title: 'Animated presentations',
					link: routeConstants.ANIMATION.alias
				},
			]
		]
	},
	{
		title: 'Specialty fields',
		list: [
			{
				name1: 'Fintech',
				name2: 'Financial services'
			},
			{
				name1: 'Healthcare',
				name2: 'Professional services'
			},
			{
				name1: 'Biotech',
				name2: 'Medicine'
			},
			{
				name1: 'Pharmaceuticals',
				name2: 'New technologies'
			},
			{
				name1: 'IT',
				name2: 'Power engineering'
			},
			{
				name1: 'Cosmetology',
				name2: 'Ecology'
			},
			{
				name1: 'FMCG',
				name2: 'Trade & Distribution'
			},
			{
				name1: 'Automotive',
				name2: 'Startups'
			},
		]
	}
]

export {footerEN}
