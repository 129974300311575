<template>
	<nav v-if="this.$store.state.isMobile"
	     class="mobile-nav"
	     :class="{ 'nav-mobile-visible': mobileNavStatus }"
	>
		<div class="mobile-nav-top">
			<router-link @click="closeNavbar()" :to="this.$store.state.isEn ? '/en' : '/'">
				<img src="@/assets/images/logo_white.svg" alt="logo">
			</router-link>

			<BarIcon
				v-if="!this.mobileNavStatus"
				@mobile-navbar="this.mobileNavStatus = !this.mobileNavStatus"
			/>

			<div
				v-else
				class="close-icon"
				@click="closeNavbar()"
			>
				<i class="fas fa-times close"/>
			</div>
		</div>

		<div
			class="mobile-nav__content"
			:class="{ 'mobile-visible': mobileNavStatus }"
		>
			<ul class="mobile-nav__list mobile-nav__list-basic" v-if="basicVisible">
				<li
					class="mobile-nav__list-item"
					v-if="!this.$store.state.isEn"
				>
					<Button
						buttonText="Product & service design"
						:additionalElement="true"
						color="white"
						:whiteArrow="true"
						additionalElementStyle="arrowWithoutCircle"
						class="mobile-nav__link"
						@click.prevent="handleSubNavVisibility('product')"
					/>
				</li>
				<li
					class="mobile-nav__list-item"
				>
					<Button
						:buttonText="this.$store.state.isEn ? 'Animation' : 'Animacja'"
						:additionalElement="true"
						color="white"
						:whiteArrow="true"
						additionalElementStyle="arrowWithoutCircle"
						class="mobile-nav__link"
						@click.prevent="handleSubNavVisibility('animation')"
					/>
				</li>
				<li
					v-for="(item, index) in handleHeadLinks"
					:key="item.link + index"
					@click="closeNavbar()"
					class="mobile-nav__list-item"
				>
					<router-link :to="item.link" class="mobile-nav__link">
						{{ item.name }}
					</router-link>
				</li>
			</ul>

			<ul class="mobile-nav__list mobile-nav__list-sublist" v-if="productVisible">
				<Button
					buttonText="Product & service design"
					:additionalElement="true"
					color="white"
					:whiteArrow="true"
					additionalElementStyle="arrowWithoutCircle"
					class="mobile-nav__link --prevent-button"
					@click.prevent
				/>

				<li
					v-for="(item, index) in productLinks"
					:key="item.link + index"
					@click="closeNavbar()"
					class="mobile-nav__list-item mobile-nav__list-sublist-item"
				>

					<span class="nav__span">
						<img src="@/assets/images/arrow_right_navbar.svg" alt="arrow">
					</span>

					<router-link :to="item.link"
					             class="mobile-nav__link mobile-nav__list-sublist-item --text-transform">
						{{ item.name }}
					</router-link>
				</li>

				<Button
					buttonText="Wstecz"
					:additionalElement="true"
					color="white"
					:whiteArrow="true"
					additionalElementStyle="arrowWithoutCircle"
					class="mobile-nav__link --animation-sublist --back-button"
					@click.prevent="handleSubNavVisibility('product-back')"
				/>
			</ul>

			<ul class="mobile-nav__list mobile-nav__list-sublist" v-if="animationVisible">
				<Button
					:buttonText="this.$store.state.isEn ? 'Animation' : 'Animacja'"
					:additionalElement="true"
					color="white"
					:whiteArrow="true"
					additionalElementStyle="arrowWithoutCircle"
					class="mobile-nav__link --prevent-button"
					@click.prevent
				/>

				<li
					v-for="(item, index) in handleAnimationLinks"
					:key="item.link + index"
					@click="closeNavbar()"
					class="mobile-nav__list-item mobile-nav__list-sublist-item"
				>

					<span class="nav__span">
						<img src="@/assets/images/arrow_right_navbar.svg" alt="arrow">
					</span>

					<router-link :to="item.link"
					             class="mobile-nav__link mobile-nav__list-sublist-item --text-transform">
						{{ item.name }}
					</router-link>
				</li>

				<Button
					:buttonText="this.$store.state.isEn ? 'Back' : 'Wstecz'"
					:additionalElement="true"
					color="white"
					:whiteArrow="true"
					additionalElementStyle="arrowWithoutCircle"
					class="mobile-nav__link --back-button --back-button-animation"
					@click.prevent="handleSubNavVisibility('animation-back')"
				/>
			</ul>
			<div class="nav__socialmedia">
				<a href="https://www.instagram.com/hylitepl" target="_blank">
					<img src="@/assets/images/instagram.png" alt="instagram">
				</a>
				<a href="https://www.behance.net/hylite" target="_blank">
					<img src="@/assets/images/behance.png" alt="behane">
				</a>
			</div>
		</div>
	</nav>
</template>

<script>
import BarIcon from '@/components/BarIcon/index'
import {animationENLinks, animationLinks, headENLinks, headLinks, productLinks} from "../data";

export default {
	name: "index mobilenav",
	components: {
		BarIcon
	},
	computed: {
		handleHeadLinks() {
			if (this.$store.state.isEn) {
				return this.headENLinks
			}

			return this.headLinks
		},
		handleAnimationLinks() {
			if (this.$store.state.isEn) {
				return this.animationENLinks
			} else {
				return this.animationLinks
			}
		}
	},
	data() {
		return {
			mobileNavStatus: false,
			productLinks,
			headLinks,
			animationLinks,
			animationENLinks,
			headENLinks,
			basicVisible: true,
			productVisible: false,
			animationVisible: false
		}
	},
	methods: {
		closeNavbar() {
			if (this.mobileNavStatus) {
				this.mobileNavStatus = false
			}
		},
		handleSubNavVisibility(type) {
			switch (true) {
				case type === 'product':
					this.basicVisible = false

					if (this.animationVisible) {
						this.animationVisible = false
					}
					this.productVisible = !this.productVisible
					break

				case type === 'animation':
					this.basicVisible = false

					if (this.productVisible) {
						this.productVisible = false
					}
					this.animationVisible = !this.animationVisible
					break

				case type === 'product-back':
					this.productVisible = false
					this.basicVisible = true
					break

				case type === 'animation-back':
					this.animationVisible = false
					this.basicVisible = true
					break

				default:
					break
			}
		}
	}
}
</script>

<style scoped lang="sass">
.close-icon
	font-size: 28px
	cursor: pointer

.--prevent-button
	user-select: none
	user-focus: none
	cursor: default

.--product-sublist
	margin-bottom: 50px

::v-deep .--back-button
	margin-top: 40px
	justify-content: flex-start !important
	align-items: center !important

	p
		order: 2 !important

	span
		margin-right: 30px
		transform: rotateZ(180deg) !important

.nav-mobile-visible
	height: 100vh

.mobile-visible
	display: flex !important

@include view('sm')
	.mobile-nav
		position: fixed
		top: 0
		left: 0
		width: 100vw
		background: $color--dark
		z-index: 998
		padding: 0 33px

		&__content
			display: none
			flex-direction: column

		&-top
			width: 100%
			padding: 30px 0
			display: flex
			align-items: center
			justify-content: space-between

		&__list
			padding-top: 50px
			padding-bottom: 50px
			height: 100%
			width: 100%
			display: flex
			flex-direction: column
			align-items: flex-start
			justify-content: space-between
			gap: 3rem 0

			&-basic
				margin-top: 145px
				border-top: 1px solid #979797
				border-bottom: 1px solid #979797

			&-sublist
				padding-top: 0 !important
				gap: 2.5rem 0
				height: 600px

				a
					color: white
					font-size: 22px
					font-family: $font--primary
					font-weight: $weight--normal
					width: 100%
					display: flex
					justify-content: space-between

				&-item
					font-size: 14px !important
					font-family: $font--default !important
					font-weight: $weight--light !important
					color: $color--gray !important
					display: flex
					gap: 0 2rem
					align-items: center
					text-transform: lowercase

					&:first-letter
						text-transform: uppercase !important

			&-item
				width: 100%
				font-size: 18px
				font-weight: $weight--normal
				text-transform: uppercase

				a
					color: white
					font-family: $font--primary

				::v-deep a
					width: 100%
					display: flex
					justify-content: space-between

.nav__socialmedia
	margin-top: 50px
	width: 100%
	display: flex
	justify-content: space-evenly
	align-items: center


.--text-transform
	display: inline-block !important
</style>
