<template>
	<nav v-if="this.$store.state.isMobile === false"
	     class="nav"
	     :class="{ visible: navStatus }"
	>
		<div class="nav__top-content">
			<router-link @click="closeNavbar()" :to="this.$store.state.isEn ? '/en' : '/'">
				<img src="@/assets/images/logo_white.svg" alt="logo">
			</router-link>
		</div>

		<div class="nav__close-icon">
			<i class="fas fa-times close" @click="closeNavbar"></i>
		</div>

		<div class="nav__content">
			<ul class="nav__list">
				<li class="nav__list-item" v-if="!this.$store.state.isEn">
					<a class="nav__item nav__item--cursor-pointer"
					   @click="handleSubNavVisibility('product')">
						Product & service design
					</a>
				</li>
				<li class="nav__list-item">
					<a class="nav__item nav__item--cursor-pointer"
					   @click="handleSubNavVisibility('animation')">{{ this.$store.state.isEn ? 'Animation' : 'Animacja' }}</a>
				</li>

				<li v-for="item in handleHeadLinks"
				    :key="item.link"
				    class="nav__list-item">
					<router-link @click="closeNavbar()" :to="item.link" class="nav__item">
						{{ item.name }}
					</router-link>
				</li>
			</ul>

			<ul v-if="productVisible"
			    class="nav__list nav__list--mp-left"
			>
				<li v-for="item in productLinks"
				    :key="item.link"
				    class="nav__list-item">

					<span class="nav__span">
						<img src="../../assets/images/arrow_right_navbar.svg" alt="arrow">
					</span>

					<router-link
						@click="closeNavbar()"
						:to="item.link"
						class="
							nav__item
							nav__item--font-size
							nav__item--font-weight
							nav__item-font--family
							nav__item--text-transform
							--color-gray
						"
					>
						{{ item.name }}
					</router-link>
				</li>
			</ul>

			<ul v-if="animationVisible"
			    class="nav__list nav__list--mp-left"
			>
				<li v-for="(item, i) in handleAnimationLinks"
				    :key="i"
				    class="nav__list-item">

					<span class="nav__span">
						<img src="../../assets/images/arrow_right_navbar.svg" alt="arrow">
					</span>

					<router-link
						@click="closeNavbar()"
						:to="item.link"
						class="
							nav__item
							nav__item--font-size
							nav__item--font-weight
							nav__item-font--family
							nav__item--text-transform
							--color-gray
						"
					>
						{{ item.name }}
					</router-link>
				</li>
			</ul>
		</div>

		<div class="nav__socialmedia">
			<a href="https://www.instagram.com/hylitedigital/" target="_blank">
				<img src="@/assets/images/instagram.png" alt="instagram">
			</a>
			<a href="https://www.behance.net/hylite" target="_blank">
				<img src="@/assets/images/behance.png" alt="behane">
			</a>
		</div>
	</nav>
</template>

<script src="./component.js"></script>
<style src="./style.sass" scoped lang="sass"></style>
