export const animationHomeSlidesEN = [
	{
		id: 'slide 1',
		number1: '01',
		text1: 'Reach the target group of your choice directly with the most attractive form of marketing, the popularity of which is dynamically growing;',
		number2: '02',
		text2: 'Explain how the product or service works and what benefits it brings to your customers;',
		number3: '03',
		text3: 'Turn seemingly dull processes and complex issues into simple and attractive at the same time;'
	},
	{
		id: 'slide 2',
		number1: '04',
		text1: 'Attract the attention of customers and encourage them to further interest in the product;',
		number2: '05',
		text2: 'Highlight the uniqueness of your product or service;',
		number3: '06',
		text3: 'Build positive brand awareness;'
	},
	{
		id: 'slide 3',
		number1: '07',
		text1: 'Explain complicated issues thanks to the use of abstract forms and visual simplifications;',
		number2: '08',
		text2: 'Use the possibility of emotional involvement, which influences better remembering and recognition of the product, service, and the brand itself;',
		number3: '09',
		text3: 'Provide answers to frequent questions in an attractive way, which allows you to relieve the sales department;'
	}
]

export const animationHomeSlidesMobileEN = [
	{
		id: 'slide 1',
		number: '01',
		text: 'Reach the target group of your choice directly with the most attractive form of marketing, the popularity of which is dynamically growing;',
	},
	{
		id: 'slide 2',
		number: '02',
		text: 'Explain how the product or service works and what benefits it brings to your customers;',
	},
	{
		id: 'slide 3',
		number: '03',
		text: 'Turn seemingly dull processes and complex issues into simple and attractive at the same time;'
	},
	{
		id: 'slide 4',
		number: '04',
		text: 'Attract the attention of customers and encourage them to further interest in the product;',
	},
	{
		id: 'slide 5',
		number: '05',
		text: 'Highlight the uniqueness of your product or service;',
	},
	{
		id: 'slide 6',
		number: '06',
		text: 'Build positive brand awareness;'
	},
	{
		id: 'slide 7',
		number: '07',
		text: 'Explain complicated issues thanks to the use of abstract forms and visual simplifications;',
	},
	{
		id: 'slide 8',
		number: '08',
		text: 'Use the possibility of emotional involvement, which influences better remembering and recognition of the product, service, and the brand itself;',
	},
	{
		id: 'slide 9',
		number: '09',
		text: 'Provide answers to frequent questions in an attractive way, which allows you to relieve the sales department;'
	}
]
