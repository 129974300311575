<template>
	<router-view v-slot="{ Component }">
		<Navbar
			@clicked="this.navStatus = !this.navStatus"
			:navStatus="navStatus"
			v-click-outside="clickOutside"
			class="desktop-only"
		/>
		<BarIcon
			v-if="!this.$store.state.isMobile"
			@navbar="this.navStatus = !this.navStatus"
		/>
		<MobileNavbar
			v-if="this.$store.state.isMobile"
		/>
		<Header v-if="cutRoutes()"/>
		<main>
			<component
				:is="Component"
			/>
		</main>
		<FooterApp/>
	</router-view>
</template>

<script>
import {headers} from './data/data'

export default {
	name: 'App',
	data: () => ({
		navStatus: false,
		headers
	}),
	watch: {
		'$route.fullPath': {
			handler() {
				this.$store.state.isEn = this.$route.fullPath.includes('/en');
			}
		}
	},
	beforeCreate() {
		const fbHook = '?'
		let currRoute = this.$route.fullPath

		if (currRoute.includes(fbHook)) {
			currRoute = currRoute.substring(0, currRoute.indexOf('?'))

			this.$router.push({path: currRoute})
		}


		if (this.$route.fullPath.includes('/en')) {
			this.$store.state.isEn = true
		}
	},
	created() {
		window.addEventListener('load', this.handleWidth)
		window.addEventListener('resize', this.handleWidth)
	},
	beforeDestroy() {
		window.removeEventListener('load', this.handleWidth)
		window.removeEventListener('resize', this.handleWidth)
	},
	methods: {
		handleWidth() {
			let w = window.innerWidth

			if (w < 737) {
				this.$store.state.isMobile = true
				this.$store.state.isTablet = false
			}

			if (w > 736 && w < 1280) {
				this.$store.state.isMobile = false
				this.$store.state.isTablet = true
			}

			if (w >= 1280) {
				this.$store.state.isMobile = false
				this.$store.state.isTablet = false
			}
		},
		cutRoutes() {
			return !(this.$route.fullPath.includes('service-design-proces') ||
				this.$route.fullPath.includes('kariera') ||
				this.$route.fullPath.includes('faq')
			)
		},
		clickOutside() {
			this.$nextTick(() => {
				this.navStatus = false
			})
		}
	}
}
</script>

<style lang="sass">
@import './assets/styles/variables.sass'
@import './assets/styles/breakpoints'

html
	font-size: 10px
	scroll-behavior: smooth

*, *::before, *::after
	margin: 0
	box-sizing: border-box

body
	overflow-x: hidden !important
	background: white
	width: 100%
	padding: 0
	color: black
	font-family: $font--default

main
	position: relative

.desktop-only
	display: block

	@include view('sm')
		display: none

.row
	display: flex
	flex-direction: row

.container
	max-width: 1280px
	margin: 0 auto
	padding: 0 100px

	@include view('md')
		padding: 0 100px

	@include view('sm')
		padding: 0 33px

.section--full-width
	min-width: 100%
	width: 100%
	padding: 0 100px

	@include view('md')
		padding: 0 100px

	@include view('sm')
		padding: 0 33px

i
	color: $color--light

@media screen and (min-width: 320px) and (max-width: 980px)
	body
		overflow: scroll

	.main
		width: 100%
		padding: 0 10px

	.top
		display: none

a
	text-decoration: none
	color: $color--dark

ol, ul, li
	padding: 0
	margin: 0
	list-style: none

input
	position: relative
	border: 0
	outline: 0
	background: transparent
	color: white

.d-sm-none
	@include view('sm')
		display: none

.h4-style
	font-size: 12px
	font-family: $font--primary
	font-weight: $weight--normal
	text-transform: uppercase
	padding-bottom: 10px

.h2-style
	font-size: 54px
	font-weight: $weight--semi-bold
	margin-bottom: 68px

	@include view('md')
		font-size: 41px

.h3-style
	font-weight: $weight--semi-bold
	font-size: 20px
	margin-bottom: 44px

.p-style
	font-size: 17px
	font-weight: $weight--light
	line-height: 27px

	@include view('sm')
		font-size: 13px
		line-height: 20px

.h2-sm-style
	@include view('sm')
		font-size: 31px

// design & animation process styles
.p-h2-style
	font-size: 45px
	font-weight: $weight--semi-bold
	font-family: $font--primary
	text-transform: uppercase

.p-h2-sm-style
	@include view('sm')
		font-size: 29px

.p-h3-style
	font-size: 20px
	font-weight: $weight--semi-bold
	margin-bottom: 25px

.p-p-style
	font-size: 17px
	font-weight: $weight--light
	line-height: 27px

	@include view('sm')
		font-size: 13px
		line-height: 20px

.p-number-style
	color: $color--gray
	font-size: 215px
	font-weight: $weight--semi-bold
	font-family: $font--primary
	opacity: .3

	@include view('sm')
		font-size: 67px
</style>

