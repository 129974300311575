import {createRouter, createWebHistory} from "vue-router";
import routeConstants from './routePath'
import {headers} from '@/data/data'
import store from '@/store/index'
import {animation} from '@/data/animation'
import {en} from '@/data/en/data'

const routes = [
	{
		path: routeConstants.HOME.path,
		name: routeConstants.HOME.name,
		component: () => import( "../views/Main/index.vue"),
	},
	{
		path: routeConstants.ABOUT.path,
		name: routeConstants.ABOUT.name,
		alias: routeConstants.ABOUT.alias,
		component: () => import( "../views/About/index.vue"),
	},
	{
		path: routeConstants.CARRIER.path,
		name: routeConstants.CARRIER.name,
		component: () => import( "../views/Carrier/index.vue"),
	},
	{
		path: routeConstants.CONTACT.path,
		name: routeConstants.CONTACT.name,
		alias: routeConstants.CONTACT.alias,
		component: () => import( "../views/Contact/index.vue"),
	},

	//animacja
	{
		path: routeConstants.ANIMATION.path,
		name: routeConstants.ANIMATION.name,
		alias: routeConstants.ANIMATION.alias,
		component: () => import( "../views/Animation/index.vue"),
	},
	{
		path: routeConstants.ANIMATION_PRODUCTION_PROCESS.path,
		name: routeConstants.ANIMATION_PRODUCTION_PROCESS.name,
		alias: routeConstants.ANIMATION_PRODUCTION_PROCESS.alias,
		component: () => import( "../views/AnimationProcess/index.vue"),
	},
	{
		path: routeConstants.PRODUCT_ANIMATION.path,
		name: routeConstants.PRODUCT_ANIMATION.name,
		alias: routeConstants.PRODUCT_ANIMATION.alias,
		component: () => import('../views/Animation/index.vue')
	},
	{
		path: routeConstants.ADVERTISEMENT_ANIMATION.path,
		name: routeConstants.ADVERTISEMENT_ANIMATION.name,
		alias: routeConstants.ADVERTISEMENT_ANIMATION.alias,
		component: () => import('../views/Animation/index.vue')
	},
	{
		path: routeConstants.PROMOTIONAL_ANIMATION.path,
		name: routeConstants.PROMOTIONAL_ANIMATION.name,
		alias: routeConstants.PROMOTIONAL_ANIMATION.alias,
		component: () => import('../views/Animation/index.vue')
	},
	{
		path: routeConstants.ONBOARDING_ANIMATION.path,
		name: routeConstants.ONBOARDING_ANIMATION.name,
		alias: routeConstants.ONBOARDING_ANIMATION.alias,
		component: () => import('../views/Animation/index.vue')
	},
	{
		path: routeConstants.PRESENTATION_ANIMATION.path,
		name: routeConstants.PRESENTATION_ANIMATION.name,
		alias: routeConstants.PRESENTATION_ANIMATION.alias,
		component: () => import('../views/Animation/index.vue')
	},

	//design
	{
		path: routeConstants.DESIGN_PROCESS.path,
		name: routeConstants.DESIGN_PROCESS.name,
		component: () => import('../views/DesignProcess/index.vue')
	},
	{
		path: routeConstants.DESIGN_AUDITS.path,
		name: routeConstants.DESIGN_AUDITS.name,
		component: () => import('../views/Design/index.vue')
	},
	{
		path: routeConstants.DESIGN_RESEARCH.path,
		name: routeConstants.DESIGN_RESEARCH.name,
		component: () => import('../views/Design/index.vue')
	},
	{
		path: routeConstants.DESIGN_FIX.path,
		name: routeConstants.DESIGN_FIX.name,
		component: () => import('../views/Design/index.vue')
	},
	{
		path: routeConstants.DESIGN_PRODUCT.path,
		name: routeConstants.DESIGN_PRODUCT.name,
		component: () => import('../views/Design/index.vue')
	},
	{
		path: routeConstants.DESIGN_TRANSFORM.path,
		name: routeConstants.DESIGN_TRANSFORM.name,
		component: () => import('../views/Design/index.vue')
	},
	{
		path: routeConstants.DESIGN_DEVELOPMENT.path,
		name: routeConstants.DESIGN_DEVELOPMENT.name,
		component: () => import('../views/Design/index.vue')
	},
	{
		path: routeConstants.DESIGN_IOT.path,
		name: routeConstants.DESIGN_IOT.name,
		component: () => import('../views/Design/index.vue')
	},
	{
		path: routeConstants.DESIGN_SUPPORT.path,
		name: routeConstants.DESIGN_SUPPORT.name,
		component: () => import('../views/Design/index.vue')
	},

	//faq
	{
		path: routeConstants.FAQ.path,
		name: routeConstants.FAQ.name,
		redirect: routeConstants.PRODUCT_FAQ.path,
		component: () => import( "../views/FAQ/index.vue"),
	},
	{
		path: routeConstants.PRODUCT_FAQ.path,
		name: routeConstants.PRODUCT_FAQ.name,
		component: () => import( "../views/FAQ/index.vue"),
	},
	{
		path: routeConstants.ANIMATION_FAQ.path,
		name: routeConstants.ANIMATION_FAQ.name,
		alias: routeConstants.ANIMATION_FAQ.alias,
		component: () => import( "../views/FAQ/index.vue"),
	},
	// {
	// path: '/:pathMatch(.*)*',
	// name: 'Error404',
	// component: () => import( "../views/Main/index"),
	// redirect: { path: '/404'}
	// }
];

const router = createRouter({
	strict: false,
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

router.beforeEach(function (to, from, next) {
	let currRoute = to.fullPath

	if (currRoute.includes('?')) {
		currRoute = currRoute.substring(0, currRoute.indexOf('?'))
		store.state.animationContent = animation.find(x => x.id === currRoute)
	}

	currRoute.includes('/en')
		?
		(
			window.document.title = 'Hylite | Digital House. We create top-notch animations as an effective video marketing tool',
				store.state.animationContent = en.find(x => x.id === currRoute)
		)
		:
		(
			window.document.title = 'Hylite | Digital House. Rozwiązania customer experience dla biznesu i marketingu',
				store.state.animationContent = animation.find(x => x.id === currRoute)
		)

	if (currRoute !== '/') {
		if (currRoute.endsWith('/')) {
			router.push({path: currRoute.slice(0, -1)})
		}
	}

	if (from.fullPath.includes('kariera') || from.fullPath.includes('faq') || from.fullPath.includes('service-design-proces')) {
		currRoute === '/'
			? store.state.headerContent = headers.find(x => x.id === 'home')
			: store.state.headerContent = headers.find(x => x.id === currRoute)

		// currRoute.includes('/en')
		// 	? store.state.headerContent = headers.find(x => x.id === 'home')
		// 	: store.state.headerContent = headers.find(x => x.id === currRoute)
	}

	setTimeout(() => {
		window.scrollTo(0, 0);
	}, 10);
	next();
});

export default router;
